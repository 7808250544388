import { DateTime } from 'luxon';

const Do_TOKEN = '\x01'

const Moment = ({format, children=NaN}) => {
  try {
    format = convertMomentToLuxon(format)
    const d = new Date(children)
    if (isNaN(d)) throw "Not a date: " + children.toString()
    const dt = DateTime.fromJSDate(d).setZone('utc')
    var s = dt.toFormat(format)
    if (s.indexOf(Do_TOKEN) >=0) s = s.replace(Do_TOKEN, ordinal(dt.day))
    return s
  } catch (err) {
    console.error(err)
    return <span style={{color:'red'}}>ERR</span>
  }
}

const convertMomentToLuxon = (fmt) => {
  /* only handles the formats we are using:
  "MMMM"
  "dddd, MMMM Do"
  "MM/DD/YYYY hh:mm A"
  "MM/DD/YYYY h:mm A"
  "dddd, MMMM D, YYYY"
  */
  return fmt
    .replaceAll('Do', Do_TOKEN)
    .replaceAll('d', 'E') // day of week
    .replaceAll('D', 'd') // day of month
    .replaceAll('Y', 'y') // year
    .replaceAll('A', 'a') // meridian
}

function ordinal(n) {
  var s = ["th", "st", "nd", "rd"];
  var v = n%100;
  return n + (s[(v-20)%10] || s[v] || s[0]);
}

export default Moment