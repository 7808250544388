import axios from "axios";
import React, { Component } from "react";
import Alert from "react-bootstrap/Alert";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import PageHeader from "../../PageHeader";
import Loader from "../../Loader";
import "./ManagerApproval.css";

class ManagerApproval extends Component {
  state = {
    applications: [],
    application: {},
    approvalId: null,
    notes: "",
    loading: true,
    submitting: false,
    submitted: false,
  };

  componentDidMount = () => {
    if (this.props.params.application_id) {
      this.fetchApplications(this.props.params.application_id);
    }
  };

  fetchApplications = (id) => {
    // This fetches a list of applications the current user submitted or
    // applications that were submitted by an employee of the current user.
    axios
      .get("/api/applications/my_application")
      .then((response) => {
        // Get the application being requested
        let applications = response.data;
        let application = applications.find((a) => a.id == id);
        if (!application) {
          application = {};
        }

        this.setState({
          applications: response.data,
          application: application,
          notes: application.ManagerApproval?.notes || "",
          approvalId: application.ManagerApproval?.id || null,
          loading: false,
        });
      })
      .catch((error) => {
        this.setState({ loading: false });
      });
  };

  isManager = () => {
    // Checks if current user is the applicant of the application. If not, we
    // assume current user is the manager because that's all the my_application
    // endpoint should be returning.
    let applications = this.state.applications.filter((a) => {
      let isNotApplicant = a.user_id !== this.props.currentUser.user_id;
      let isApplication = a.id == this.props.params.application_id;
      return isNotApplicant && isApplication;
    });

    return applications.length === 1;
  };

  handleInputChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleFormSubmit = (event) => {
    event.preventDefault();
    this.setState({ submitting: true });

    // Send data to the API
    let url = `/api/applications/${this.props.params.application_id}/approve`;
    let data = {
      application_id: this.props.params.application_id,
      id: this.state.approvalId,
      notes: this.state.notes,
      approved: true,
    };

    axios.post(url, data).then((response) => {
      this.setState({
        approvalId: response.data.id,
        submitting: false,
        submitted: true,
      });
    });
  };

  render() {
    if (this.state.loading) {
      return <Loader>Loading Application Data...</Loader>;
    }

    let isNotManager = !this.isManager();
    let isWithdrawn =
      this.state.application.ApplicationStatus.status === "Withdrawn";
    if (isNotManager || isWithdrawn) {
      return (
        <Container className="approval-container">
          <Alert variant="danger">
            {isNotManager
              ? "You must be the manager of the submitter in order to approve this application!"
              : "This application has been withdrawn!"}
          </Alert>
        </Container>
      );
    }

    return (
      <div>
        <PageHeader title="Application Approval" />

        <Container className="approval-container">
          <h2>Manager Approval</h2>
          <h3 className="h5">
            For {this.state.application.User.name} in{" "}
            {this.state.application.year}
          </h3>
          <p>
            Mentees must commit to the time requirements to be considered for
            the CLEAR program. To acknowledge this commitment, managers must
            provide approval. Include any notes and submit your approval in the
            form below.
          </p>

          {this.state.approvalId ? (
            <Alert variant={this.state.submitted ? "success" : "info"}>
              This submission has been approved! You may update your notes in
              the field below.
            </Alert>
          ) : null}

          <Form onSubmit={this.handleFormSubmit}>
            <Form.Group>
              <Form.Control
                name="notes"
                value={this.state.notes}
                as="textarea"
                rows="4"
                onChange={this.handleInputChange}
              />
            </Form.Group>

            <Form.Group>
              <Button
                variant="primary"
                type="submit"
                disabled={this.state.submitting}
              >
                {this.state.submitting ? "Submitting..." : "Submit Approval"}
              </Button>
              <Button variant="" href="/apply">
                Cancel
              </Button>
            </Form.Group>
          </Form>
        </Container>
      </div>
    );
  }
}

export default ManagerApproval;
