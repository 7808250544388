import React, { Component } from "react";
import axios from "axios";
import { Form, Button } from "react-bootstrap";
import PageHeader from "../PageHeader";
import Loader from "../Loader";
import "./Project.css";
import { FileInput } from '../Files/';
import clearLogoSrc from "../../assets/images/clear-logo.svg";

export default class ProjectForm extends Component {
  state = {
    loading: true,
    error: null,
    possibleTags: [],
    project: {
      title: "",
      description: "",
      tags: [],
      logo: ""
    },
    saved: false,
    invalidImageTypeSelected: false
  };

  componentDidMount() {
    this.loadProject(this.props.id);
    this.loadTags();
    this.handleTagsChange = this.handleTagsChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  loadTags() {
    return axios
      .get(`/api/tags`)
      .then((response) => {
        this.setState({
          possibleTags: response.data,
        });
      })
      .catch((error) => {
        this.setState({
          error: "Unable to load possible tags!",
        });
      });
  }

  loadProject(id) {
    return axios
      .get(`/api/projects/${id}`)
      .then((response) => {
        const { logo, year } = response.data;

        if (logo) {
          if (logo.indexOf('api/files') < 0 && logo !== 'GENERIC') {
            response.data.logo = `api/files/images/project-fair/${year}/logos/${logo}`;
          }

          if (logo === 'GENERIC') {
            // set default logo to the clearLogo
            response.data.logo = clearLogoSrc;
          }
        }

        this.setState({
          loading: false,
          project: response.data,
        });
      })
      .catch((error) => {
        this.setState({
          loading: false,
          error: "Unable to load form!",
        });
      });
  }

  handleSubmit(e) {
    e.preventDefault();

    // Validate Form
    let form = e.currentTarget;
    let isValid = form.checkValidity() && !this.state.invalidImageTypeSelected;
    if (!isValid) {
      let { error } = this.state;
      error = "Invalid fields submitted!"
      this.setState({ error });
      return false;
    }

    // If valid, send to the backend
    axios
      .put(`/api/projects/${this.state.project.id}`, this.state.project)
      .then((res) => {
        if (res) {
          this.setState({
            saved: true,
            error: null
          });
        }
      });
  }

  handleChange(e) {
    this.setState({
      saved: false,
    });
    let { project, error } = this.state;
    let value = e.target.value || null;

    if (e.target.type == 'checkbox') {
      value = e.target.checked;
    }

    project[e.target.name] = value;

    // reset the error state when a user updates the form
    if (error === "Invalid fields submitted!") {
      error = null;
    }


    this.setState({
      project,
      error
    });
  }

  handleTagsChange(e) {
    this.setState({
      saved: false,
    });
    let project = this.state.project;
    if (e.target.checked) {
      // add to array
      project.tags.push({ tag_id: e.target.value });
    } else {
      // remove from array
      project.tags = project.tags.filter((pt) => pt.tag_id != e.target.value);
    }
    this.setState({
      project,
    });
  }

  handleCancel(e) {
    if (this.props.onCancel) {
      this.props.onCancel(e.target.value);
    }
  }

  invalidFileSelected = (invalidFileSelected) => {
    this.setState({ invalidImageTypeSelected: invalidFileSelected });
  }

  render = () => {
    if (this.state.loading) {
      return <Loader />;
    }

    let project = this.state.project;
    return (
      <>
        <PageHeader title={(project.id ? "Edit" : "Create") + " Project"} />
        <div className="form-container">
          <Form key={project.id} id="event-form" onSubmit={this.handleSubmit}>
            <h2>Project Information</h2>
            <FileInput object={project} fileKey="logo" entityType="Logo" allowedTypes={['jpeg', 'jpg', 'png']} invalidFileTypeCallback={this.invalidFileSelected} />
            <Form.Group>
              <Form.Label>Title</Form.Label>
              <Form.Control
                name="title"
                value={project.title}
                required
                onChange={this.handleChange}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                name="description"
                value={project.description}
                onChange={this.handleChange}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Live Video Source</Form.Label>
              <Form.Control
                as="textarea"
                name="video_source"
                value={project.video_source || ""}
                placeholder="Microsoft Teams"
                onChange={this.handleChange}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Live Channel Link (Meeting Invite)</Form.Label>
              <Form.Control
                as="textarea"
                name="video_url"
                value={project.video_url || ""}
                onChange={this.handleChange}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Video Stream Link</Form.Label>
              <Form.Control
                as="textarea"
                name="video_stream_url"
                value={project.video_stream_url || ""}
                onChange={this.handleChange}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Special Thanks</Form.Label>
              <Form.Control
                as="textarea"
                name="thanks"
                value={project.thanks || ""}
                onChange={this.handleChange}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Active</Form.Label>
              <Form.Check
                type="checkbox"
                id="active"
                name="active"
                defaultChecked={project.active}
                onChange={this.handleChange}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Tags</Form.Label>
              <div className="project-tags">
                {this.state.possibleTags.map((t, i) => {
                  return (
                    <Form.Check
                      type="checkbox"
                      inline
                      id={`${t.id}-${t.name}`}
                      key={`${t.id}-${t.name}`}
                      value={t.id}
                      defaultChecked={this.state.project.tags.find(
                        (x) => x.tag_id == t.id
                      )}
                      label={t.name}
                      onChange={this.handleTagsChange}
                    />
                  );
                })}
              </div>
            </Form.Group>
          </Form>
          <hr />
          <Button disabled={this.state.saved} type="submit" form="event-form">
            Save Project
          </Button>{" "}
          <Button variant="outline-dark" onClick={this.handleCancel}>
            Back
          </Button>
          {this.state.saved ? <h3>Save Successful</h3> : ""}
          {this.state.error ? <h3>Error: {this.state.error}</h3> : ""}
        </div>
      </>
    );
  };
}
