import { Card, Button, Badge } from "react-bootstrap";
import './RoleCard.css'

const RoleCard = ({title, text, roleText, isPending, isSubmitted, okToApply, isRole, isApproved, approvedText='Application Submitted', onBegin, onWithdraw, onContinue}) => {
  return (
    <Card className='role-card'>
    <Card.Body>
      <Card.Title>{title}</Card.Title>
      <Card.Text as='div'>{text}</Card.Text>
      {isSubmitted && isRole ? (
        <Card.Text>
          {isApproved ? (
            <Badge className="submitted" variant="success">{approvedText}</Badge>
          ) : (
            <Badge className="pending" variant="info">Awaiting Manager Approval</Badge>
          )}
        </Card.Text>
      ) : undefined}
    </Card.Body>
    <Card.Body className='button-group'>
      {isPending && isRole ? (<>
        <Button variant="primary" block={true} onClick={onContinue}>Continue</Button>
        <Button variant="danger" block={true} onClick={onWithdraw}>Withdraw</Button>
      </>
      ) : !isSubmitted && okToApply && (
        <Button variant={!isPending ? 'primary' : 'secondary'} onClick={onBegin}>
          Begin {roleText} Application
        </Button>
      )}
      {isSubmitted && isRole ? (
        <Button variant="danger" onClick={onWithdraw}>Withdraw Application</Button>
      ) : undefined}
    </Card.Body>
  </Card>
  )
}

export default RoleCard