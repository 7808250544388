// Refactored From https://github.com/pushtell/react-bootstrap-date-picker/blob/master/src/index.jsx to work with React v16

import React, { JSXElementConstructor, KeyboardEvent, ReactElement, ReactNode, useEffect, useRef, useState } from 'react'
import { Button, FormControl, InputGroup, Overlay, Popover } from 'react-bootstrap'
import CalendarHeader from './DatePickerHeader'
import Calendar from './DatePickerCalendar'
import { toEndOfDay, makeDateValues, makeInputValueString, toStartOfDay } from './utils'

let instanceCount = 0;

type Placement = 'auto-start' | 'auto' | 'auto-end' | 'top-start' | 'top' | 'top-end' | 'right-start' | 'right' | 'right-end' | 'bottom-end' | 'bottom' | 'bottom-start' | 'left-end' | 'left' | 'left-start'
type Props = {
  defaultValue?: string
  value?: string
  required?: boolean
  className?: string
  style?: object
  minDate?: string
  maxDate?: string
  cellPadding?: string
  autoComplete?: string
  placeholder?: string
  dayLabels?: string[]
  monthLabels?: string[]
  onChange?: (selectedDate:string, inputValue:string)=>void
  onClear?: ()=>void
  onBlur?: () => void
  onFocus?: () => void
  autoFocus?: boolean
  disabled?: boolean
  weekStartsOn?: number
  clearButtonElement?: string | object
  showClearButton?: boolean
  previousButtonElement?: string | object
  nextButtonElement?: string | object
  calendarPlacement?: Placement | (()=>Placement)
  dateFormat?: string // 'MM/DD/YYYY', 'DD/MM/YYYY', 'YYYY/MM/DD', 'DD-MM-YYYY'
  size?: 'sm' | 'lg'
  calendarContainer?: HTMLElement | React.RefObject<HTMLElement> | (()=>HTMLElement | React.RefObject<HTMLElement>)
  id?: string
  name?: string
  showTodayButton?: boolean
  todayButtonLabel?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  customControl?: ReactElement<any, string | JSXElementConstructor<any>>
  roundedCorners?: boolean
  showWeeks?: boolean
  children?: ReactNode
  isInvalid?: boolean,
  onInvalid?: ()=>void
  endOfDay?: boolean
  utc?: boolean
}

const DatePicker = ({ defaultValue, value:_value, required, className, minDate:_minDate, maxDate:_maxDate,
  cellPadding='5px',
  dayLabels=['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
  monthLabels=['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
  clearButtonElement='x',
  previousButtonElement='<',
  nextButtonElement='>',
  calendarPlacement='bottom',
  dateFormat:_dateFormat,
  showClearButton=false,
  autoFocus=false,
  disabled=false,
  showTodayButton=false,
  todayButtonLabel='Today',
  autoComplete='on',
  showWeeks=false,
  style,
  roundedCorners=false,
  placeholder, onChange, onClear, onBlur, onFocus, weekStartsOn,
  size, calendarContainer, id, name, customControl, children, isInvalid, onInvalid, endOfDay, utc=false}:Props) =>
{
  const minDate = _minDate? new Date(`${_minDate.slice(0,10)}T12:00:00.000Z`) : undefined;
  const maxDate = _maxDate? new Date(`${_maxDate.slice(0,10)}T12:00:00.000Z`) : undefined;
  const language = (window?.navigator?.language || '').toLowerCase()
  const dateFormat = _dateFormat ? _dateFormat : (!language || language === 'en-us' ? 'MM/DD/YYYY' : 'DD/MM/YYYY')
  const separator = (dateFormat.match(/[^A-Z]/) || ['/'])[0]

  const inputRef = useRef<HTMLInputElement>(null);
  const hiddenInputRef = useRef<HTMLInputElement>(null);
  const overlayRef = useRef<HTMLDivElement>(null);

  const [focused, setFocused] = useState(false)
  const [inputFocused, setInputFocused] = useState(false)

  const [value, _setValue] = useState(makeDateValues(_value || defaultValue, dateFormat, minDate, maxDate, separator, utc))
  const setValue = (delta:object) => _setValue({...value, ...delta})

  useEffect(()=>{
    _setValue(makeDateValues(_value, dateFormat, minDate, maxDate, separator, utc))
  }, [_value])

  const clear = () => {
    if (onClear) {
      onClear();
    }
    else {
      setValue(makeDateValues(undefined, dateFormat, minDate, maxDate, separator, utc));
    }

    if (onChange) {
      onChange('', '');
    }
  }

  const handleHide  = () => {
    if (inputFocused) {
      return
    }
    setFocused(false)
    if (onBlur) {
      onBlur();
    }
  }

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Tab' && inputFocused) {
      setInputFocused(false)
      setFocused(false)

      if (onBlur) {
        onBlur();
      }
    }
  }

  const handleFocus = () => {
    if (focused === true) {
      return;
    }

    setInputFocused(false)
    setFocused(true)

    setInputFocused(true)
    setFocused(true)

    if (onFocus) {
      onFocus();
    }
  }

  const handleBlur = () => {
    setInputFocused(false)
  }

  const getCalendarPlacement = () => {
    return (typeof calendarPlacement === 'function') ? calendarPlacement() : calendarPlacement;
  }

  const handleBadInput = (originalValue:string) => {
    const parts = originalValue.replace(new RegExp(`[^0-9${separator}]`), '').split(separator);
    if (dateFormat.match(/MM.DD.YYYY/) || dateFormat.match(/DD.MM.YYYY/)) {
      if (parts[0] && parts[0].length > 2) {
        parts[1] = parts[0].slice(2) + (parts[1] || '');
        parts[0] = parts[0].slice(0, 2);
      }
      if (parts[1] && parts[1].length > 2) {
        parts[2] = parts[1].slice(2) + (parts[2] || '');
        parts[1] = parts[1].slice(0, 2);
      }
      if (parts[2]) {
        parts[2] = parts[2].slice(0,4);
      }
    } else {
      if (parts[0] && parts[0].length > 4) {
        parts[1] = parts[0].slice(4) + (parts[1] || '');
        parts[0] = parts[0].slice(0, 4);
      }
      if (parts[1] && parts[1].length > 2) {
        parts[2] = parts[1].slice(2) + (parts[2] || '');
        parts[1] = parts[1].slice(0, 2);
      }
      if (parts[2]) {
        parts[2] = parts[2].slice(0,2);
      }
    }
    setValue({inputValue: parts.join(separator)})
  }

  const handleInputChange = () => {
    const originalValue = inputRef.current?.value || ''
    const inputValue = originalValue.replace(/(-|\/\/)/g, separator).slice(0,10);

    if (!inputValue) {
      clear();
      return;
    }

    let month, day, year;
    if (dateFormat.match(/MM.DD.YYYY/)) {
      if (!inputValue.match(/[0-1][0-9].[0-3][0-9].[1-2][0-9][0-9][0-9]/)) {
        return handleBadInput(originalValue);
      }

      month = inputValue.slice(0,2).replace(/[^0-9]/g, '');
      day = inputValue.slice(3,5).replace(/[^0-9]/g, '');
      year = inputValue.slice(6,10).replace(/[^0-9]/g, '');
    } else if (dateFormat.match(/DD.MM.YYYY/)) {
      if (!inputValue.match(/[0-3][0-9].[0-1][0-9].[1-2][0-9][0-9][0-9]/)) {
        return handleBadInput(originalValue);
      }

      day = inputValue.slice(0,2).replace(/[^0-9]/g, '');
      month = inputValue.slice(3,5).replace(/[^0-9]/g, '');
      year = inputValue.slice(6,10).replace(/[^0-9]/g, '');
    } else {
      if (!inputValue.match(/[1-2][0-9][0-9][0-9].[0-1][0-9].[0-3][0-9]/)) {
        return handleBadInput(originalValue);
      }

      year = inputValue.slice(0,4).replace(/[^0-9]/g, '');
      month = inputValue.slice(5,7).replace(/[^0-9]/g, '');
      day = inputValue.slice(8,10).replace(/[^0-9]/g, '');
    }

    const monthInteger = parseInt(month, 10);
    const dayInteger = parseInt(day, 10);
    const yearInteger = parseInt(year, 10);
    if (monthInteger > 12 || dayInteger > 31) {
      return handleBadInput(originalValue);
    }

    if (!isNaN(monthInteger) && !isNaN(dayInteger) && !isNaN(yearInteger) && monthInteger <= 12 && dayInteger <= 31 && yearInteger > 999) {
      const selectedDate = new Date(yearInteger, monthInteger - 1, dayInteger, 12, 0, 0, 0);
      setValue({
        selectedDate,
        displayDate: selectedDate,
        value: selectedDate.toISOString()
      })

      if (onChange) {
        const d = endOfDay ? toEndOfDay(selectedDate, utc) : toStartOfDay(selectedDate, utc)
        onChange(d, inputValue);
      }
    }
    setValue({inputValue:inputValue})
  }

  const onChangeMonth = (newDisplayDate:Date) => {
    setValue({
      displayDate: newDisplayDate
    });
  }

  const onChangeDate = (newSelectedDate:Date) => {
    const inputValue = makeInputValueString(newSelectedDate, dateFormat, separator);
    setValue({
      inputValue: inputValue,
      selectedDate: newSelectedDate,
      displayDate: newSelectedDate,
      value: newSelectedDate.toISOString()
    })
    setFocused(false)

    if (onBlur) {
      onBlur();
    }

    if (onChange) {
      const d = endOfDay ? toEndOfDay(newSelectedDate, utc) : toStartOfDay(newSelectedDate, utc)
      onChange(d, inputValue);
    }
  }

    const calendarHeader = <CalendarHeader
      previousButtonElement={previousButtonElement}
      nextButtonElement={nextButtonElement}
      displayDate={value.displayDate || new Date()}
      minDate={minDate}
      maxDate={maxDate}
      onChange={onChangeMonth}
      monthLabels={monthLabels} />

    const control = customControl
      ? React.cloneElement(customControl, {
        onKeyDown: handleKeyDown,
        value: value.inputValue || '',
        required: required,
        placeholder: focused ? dateFormat : placeholder,
        ref: inputRef,
        disabled: disabled,
        onFocus: handleFocus,
        onBlur: handleBlur,
        onChange: handleInputChange,
        className: className,
        style: style,
        autoComplete: autoComplete,
        isInvalid: isInvalid,
        onInvalid: onInvalid,
      })
      : <FormControl
          onKeyDown={handleKeyDown}
          value={value.inputValue || ''}
          required={required}
          ref={inputRef}
          type="text"
          className={className}
          style={style}
          autoFocus={autoFocus}
          disabled={disabled}
          placeholder={focused ? dateFormat : placeholder}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={handleInputChange}
          autoComplete={autoComplete}
          isInvalid={isInvalid}
          onInvalid={onInvalid}
          />;

  return (
    <div>

    <InputGroup
      className={showClearButton ? className : ''}
      size={size}
      id={id ? `${id}_group` : undefined}>
      {control}
    </InputGroup>
      <Overlay
        rootClose={true}
        onHide={handleHide}
        show={focused}
        container={calendarContainer || overlayRef}
        target={inputRef}
        placement={getCalendarPlacement()}>
        <Popover id={`date-picker-popover-${instanceCount++}`} className="date-picker-popover">
          {calendarHeader ? <Popover.Title>{calendarHeader}</Popover.Title> : null}
          <Calendar
            cellPadding={cellPadding}
            selectedDate={value.selectedDate}
            displayDate={value.displayDate}
            onChange={onChangeDate}
            dayLabels={dayLabels}
            weekStartsOn={weekStartsOn}
            showTodayButton={showTodayButton}
            todayButtonLabel={todayButtonLabel}
            minDate={minDate}
            maxDate={maxDate}
            roundedCorners={roundedCorners}
            showWeeks={showWeeks}
          />
        </Popover>
      </Overlay>
      <div ref={overlayRef} style={{position: 'relative'}} />
      <input ref={hiddenInputRef} type="hidden" id={id} name={name} value={value.value || ''} data-formattedvalue={value.value ? value.inputValue : ''} />
      {showClearButton && !customControl && <Button variant="outline-secondary"
        onClick={disabled ? undefined : clear}
        style={{cursor:(value.inputValue && !disabled) ? 'pointer' : 'not-allowed'}}>
        <div style={{opacity: (value.inputValue && !disabled) ? 1 : 0.5}}>
          {clearButtonElement}
        </div>
      </Button>}
      {children}
    </div>
  )
}

export default DatePicker