export async function getProgramUsers() {
  return await fetch("/api/users/program")
    .then((resp) => resp.json())
    .then((data) => data);
}

export async function getAllUsers() {
  return await fetch("/api/users")
    .then((resp) => resp.json())
    .then((data) => data);
}

export async function saveUser(user) {
  return await fetch("/api/users", {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(user),
  });
}

export async function removeUser(user) {
  return await fetch("/api/users", {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(user),
  });
}

export async function getCommittee() {
  return await fetch("/api/users/committee")
    .then((resp) => resp.json())
    .then((data) => data);
}
