import React, { Component } from "react";
import PermissionLevel from "../../data/PermissionLevel";
import Unauthorized from "../Unauthorized";
import ProjectForm from "./form.jsx";

export default class Project extends Component {
  state = {
    editing: true,
    error: null,
  };

  isOfficer() {
    var userRole = this.props.currentUser.Role;
    return userRole.permission_level >= PermissionLevel.Officer;
  }

  render = () => {
    // Only Officers and up can view this page for now
    if (!this.isOfficer()) {
      return <Unauthorized />;
    }

    return (
      <div>
        <ProjectForm
          id={this.props.params.project_id}
          onCancel={() => window.history.back()}
        />
      </div>
    );
  };
}
