import { useEffect, useRef } from "react"

/**
  Clears timeouts when the component is unmounted
  @example
  const {setTimeout, clearTimeout} = useSetTimeout()
  const to = setTimeout(()=>console.log('helloworld'), 1.5 * 1000)
  clearTimeout(to)
*/
const useSetTimeout = () => {
  const ref = useRef<Map<number,number>>(new Map())
  useEffect(() => {
    // onUnmount
    return () => ref.current.forEach((to)=>window.clearTimeout(to))
  }, [])

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const setfn = (callback:(...args:any)=>void, ms?:number, ...args:any) => {
    const to = window.setTimeout(()=>{
      callback(...args)
      ref.current.delete(to)
    }, ms)
    ref.current.set(to, to)
    return to
  }
  const clearfn = (to:number) => {
    window.clearTimeout(ref.current.get(to))
    ref.current.delete(to)
  }
  return { setTimeout: setfn, clearTimeout: clearfn }
}
export default useSetTimeout