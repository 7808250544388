import React from "react";
import EducationForm from "./EducationForm";
import EducationTable from "./EducationTable";
import "./EducationStep.css";
import ApplicationHeader from "../ApplicationHeader";

const EducationStep = (props) => {
  return (
    <div>
      <EducationTable
        educationEntries={props.educationEntries}
        handleDeleteEducation={props.handleDeleteEducation}
      />

      <EducationForm
        createEducation={props.createEducation}
        application={props.application}
        educationEntries={props.educationEntries}
        {...props}
      />
    </div>
  );
};

export default EducationStep;
