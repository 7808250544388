// Refactored From https://github.com/pushtell/react-bootstrap-date-picker/blob/master/src/index.jsx to work with React v16

import { MouseEvent } from 'react'
import { Button } from 'react-bootstrap'
import './DatePickerCalendar.css'

const setTimeToNoon = (date:Date) => {
  date.setHours(12);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);
  return date;
}

const getWeekNumber = (date:Date) => {
  const target  = new Date(date.valueOf());
  const dayNr   = (date.getDay() + 6) % 7;
  target.setDate(target.getDate() - dayNr + 3);
  const firstThursday = target.valueOf();
  target.setMonth(0, 1);
  if (target.getDay() !== 4) {
    target.setMonth(0, 1 + ((4 - target.getDay()) + 7) % 7);
  }
  return 1 + Math.ceil((firstThursday - target.valueOf()) / 604800000);
}

const daysInMonth = (month:number, year:number) => {
  return new Date(year, month + 1, 0).getDate()
}

const rotateDayLabels = (weekStartsOn=0, dayLabels:string[]) => {
  if (weekStartsOn > 1) {
    return dayLabels.slice(weekStartsOn).concat(dayLabels.slice(0, weekStartsOn))
  } else if (weekStartsOn === 1) {
    return dayLabels.slice(1).concat(dayLabels.slice(0,1));
  }
  return dayLabels
}

type Props = {
  selectedDate?: Date
  displayDate: Date
  minDate?: string | number | Date
  maxDate?: string | number | Date
  onChange: (d:Date)=>void
  dayLabels: string[]
  cellPadding: string
  weekStartsOn?: number
  showTodayButton?: boolean
  todayButtonLabel?: string
  roundedCorners?: boolean
  showWeeks?: boolean
}

const DatePickerCalendar = ({
  selectedDate,
  displayDate, minDate, maxDate, onChange,
  dayLabels=['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
  cellPadding, weekStartsOn=0, showTodayButton, todayButtonLabel, roundedCorners, showWeeks=false
}:Props) =>
{
  dayLabels = rotateDayLabels(weekStartsOn, dayLabels)
  const currentDate = setTimeToNoon(new Date());
  selectedDate = selectedDate ? setTimeToNoon(new Date(selectedDate)) : undefined;
  minDate = minDate ? setTimeToNoon(new Date(minDate)) : undefined;
  maxDate = maxDate ? setTimeToNoon(new Date(maxDate)) : undefined;

  const handleClick = (e:MouseEvent<HTMLElement>) => {
    const day = e.currentTarget.getAttribute('data-day');
    const newSelectedDate = setTimeToNoon(new Date(displayDate));
    newSelectedDate.setDate(Number(day));
    onChange(newSelectedDate);
  };

  const handleClickToday = () => {
    const newSelectedDate = setTimeToNoon(new Date());
    onChange(newSelectedDate);
  }

  const year = displayDate.getFullYear();
  const month = displayDate.getMonth();
  const firstDay = new Date(year, month, 1);

  const startingDay = weekStartsOn > 1
    ? firstDay.getDay() - weekStartsOn + 7
    : weekStartsOn === 1
      ? (firstDay.getDay() === 0 ? 6 : firstDay.getDay() - 1)
      : firstDay.getDay();

  const monthLength = daysInMonth(month, year);
  const weeks = [];
  let day = 1;
  for (let i = 0; i < 9; i++) {
    const week = [];
    for (let j = 0; j <= 6; j++) {
      if (day <= monthLength && (i > 0 || j >= startingDay)) {
        let className = undefined;
        const date = new Date(year, month, day, 12, 0, 0, 0).toISOString();
        const beforeMinDate = minDate && Date.parse(date) < minDate.getTime();
        const afterMinDate = maxDate && Date.parse(date) > maxDate.getTime();
        let clickHandler:((e:MouseEvent<HTMLTableCellElement>)=>void)|undefined = handleClick;
        const style = { cursor: 'pointer', padding: cellPadding, borderRadius: roundedCorners ? 5 : 0 };

        if (beforeMinDate || afterMinDate) {
          className = 'text-muted';
          clickHandler = undefined;
          style.cursor = 'default';
        } else if (Date.parse(date) === selectedDate?.getTime()) {
          className = 'bg-primary';
        } else if (Date.parse(date) === currentDate.getTime()) {
          className = 'text-primary';
        }

        week.push(<td
          key={j}
          data-day={day}
          onClick={clickHandler}
          style={style}
          className={className}
        >
          {day}
        </td>);
        day++;
      } else {
        week.push(<td key={j} />);
      }
    }

    if (showWeeks){
      const weekNum = getWeekNumber(new Date(year, month,  day - 1, 12, 0, 0, 0));
      week.unshift(<td
          key={7}
          style={{padding: cellPadding, fontSize: '0.8em', color: 'darkgrey'}}
          className="text-muted"
      >
        {weekNum}
      </td>);

    }

    weeks.push(<tr key={i}>{week}</tr>);
    if (day > monthLength) {
      break;
    }
  }

  const weekColumn = showWeeks ?
    <td
      className="text-muted current-week"
      style={{padding: cellPadding}} /> :
    null;

  return (
    <table className="text-center">
      <thead>
        <tr>
          {weekColumn}
          {dayLabels.map((label, index)=>{
            return <td
              key={index}
              className="text-muted"
              style={{padding: cellPadding}}>
              <small>{label}</small>
            </td>;
          })}
        </tr>
      </thead>
      <tbody>
        {weeks}
      </tbody>
      {showTodayButton && <tfoot>
        <tr>
          <td colSpan={dayLabels.length} style={{ paddingTop: '9px' }}>
            <Button
              block
              className="u-today-button"
              onClick={handleClickToday}>
              {todayButtonLabel}
            </Button>
          </td>
        </tr>
      </tfoot>}
    </table>
  )
}

export default DatePickerCalendar