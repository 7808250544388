import React, { Component } from "react";
import axios from "axios";
import { Form, Button, Card } from "react-bootstrap";
import Loader from "../Loader";
import "./Event.css";

export default class EventForm extends Component {
  state = {
    loading: true,
    error: null,
    roles: [],
    event: {
      name: "",
      description: "",
      date: "",
      type: "event",
      role: "",
      chapter: this.props.chapter,
      EventLinks: [],
    },
    newLinkName: "",
    newLinkUrl: "",
  };

  componentDidMount() {
    this.loadRoles().then(() => {
      this.loadEvent(this.props.id);
    });

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleLinkAdd = this.handleLinkAdd.bind(this);
    this.handleLinkRemove = this.handleLinkRemove.bind(this);
  }

  componentDidUpdate() {
    if (!this.props.id && this.state.event.id) {
      this.resetEvent();
    } else if (this.props.id != this.state.event.id) {
      this.loadEvent(this.props.id);
    }
  }

  loadRoles() {
    return axios
      .get(`/api/roles/`)
      .then((response) => {
        var roles = response.data
          .sort((a, b) => {
            return b.permission_level - a.permission_level;
          })
          .filter((role) => role.permission_level >= 65);

        this.setState({
          roles: roles,
        });
      })
      .catch((error) => {
        this.setState({
          loading: false,
          error: "Unable to load roles!",
        });
      });
  }

  loadEvent(id) {
    if (!id) {
      this.resetEvent();
      return;
    }

    return axios
      .get(`/api/events/${id}`)
      .then((response) => {
        this.setState({
          loading: false,
          event: response.data,
        });
      })
      .catch((error) => {
        this.setState({
          loading: false,
          error: "Unable to load form!",
        });
      });
  }

  resetEvent() {
    this.setState({
      loading: false,
      event: {
        name: "",
        description: "",
        date: "",
        type: "event",
        role: this.state.roles[0].id,
        chapter: this.props.chapter,
        EventLinks: [],
      },
    });
  }

  // Handlers
  handleChange(e) {
    let event = this.state.event;
    event[e.target.name] = e.target.value;

    this.setState({
      event: event,
    });
  }

  handleLinkAdd(e) {
    e.preventDefault();

    let event = this.state.event;
    event.EventLinks.push({
      name: this.state.newLinkName,
      url: this.state.newLinkUrl,
    });

    this.setState({
      event: event,
      newLinkName: "",
      newLinkUrl: "",
    });
  }

  handleLinkRemove(index) {
    let event = this.state.event;
    event.EventLinks.splice(index, 1);

    this.setState({
      event: event,
    });
  }

  handleSubmit(e) {
    e.preventDefault();

    // Validate Form
    let form = e.currentTarget;
    let isValid = form.checkValidity();
    if (!isValid) {
      return false;
    }

    // If valid, send to the backend
    axios.post(`/api/events/`, this.state.event).then((res) => {
      if (this.props.onSaved) {
        this.props.onSaved(res.data);
      }
    });
  }

  handleDelete(e) {
    var confirmed = window.confirm(
      "Are you sure you want to delete this item? This cannot be undone."
    );
    if (confirmed) {
      axios.delete(`/api/events/${this.state.event.id}`).then((res) => {
        if (this.props.onDeleted) {
          this.props.onDeleted(this.state.event.id);
        }
      });
    }
  }

  handleCancel(e) {
    if (this.props.onCancel) {
      this.props.onCancel(e.target.value);
    }
  }

  render = () => {
    if (this.state.loading) {
      return <Loader />;
    }

    let event = this.state.event;
    return (
      <div className="form-container">
        <Form key={event.id} id="event-form" onSubmit={this.handleSubmit}>
          <h1>
            {this.state.event.id
              ? 'Editing "' + event.name + '"'
              : "Creating Item"}
          </h1>
          <hr />
          <h2>Item Information</h2>

          <Form.Group>
            <Form.Label>Name</Form.Label>
            <Form.Control
              name="name"
              value={event.name}
              required
              onChange={this.handleChange}
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              name="description"
              value={event.description}
              onChange={this.handleChange}
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>Date</Form.Label>
            <Form.Control
              type="date"
              name="date"
              value={event.date}
              required
              onChange={this.handleChange}
            />
          </Form.Group>

          <Form.Group>
            <label>Type</label>
            <Form.Control
              as="select"
              name="type"
              value={event.type}
              required
              onChange={this.handleChange}
            >
              <option value="event">Event</option>
              <option value="task">Task</option>
            </Form.Control>
          </Form.Group>

          <Form.Group>
            <Form.Label>Permissions</Form.Label>
            <Form.Control
              as="select"
              name="role"
              value={event.role}
              required
              onChange={this.handleChange}
            >
              {this.state.roles.map((role) => {
                return (
                  <option key={role.id} value={role.id}>
                    {role.name[0].toUpperCase() + role.name.slice(1)}
                  </option>
                );
              })}
            </Form.Control>
            <Form.Text className="text-muted">
              Minimum permissions required to view this item. This includes the
              selected role and each role above it.
            </Form.Text>
          </Form.Group>
        </Form>
        <hr />
        <h2>Links</h2>
        {this.state.event.EventLinks && this.state.event.EventLinks.length ? (
          <ul>
            {this.state.event.EventLinks.map((link, index) => {
              return (
                <li key={index}>
                  <a href={link.url}>{link.name}</a> (
                  <Button
                    onClick={() => this.handleLinkRemove(index)}
                    variant="link-danger"
                    size="sm"
                  >
                    Remove Link
                  </Button>
                  )
                </li>
              );
            })}
          </ul>
        ) : (
          <p className="text-muted">
            No links yet! If you'd like to include links, use the form below.
          </p>
        )}
        <Card border="dark" bg="light">
          <Card.Header>Add Link</Card.Header>
          <Card.Body>
            <Form onSubmit={this.handleLinkAdd}>
              <Form.Group>
                <Form.Label htmlFor="newLinkName">Link Name</Form.Label>
                <Form.Control
                  name="newLinkName"
                  value={this.state.newLinkName}
                  required
                  onChange={(e) =>
                    this.setState({ newLinkName: e.target.value })
                  }
                />
              </Form.Group>
              <Form.Group>
                <Form.Label htmlFor="newLinkUrl">Link Url</Form.Label>
                <Form.Control
                  name="newLinkUrl"
                  value={this.state.newLinkUrl}
                  required
                  onChange={(e) =>
                    this.setState({ newLinkUrl: e.target.value })
                  }
                />
              </Form.Group>

              <Button type="submit">Add Link</Button>
            </Form>
          </Card.Body>
        </Card>
        <hr />
        <Button type="submit" form="event-form">
          Save Item
        </Button>{" "}
        {event.id ? (
          <Button variant="outline-danger" onClick={this.handleDelete}>
            Delete Item
          </Button>
        ) : null}{" "}
        <Button variant="outline-dark" onClick={this.handleCancel}>
          Cancel
        </Button>
      </div>
    );
  };
}
