import React, { Component } from "react";
import { Alert, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faComments,
  faVideo,
  faClipboardList,
  faTh,
} from "@fortawesome/free-solid-svg-icons";
import { getProjectFairDetails } from "../../services/projectFairDetails.js";
import PermissionLevel from "../../data/PermissionLevel";
import ProjectCard from "./ProjectCard";
import MultiSelect from "../MultiSelect";
import "./Projects.css";

class ProjectFair extends Component {
  constructor(props) {
    super(props);
    this.multiSelect1Ref = React.createRef();
    this.multiSelect2Ref = React.createRef();
  }

  state = {
    fairDetails: {
      welcome_msg: null,
      closing_msg: null,
      projects: [],
    },
    possibleTags: [],
    possibleLocations: [],
    filteredProjects: [],
    groups: [],
    openAll: false,
    currentTime: parseInt(new Date().getTime() / 1000),
    tagFilters: [],
    locationFilters: [],
    isClear: true,
  };

  componentDidMount = async () => {
    let details = await getProjectFairDetails(this.props.params.year);
    let possibleTags = [
      ...new Set(
        details.ApprovedProjects.map((p) =>
          p.tags.map((t) => t.tag.name)
        ).flat()
      ),
    ];
    let possibleLocations = [...new Set(
      details.ApprovedProjects.map((d) => d.Chapter.location)
    )
    ]


    this.setState({
      fairDetails: {
        projects: details.ApprovedProjects,
        ...details,
      },
      active: details.end_date_sec > this.state.currentTime,
      possibleTags,
      possibleLocations
    }, (() => {
      window.addEventListener("resize", this.updateCols);
      this.updateCols();
    }));
  };

  isOfficer() {
    var userRole = this.props.currentUser.Role;
    return userRole.permission_level >= PermissionLevel.Officer;
  }

  clearAllMultiSelections = () => {
    this.multiSelect1Ref.current.clearDataList();
    this.multiSelect2Ref.current.clearDataList();
    this.multiSelect1Ref.current.handleChange([]);
    this.multiSelect2Ref.current.handleChange([]);
  };

  toggleCollapse = (e) => {
    this.setState({
      openAll: !this.state.openAll,
    });
  };

  updateCols = () => {
    let projects = this.state.fairDetails.projects;
    if (this.state.tagFilters.length > 0) {
      projects = projects.filter((p) => {
        return p.tags.some((t) => this.state.tagFilters.includes(t.tag.name));
      });
    }

    if (this.state.locationFilters.length > 0) {
      projects = projects.filter((p) => {
        if (this.state.locationFilters.includes(p.Chapter.location)) {
          return true;
        }
        return false;
      });
    }

    let width = document.body.clientWidth;
    let breakpoints = [600, 1000, Infinity];
    let cols = 0;

    for (var i = 0; i < breakpoints.length; i++) {
      if (width < breakpoints[i]) {
        cols = i + 1;
        break;
      }
    }

    let n = projects.length;
    let groupLists = [];
    for (var j = 0; j < n; j++) {
      if (!groupLists[j % cols]) {
        groupLists[j % cols] = [];
      }
      groupLists[j % cols].push(projects[j]);
    }

    this.setState({ groups: groupLists });
  };


  render() {
    let welcome = "";
    let closing = "";
    let directions = "";
    let survey = "";
    let groups = "";

    if (this.state.fairDetails) {
      if (this.state.fairDetails.welcome_msg) {
        welcome = (
          <section className="project-fair-text">
            {this.state.fairDetails.welcome_msg.split("\n").map((i) => {
              return (
                <p key={"intro" + i} className="intro">
                  {i}
                </p>
              );
            })}
          </section>
        );
      }

      if (this.state.fairDetails.closing_msg) {
        closing = (
          <section className="project-fair-text">
            <h4>Special Thanks</h4>
            {this.state.fairDetails.closing_msg.split("\n").map((i) => {
              return (
                <p key={"closing" + i} className="intro">
                  {i}
                </p>
              );
            })}
          </section>
        );
      }

      if (this.state.fairDetails.directions) {
        directions = this.state.fairDetails.directions.split("\n").map((i) => {
          return <p key={"directions" + i}>{i}</p>;
        });
      }

      if (
        this.state.fairDetails.survey_link &&
        this.state.fairDetails.survey_text
      ) {
        survey = (
          <a
            href={this.state.fairDetails.survey_link}
            target="_blank"
            rel="noopener noreferrer"
          >
            {this.state.fairDetails.survey_text}
          </a>
        );
      }

      if (this.state.groups.length > 0) {
        groups = (
          <section className="project-lists">
            {this.state.groups.map((g, i) => (
              <ul key={i} className="project-fair-list">
                {g.map((p) => (
                  <li key={p.id} className="project">
                    <ProjectCard
                      data={p}
                      editable={this.isOfficer()}
                      collapsible={true}
                      open={this.state.openAll}
                      live={this.state.active}
                    />
                  </li>
                ))}
              </ul>
            ))}
          </section>
        );
      }

      return (
        <main className="project-fair">
          <section className="project-fair-header">
            <h1 className="section-title">{this.state.fairDetails.title}</h1>
            <h2>{this.state.fairDetails.friendly_date}</h2>
            <h2>{this.state.fairDetails.friendly_time}</h2>

            {!this.state.active && this.state.fairDetails.end_date_sec ? (
              <Alert variant="primary" className="project-fair-complete">
                <Alert.Heading>The Project Fair is over!</Alert.Heading>
                Thank you to all attendees and participants this year! Be sure
                to look out for communication about joining CLEAR next year!
              </Alert>
            ) : null}
          </section>

          <section className="project-fair-info">
            <div>
              <FontAwesomeIcon icon={faVideo} size="3x" />
              <h4>Watch Live Streams</h4>

              {this.state.active
                ? directions
                : "Miss the fair? You can still watch the presentations! Recordings will be posted for each project below as they become available."}
            </div>

            <div>
              <FontAwesomeIcon icon={faComments} size="3x" />
              <h4>Join Our Channel</h4>
              {this.state.fairDetails.support_channel_link ? (
                <p>
                  Questions? Comments? Suggestions? Join us in the{" "}
                  <a
                    href={this.state.fairDetails.support_channel_link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {this.state.fairDetails.support_channel_name}
                  </a>{" "}
                  channel.
                </p>
              ) : (
                <p className="text-muted">
                  Our Project Fair Teams channel is coming soon!
                </p>
              )}
            </div>

            <div>
              <FontAwesomeIcon icon={faClipboardList} size="3x" />
              <h4>Provide Feedback</h4>
              {survey ? (
                survey
              ) : (
                <p className="text-muted">
                  We'll have a survey ready after the project fair begins!
                </p>
              )}
            </div>
          </section>

          {welcome}


            <section className="project-fair-controls">
              <div className="project-fair-filters">
                <div className="project-tag-input">
                  <MultiSelect
                    id="tag-filter"
                    label="Filter By Topic"
                    isShowClearAll={false}
                    datalist={this.state.possibleTags}
                    ref={this.multiSelect1Ref}
                    onChange={(s) =>
                      this.setState({ tagFilters: s }, this.updateCols)
                    }
                  />
                </div>

                <div className="project-location-input">
                  <MultiSelect
                    id="location-filter"
                    type="select"
                    label="Filter By Location"
                    isShowClearAll={false}
                    datalist={this.state.possibleLocations}
                    ref={this.multiSelect2Ref}
                    onChange={(s) => this.setState({ locationFilters: s }, this.updateCols)}
                  />
                </div>
                 <div className="project-clear-filter">
                   <Button onClick={this.clearAllMultiSelections}>
                   Clear Filter
                   </Button>
                </div>
              </div>
            {this.state.groups.length > 0 ? (
              <div className="toggle-collapse">
                <div className="custom-control custom-switch">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="customSwitchesChecked"
                    onChange={this.toggleCollapse.bind(this)}
                    defaultChecked
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="customSwitchesChecked"
                  >
                    Collapse All
                  </label>
                </div>
              </div>
           ) : null}
            </section>


          {(groups && this.state.possibleLocations.length && this.state.possibleTags.length)? (
            groups
          ) : (
            (this.state.possibleLocations.length || this.state.possibleTags.length) ? (
              <section className="project-fair-text text-center">
                <div>
                  <p>
                    <FontAwesomeIcon
                      icon={faTh}
                      size="6x"
                      className="text-muted text-faded-25"
                    />
                  </p>
                  <p className="text-muted">No Results Found!</p>
                </div>
              </section>
            ) : (
              <section className="project-fair-text text-center">
                <div>
                  <p>
                    <FontAwesomeIcon
                      icon={faTh}
                      size="6x"
                      className="text-muted text-faded-25"
                    />
                  </p>
                  <p className="text-muted">Project list coming soon!</p>
                </div>
              </section>
            )
          )
        }
          {closing}
        </main>
      );
    }
  }
}

export default ProjectFair;
