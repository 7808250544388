import React from "react";
import CareerForm from "./CareerForm";
import CareerTable from "./CareerTable";
import ApplicationHeader from "../ApplicationHeader";

const CareerStep = (props) => {
  return (
    <div>
      <p>
        Include career history for the past 5 years, including both Comcast and
        non-Comcast experience.
      </p>
      <CareerTable
        careerEntries={props.careerEntries}
        handleDeleteCareer={props.handleDeleteCareer}
      />
      <CareerForm {...props} />
    </div>
  );
};

export default CareerStep;
