import React, {Component, useState} from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import RangeSlider from 'react-bootstrap-range-slider';
import "./CompetenciesStep.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { COMPETENCIES_MENTEE_INFO, COMPETENCIES_MENTOR_INFO, COMPETENCIES_COMMITTEE_MEMBER_INFO, COMPETENCIES_ADDITIONAL_SKILLS_PROMPT, } from '../constants';
import {
    faChevronRight,
    faChevronLeft,
    faInfoCircle
} from "@fortawesome/free-solid-svg-icons";
import ApplicationHeader from "../ApplicationHeader";
import SaveAndStepButton from "../SaveAndStepButton.jsx";


class CompetenciesStep extends Component {
    thisPageStep = 2;
    onContinue = async () => {
        this.props.jumpToStep(this.thisPageStep + 1);
    };

    onBack = async () => {
        this.props.jumpToStep(this.thisPageStep - 1);
    };

    state = {
        info: "",
        application: this.props.application,

        formattedRatings: {},
        //css is configured manually to support 6 ratings as columns; if adjusted see css for gradients / column specifics
        validRatings: [
            "Very Weak",
            "Weak",
            "Slightly Weak",
            "Slightly Strong",
            "Strong",
            "Very Strong",
        ],
        hasChanges: false,
        disabled: true,
        hideContinue: true,
        exampleHeading: "Example(s) of Development Request",
        sliderValue: 1,
    };

    // default object for competency rating
    compRating = {
        competency: null,
        rating: "1",
        examples: null,
        application_id: this.props.application.id,
        hasChanges: false,
    };

    componentDidMount = async () => {
        await this.props.getCompetencies(this.props.application.applicant_type_id);
        await this.formatRatings();
        this.props.competencies.forEach(comp => {
            const compId = comp.id;
            const currentRating = this.state.formattedRatings[compId];
            if (!currentRating || !currentRating.rating || currentRating.rating === "0") {
                this.handleRatingChange(compId, "1");
            }
        });
    };

    formatRatings = () => {
        return new Promise((resolve) => {
            let ratings = {};
            for (let index = 0; index < this.props.ratings.length; index++) {
                let id = this.props.ratings[index].competency;
                ratings[id] = this.props.ratings[index];
                delete ratings[id].created_at;
                delete ratings[id].updated_at;
            }
            this.setState({formattedRatings: ratings}, resolve);
        });
    };

    handleRatingChange = (compId, newValue) => {
        let newRatingAsString = String(newValue);

        this.setState(prevState => {
            let updatedRatings = {...prevState.formattedRatings};

            if (!updatedRatings[compId]) {
                updatedRatings[compId] = {...this.compRating, competency: compId};
            }

            updatedRatings[compId] = {
                ...updatedRatings[compId],
                rating: newRatingAsString
            };

            return {
                formattedRatings: updatedRatings,
                hasChanges: true
            };
        });
    };

    mapValueToLabel = (value) => {
      const index = value - 1;
      if (index >= 0 && index < this.state.validRatings.length) {
        return this.state.validRatings[index];
      }
      return "N/A";
    };

    handleSubmit = async (event) => {
        if (event) {
            event.preventDefault();
        }
        try {
            const applicationToSave = {
                ...this.state.application,
                additional_skills: this.formatTextForDatabase(this.state.application.additional_skills)
            };
            await this.props.saveApplication(applicationToSave);
            await this.props.createCompetenciesRatings(Object.values(this.state.formattedRatings));
            this.setState({
                hasChanges: false,
                disabled: false,
                hideContinue: false,
            });
        } catch (error) {
            console.error("Error during competencies ratings submission:", error);
        }
    };
    handleAdditionalSkillsChange = (event) => {
        const value = event.target.value.trim() ? event.target.value : '';
        this.setState({
            application: {
                ...this.state.application,
                additional_skills: value,
            },
            hasChanges: true,
        });
    };

    calculateRows(text) {
        if (text === null || text === undefined) {
            return 3;
        } else {
            const lines = text.split('\n');
            return lines.length > 3 ? lines.length : 3;
        }
    };

    formatTextForDatabase(text) {
        if (text === null || text === undefined) {
            return '';
        } else {
            let formattedText = text.replace(/\n{3,}/g, '\n\n');
            formattedText = formattedText.replace(/\n+$/, '');
            return formattedText;
        }
    }


    createTable = () => {
        let tableBody = this.props.competencies.map(comp => {
            let compId = comp.id;
            let compName = comp.name;
            let tooltipText = comp.tooltip_text;

            let rating = this.state.formattedRatings[compId]
                ? parseInt(this.state.formattedRatings[compId].rating, 10)
                : 1;

            return (
                <tr key={compId}>
                    <td>
                        {compName}
                        <OverlayTrigger
                            placement="right"
                            overlay={
                                <Tooltip
                                    id={`tooltip-${compName}`}
                                >
                                    {tooltipText.split('\n').map((line, index) => (
                                        <React.Fragment key={index}>
                                            {line}
                                            {index < tooltipText.split('\n').length - 1 ? <br/> : null}
                                        </React.Fragment>
                                    ))}
                                </Tooltip>
                            }
                        >
                            <FontAwesomeIcon
                                icon={faInfoCircle}
                                size="lg" // Adjust the size as needed
                                className="ml-2"
                                style={{color: 'var(--primary)'}} // Use your primary color variable or hardcoded color
                            />
                        </OverlayTrigger>
                    </td>
                    <td colSpan="6" className="multi-span">
                        <div className="slider-container">
                            <RangeSlider
                                value={rating}
                                onChange={e => this.handleRatingChange(compId, e.target.value)}
                                min={1}
                                max={6}
                                tooltip='auto'
                                tooltipLabel={this.mapValueToLabel}
                            />
                        </div>
                    </td>
                </tr>
            );
        });

        return (
            <Table striped bordered hover className="competency-table">
                <thead>
                <tr>
                    <th>Skill</th>
                    {this.state.validRatings.map((rating, index) => (
                        <th key={index}>{rating}</th>
                    ))}
                </tr>
                </thead>
                <tbody>{tableBody}</tbody>
            </Table>
        );
    };

    render() {
        const minRows = 3;
        const defaultRows = Math.max(this.calculateRows(this.state.application.additional_skills), minRows);

        let info = ""
        switch (this.props.application.ApplicantType.id) {
          case 1:
            info = COMPETENCIES_MENTEE_INFO;
            break;
          case 2:
            info = COMPETENCIES_MENTOR_INFO;
            break;
          case 3:
            info = COMPETENCIES_COMMITTEE_MEMBER_INFO;
            break;
          default:
            info = `${this.props.applicantTypeId}`; // Assuming this needs to stay dynamic based on props
        }
        let additional_skills_prompt = COMPETENCIES_ADDITIONAL_SKILLS_PROMPT;


        return (
            <div>
                <ApplicationHeader message={info}/>

                <Form onSubmit={this.handleSubmit}>
                    {this.createTable()}
                    <Form.Group controlId="formAdditionalSkills">
                        <p style={{
                              fontSize: '1.05rem',
                              textAlign: 'center',
                              margin: '1em auto',
                              marginBottom: '1em',
                              marginTop: '2.5em',
                              maxWidth: '36em'
                            }}>
                              {additional_skills_prompt}
                            </p>
                        <Form.Control
                            as="textarea"
                            placeholder="Enter additional skills or information here"
                                value={this.state.application.additional_skills || ""}
                            onChange={this.handleAdditionalSkillsChange}
                            rows={defaultRows}
                        />
                    </Form.Group>
                    <div className="row nav-btns justify-content-between">
                        <SaveAndStepButton
                            buttonText="Back"
                            icon={faChevronLeft}
                            iconPosition="left"
                            className="col-2"
                            variant="primary"
                            fieldsToCheck={[]}
                            onSave={this.handleSubmit}
                            onSuccessFunction={this.onBack}
                        />
                        <SaveAndStepButton
                            buttonText="Continue"
                            icon={faChevronRight}
                            iconPosition="right"
                            className="col-2"
                            variant="primary"
                            fieldsToCheck={[]}
                            onSave={this.handleSubmit}
                            onSuccessFunction={this.onContinue}
                        />
                    </div>
                </Form>
            </div>
        );
    }
}

export default CompetenciesStep;