import React, { Component } from "react";
import axios from "axios";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import PermissionLevel from "../../data/PermissionLevel";
import Loader from "../Loader";
import PageHeader from "../PageHeader";
import "./Pledge.css";

class Pledge extends Component {
  state = {
    applications: null,
    applicationLoading: true,
    selectedFile: null,
    commitments: [
      "I am responsible for my own success in the program.",
      "I will be open to feedback, new ideas, trying new things, and I will be respectful of others.",
      "I understand the expectations that are being asked of me, what my deliverables are, and what I’m being asked to commit to the relationships I will make this year.",
      "I understand that a prototype or other working concept deliverable is required to graduate the program.",
      "I understand it is expected that I am on time or early to every class session, and I understand that electronics discipline will be a requirement in these sessions.",
      "I understand that people are committing their time and energy to my success and as such, I will value their time and will be considerate when asking for it.",
      "I will be responsible for all deadlines in the program, and I understand that the program will be communicating to me 1) in person at each session 2) via email 3) via the Slack channel.",
      "I understand that that attendance is required for all monthly sessions, and that by missing more than one session, I may be ineligible to graduate without participating in the exception process with the Committee.",
      "I will be responsible for communicating with my Executive Sponsor over the course of the project and meeting their expectations as input on my project’s final deliverable.",
    ],
    acknowledged: false,
    approvedMentees: [],
    approvedAll: false,
  };

  componentDidMount() {
    let applicationIds = [];
    let permissionLevel = this.props.currentUser.Role.permission_level;

    // For Mentors, Committee, Officers, Admins,
    // Get any applications from their assigned triad.
    if (permissionLevel >= PermissionLevel.Mentor) {
      axios
        .get("api/applications/triad")
        .then((response) => {
          this.setState({
            applications: response.data
              .map((x) => x.Application)
              .filter((a) => {
                let isMenteeApplication =
                  a.ApplicantType.applicant_type === "mentee";
                let isForCurrentYear = a.year === new Date().getFullYear();
                return isMenteeApplication && isForCurrentYear;
              }),
          });

          if (response.data) {
            response.data.forEach((r) => {
              if (r.Application) {
                applicationIds.push(r.Application.id);
              }
            });
          }
        })
        .then(() => {
          this.checkApplications(applicationIds);
        })
        .catch((error) => {
          this.setState({ applicationLoading: false });
        });
    } else {
      this.checkApplications(applicationIds);
    }
  }

  // For mentees, managers, get all submitted applications
  checkApplications(applicationIds) {
    axios
      .get("api/applications/my_application")
      .then((response) => {
        let apps = response.data.filter((a) => {
          let isMenteeApplication = a.ApplicantType.applicant_type === "mentee";
          let isCurrentYear = a.year === new Date().getFullYear();
          let isAccepted = a.status_id === 4;
          return isMenteeApplication && isCurrentYear && isAccepted;
        });

        if (this.state.applications) {
          apps = apps.concat(this.state.applications);
        }

        applicationIds = applicationIds.concat(response.data.map((x) => x.id));
        this.setState({
          applications: apps,
          applicationLoading: applicationIds.length > 0,
        });
      })
      .then(() => {
        if (applicationIds.length > 0) {
          this.checkAcknowledged(applicationIds);
        }
      })
      .catch((error) => {
        this.setState({ applicationLoading: false });
      });
  }

  // Check the current acknowledgement status of the given applications
  checkAcknowledged(appIds) {
    axios
      .post("api/grad_pledges", {
        application_ids: appIds,
        signed_by: this.props.currentUser.user_id,
      })
      .then((resp) => {
        this.setState({
          approvedMentees: resp.data.map((x) => x.application_id),
        });

        if (
          this.state.approvedMentees.length > 0 &&
          this.state.applications.every((x) =>
            this.state.approvedMentees.includes(x.id)
          )
        ) {
          this.setState({
            approvedAll: true,
            applicationLoading: false,
          });
        } else {
          this.setState({ applicationLoading: false });
        }
      });
  }

  determineUserRole(app) {
    let isMenteeApplication = app.ApplicantType.applicant_type === "mentee";
    let isApplicant = app.user_id === this.props.currentUser.user_id;
    let isManager =
      app.manager &&
      app.manager.toLowerCase() ===
        this.props.currentUser.User.email.toLowerCase();

    if (isMenteeApplication && isApplicant) {
      return "mentee";
    } else if (isManager) {
      return "manager";
    } else {
      return this.props.currentUser.Role.name;
    }
  }

  handleSubmit(e) {
    let payloads = [];
    this.state.applications.forEach((a) => {
      if (!this.state.approvedMentees.includes(a.id)) {
        payloads.push({
          application_id: a.id,
          signed_by: this.props.currentUser.user_id,
          signer_role: this.determineUserRole(a),
        });
      }
    });

    axios.post("api/grad_pledges/ack", payloads).then((_) => {
      this.setState({
        approvedAll: true,
      });
    });
  }

  changeCheck() {
    this.setState({
      acknowledged: !this.state.acknowledged,
    });
  }

  render = () => {
    if (this.state.applicationLoading) {
      return <Loader />;
    }

    let applications = this.state.applications;
    let noApplications = !applications || applications.length === 0;
    let commitments = this.state.commitments.map((c) => <li key={c}> {c} </li>);
    let acknowledgeMsg =
      this.props.currentUser.Role.name !== "mentee"
        ? "By checking this checkbox, I acknowledge that in addition to supporting and upholding the above values and duties of the Mentee, I promise to commit myself to supporting your success on this journey. My network is your network."
        : "By checking this checkbox, as a mentee in the CLEAR program, I acknowledge these commitments.";
    return (
      <>
        <PageHeader title="Your Pledge" />

        <div className="page-container">
          <h3>CLEAR Program Commitment</h3>
          <p>
            Please read the below and acknowledge your commitment to the
            program.
          </p>

          {noApplications ? (
            <Alert variant="info">
              No applications found. Please apply before acknowledging your
              pledge.
            </Alert>
          ) : null}

          <div className={noApplications ? "hidden" : ""}>
            <p>
              <b>Mentee(s)</b>:{" "}
              {applications.map((x) => x.User.name).join("; ")}
            </p>

            <ul>{commitments}</ul>

            <div className={this.state.approvedAll ? "hidden" : ""}>
              <div className="form-group">
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="acknowledged"
                    checked={this.state.acknowledged}
                    onChange={this.changeCheck.bind(this)}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="acknowledged"
                  >
                    <b>{acknowledgeMsg}</b>
                  </label>
                </div>
              </div>

              <Button
                onClick={this.handleSubmit.bind(this)}
                disabled={!this.state.acknowledged}
              >
                Acknowledge Commitment
              </Button>
            </div>

            <div className={this.state.approvedAll ? "" : "hidden"}>
              <Alert variant="success">
                <FontAwesomeIcon icon={faCheck} /> You have completed your
                pledge(s) for this year. Thank you!
              </Alert>
            </div>
          </div>
        </div>
      </>
    );
  };
}

export default Pledge;
