import { DateTime } from "luxon"

const timeAgo = (dt: DateTime) => {
  if (!dt.isValid) return '-'
  const units:Intl.RelativeTimeFormatUnit[] = ['year', 'month', 'week', 'day', 'hour', 'minute', 'second']
  const diff = dt.diffNow().shiftTo(...units)
  const unit = units.find((unit) => diff.get(unit) !== 0) || 'second'
  // TODO handle other languages?
  const relativeFormatter = new Intl.RelativeTimeFormat('en', { numeric: 'auto', })
  return relativeFormatter.format(Math.trunc(diff.as(unit)), unit)
}

export default timeAgo