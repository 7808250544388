import { useEffect, useState } from 'react'
import DatePicker from '../DatePicker'
import Select from 'react-select'
import classNames from '../../util/classNames'
import { DateTime } from 'luxon'
import './DateTimePicker.css'

const hourOptions = new Array(24).fill(0).map((_,i)=>{
  const l = (i%12 == 0) ? `12:00 ${i == 0 ? 'AM':'PM'}` : i < 12 ? `${i}:00 AM` : `${i-12}:00 PM`
  const v = i < 10 ? `0${i}:00` : `${i}:00`
  return {label:l, value:v}
})

const tzAbbr = (d:string, h:string) => {
  console.log('tzAbbr', d, h)
  if (!d || !d.trim()) return ''
  return DateTime.fromISO(d.slice(0,11) + h).toFormat('ZZZZ')
}

interface Props {
  value:string // iso-format
  onChange:(s:string)=>void
  invalid?:boolean
}
const DateTimePicker = ({value, onChange, invalid=false}:Props) => {
  console.log('value:', value)
  const d = new Date(value)
  console.log('d:', d)
  const [date, _setDate] = useState(d.toLocaleDateString('sv')+'T00:00Z')
  const [hour, _setHour] = useState(hourOptions[d.getHours()])

  const dateFor = (d:string, h:string) => new Date(d.slice(0,11) + h)
  const setDate = (s:string) => {
    if (!s) return
    _setDate(s)
    const d = dateFor(s, hour.value)
    if (onChange) onChange(d.toISOString())
  }
  const setHour = (h:{label:string, value:string}) => {
    if (!h) return
    _setHour(h)
    const d = dateFor(date, h.value)
    if (onChange) onChange(d.toISOString())
  }

  useEffect(()=>{
    _setDate(d.toLocaleDateString('sv')+'T00:00Z')
    _setHour(hourOptions[d.getHours()])
  }, [value])

  return (
    <div className='datetime-picker d-flex align-items-center'>
      <DatePicker utc
        value={value} onChange={setDate}
        className={classNames('mr-2', {"is-invalid":!!invalid})}
        placeholder='Pick a date...'
      />
      <Select
        className={classNames('react-select', {"is-invalid":!!invalid})}
        value={hour} onChange={x=>{if(x) setHour(x)}}
        options={hourOptions}
      />
      <span className='tz-abbr'>{tzAbbr(date, hour.value)}</span>
    </div>
  )
}
export default DateTimePicker