import { MouseEventHandler } from "react";
import { Button, Container } from "react-bootstrap";

type Props = {
  error:string
  onRetry?:MouseEventHandler
}
const ErrorMessage = ({error, onRetry}:Props) => (
  <Container style={{paddingTop: "2em"}}>
    <h2>An unexpected error occurred:</h2>
    <h3>{error}</h3>
    {onRetry ? <Button onClick={onRetry}>Retry</Button> : null}
  </Container>
)

export default ErrorMessage
