import { Alert } from "react-bootstrap";
import Moment from "../../Moment";
import { useContext } from "react";
import ApplicationWindowContext from "../ApplicationContainer/ApplicationWindowContext";

const DeadlineAlert = () => {
  const {opening, softDeadline, deadline} = useContext(ApplicationWindowContext)

  const withinApplicationWindow = () => {
    const now = new Date();
    let areAfterOpening = opening < now;
    let areBeforeClosing = deadline > now;
    return areAfterOpening && areBeforeClosing;
  }

  const pastApplicationWindow = () => {
    const now = new Date();
    let areAfterClosing = deadline < now;
    return areAfterClosing;
  }

  return (<>
    <p></p>
    {pastApplicationWindow() ? (
      <Alert variant="info">
        The application window has closed for the year!
      </Alert>
    ) : !withinApplicationWindow() ? (
      <Alert variant="primary">
        The application window opens on{" "}
        <strong>
          <Moment format="dddd, MMMM D, YYYY">{opening}</Moment>
        </strong>
        !
      </Alert>
    ) : (
      <Alert variant="danger">
        The deadline for applications is{" "}
        <strong>
          <Moment format="dddd, MMMM D, YYYY">
            {softDeadline}
          </Moment>{" "}
          COB
        </strong>
        !
      </Alert>
    )}
  </>)
}

export default DeadlineAlert