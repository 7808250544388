import React, { Component } from "react";
import axios from "axios";
import BootstrapTable from "../../../../react-bootstrap-table/packages/react-bootstrap-table2";
import filterFactory, { selectFilter } from "../../../../react-bootstrap-table/packages/react-bootstrap-table2-filter";
import cellEditFactory, { Type } from "../../../../react-bootstrap-table/packages/react-bootstrap-table2-editor";
import { Typeahead } from "react-bootstrap-typeahead";
import { Form, Col, Button, Spinner, Badge } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faIdBadge,
  faSort,
  faSortUp,
  faSortDown,
} from "@fortawesome/free-solid-svg-icons";
import {
  getAllUsers,
  getProgramUsers,
  saveUser,
  removeUser,
} from "../../../../services/users.js";
import "./ManageUsers.css";
import Unauthorized from "../../../Unauthorized";
import PermissionLevel from "../../../../data/PermissionLevel";
import ConfirmDeleteButton from "../../../ConfirmDeleteButton";

//TODO: this is also used in the Submissions.js, abstract it out
function sortCaret(order, column) {
  if (order === undefined) {
    return <FontAwesomeIcon icon={faSort} />;
  } else if (order === "asc") {
    return <FontAwesomeIcon icon={faSortUp} />;
  } else if (order === "desc") {
    return <FontAwesomeIcon icon={faSortDown} />;
  }
}

class ManageUsers extends Component {
  state = {
    validPermissionLevel: 90,
    users: [],
    allUsers: [],
    chapters: [],
    roles: [],
    showUsersDropdown: false,
    canUpdate: this.props.currentUser.Role.permission_level >= PermissionLevel.Officer,
    fields: [],
    addError: "",
    selUser: null,
    simUser: null,
    selRole: -1,
    selChapter: -1,
    saving: false,
    loading: true,
  };

  componentDidMount = async () => {
    let programUsers = await getProgramUsers();
    let allUsers = await getAllUsers();
    let chapters = await axios.get('/api/chapters').then(r => r.data);
    let roles = await axios.get('/api/roles').then(r => r.data);
      
    this.setState({
      allUsers,
      users: programUsers,
      chapters: chapters,
      roles: roles,
      loading: false,
      fields: [
        {
          dataField: "User.name",
          text: "Name",
          sort: true,
          sortCaret: sortCaret,
          editable: false,
        },
        {
          dataField: "year",
          text: "Year",
          sort: true,
          sortCaret: sortCaret,
          editable: false,
        },
        {
          dataField: "Chapter.id",
          text: "Chapter",
          sort: true,
          sortCaret: sortCaret,
          formatter: (cell, row) => {
            return chapters.find(c => Number(c.id) === Number(cell))?.location || '';
          },
          filter: selectFilter({
            options: chapters.reduce((opts, c) => Object.assign(opts, { [c.id]: c.location }), {}),
          }),
          classes: "table-cell-editable",
          editCellClasses: "table-cell-editing",
          editable: this.state.canUpdate,
          editor: {
            type: Type.SELECT,
            options: chapters.map((c) => ({ value: c.id, label: c.location })),
          },
        },
        {
          dataField: "Role.id",
          text: "Role",
          sort: true,
          sortCaret: sortCaret,
          formatter: (cell, row) => {
            return roles.find(r => Number(r.id) === Number(cell))?.name || '';
          },
          filter: selectFilter({
            options: roles.reduce((opts, r) => Object.assign(opts, { [r.id]: r.name }), {}),
          }),
          classes: "table-cell-editable",
          editCellClasses: "table-cell-editing",
          editable: this.state.canUpdate,
          editor: {
            type: Type.SELECT,
            options: roles.map((r) => ({
              value: r.id,
              label: r.name,
            }))
          },
        },
        {
          dataField: "Actions",
          text: "",
          editable: false,
          formatter: (cell, row, rowIndex) => {
            return (
              <ConfirmDeleteButton
                confirmLabel="Delete User"
                disabled={!this.state.canUpdate}
                onDelete={() => this.deleteUser(row)}
                justify='left'
              />
            );
          },
        },
      ]
    });
  };

  addUser = async () => {
    if (this.validateUser()) {
      this.setState({ saving: true });

      let user = {
        user_id: this.state.selUser[0].id,
        chapter_id: this.state.selChapter,
        role_id: this.state.selRole,
        year: new Date().getFullYear(),
      };

      await this.saveUser(user);
      let userList = await getProgramUsers();

      this.setState({
        selUser: null,
        selChapter: -1,
        selRole: -1,
        users: userList,
        saving: false,
      });
    }
  };

  saveUser = async (user) => {
    await saveUser(user);
  };

  deleteUser = async (user) => {
    if (!this.state.canUpdate) {
      window.alert("You do not have permissions to edit");
      return;
    }

    removeUser(user);

    let users = [...this.state.users];
    let usersNew = users.filter((u) => u.user_id !== user.user_id);
    this.setState({
      users: usersNew,
    });
  };

  validateUser = () => {
    this.setState({ addError: "" });

    if (!this.state.selUser) {
      this.setState({
        addError:
          "You must select a user that hasn't already been assigned a role!",
      });
      return false;
    } else if (!this.state.selChapter) {
      this.setState({ addError: "You must select a chapter!" });
      return false;
    } else if (!this.state.selRole) {
      this.setState({ addError: "You must select a role!" });
      return false;
    }

    return true;
  };

  exportUsers = () => {
    if (
      !this.state.users ||
      !this.state.users.length ||
      !this.state.canUpdate
    ) {
      return;
    }

    // Get our list of users and define our header
    const users = this.state.users;
    let csv = "NTID/Email Address, Role 1, Role 2, Role 3,";

    // Iterate over users and generate rows as strings
    const checkedSet = new Set();
    users.forEach((user) => {
      if (!checkedSet.has(user.id)) {
        csv += `\n${user.User.email},${user.Role.name},${""},${""},`;
        checkedSet.add(user.id);
      }
    });

    // Generate a download
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8" });
    const fileName = `clear-users-${new Date()
      .toLocaleDateString()
      .split("/")
      .join("-")}.csv`;

    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    document.body.appendChild(a);
    a.setAttribute("style", "display: none");
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  };

  handleChange = (oldValue, newValue, row, column) => {
    if (!this.state.canUpdate) {
      window.alert("You do not have permissions to edit");
      return;
    }
    let user = row;
    if (column.dataField === "Chapter.id") {
      user.chapter_id = newValue;
    } else if (column.dataField === "Role.id") {
      user.role_id = newValue;
    }
    this.saveUser(user);

    let users = [...this.state.users];
    for (let idx in users) {
      if (column.dataField === "Chapter.id") {
        users[idx].chapter_id = newValue;
      } else if (column.dataField === "Role.id") {
        users[idx].role_id = newValue;
      }
    }
    this.setState(users);
  };

  render = () => {
    if (
      this.props.currentUser.Role.permission_level < PermissionLevel.Committee
    ) {
      return <Unauthorized />;
    }

    // a "fake permission" when the permission level is based on PERMISSION_LEVEL in development .env
    // a "fake permission" cannot login as another user, since they have no user id.
    const isFakePermission = this.props.currentUser.Role.name == 'user'

    const hasOfficerPermission = this.props.currentUser.Role?.permission_level >= PermissionLevel.Officer
    const canLoginAs = !isFakePermission && this.state.simUser?.length && this.state.simUser[0].id
    return (<>
        <div>
          <h3>Add a User Role</h3>
          <div className={!this.state.canUpdate ? "hidden" : ""}>
            <p>
              To add a member to this year's program, fill out and submit the
              form below. If a member has already been added to this year's
              program, you can edit their role in the table below.
            </p>

            {this.state.saving ? (
              <p>Adding Role...</p>
            ) : (
              <div>
                <p className="text-danger">{this.state.addError}</p>
                <Form onSubmit={(e) => e.preventDefault()}>
                  <Form.Row>
                    <Col sm={4}>
                      <Typeahead
                        id="user-name"
                        labelKey="name"
                        options={this.state.allUsers.filter((u) => {
                          return (
                            this.state.users.findIndex(
                              (v) => u.id === v.user_id
                            ) === -1
                          );
                        })}
                        placeholder="Enter Name..."
                        onChange={(selUser) => this.setState({ selUser })}
                      />
                    </Col>
                    <Col sm={3}>
                      <Form.Control
                        as="select"
                        value={this.state.selChapter}
                        onChange={(e) =>
                          this.setState({ selChapter: e.target.value })
                        }
                      >
                        <option value={-1}>Select Chapter...</option>
                        {this.state.chapters.map((c) => {
                          return (
                            <option key={c.id} value={c.id}>
                              {c.location}
                            </option>
                          );
                        })}
                      </Form.Control>
                    </Col>
                    <Col sm={3}>
                      <Form.Control
                        as="select"
                        value={this.state.selRole}
                        onChange={(e) =>
                          this.setState({ selRole: e.target.value })
                        }
                      >
                        <option value={-1}>Select Role...</option>
                        {this.state.roles.map((role) => {
                          return (
                            <option key={role.id} value={role.id}>
                              {role.name}
                            </option>
                          );
                        })}
                      </Form.Control>
                    </Col>
                    <Col>
                      <Button onClick={this.addUser}>Add User</Button>
                    </Col>
                  </Form.Row>
                </Form>
              </div>
            )}
            <hr />
          </div>
        </div>

        {hasOfficerPermission ? ( <div>
        <h3>Login as another user</h3>
        {isFakePermission ? <p>You'll need to add yourself as an Admin or Officer before you can login as another user.</p> : undefined}
        <div style={{margin:'0.5em 0'}}>
          <Form onSubmit={(e) => e.preventDefault()}>
            <Form.Row>
              <Col sm={4}>
                <Typeahead
                  id="user-name"
                  labelKey="name"
                  options={this.state.allUsers}
                  placeholder="Enter Name..."
                  onChange={(simUser) => this.setState({ simUser })}
                />
              </Col>
              <Col>
                <Button onClick={()=>this.props.loginAsUser(this.state.simUser[0].id)} disabled={!canLoginAs}>
                  Login As User</Button>
              </Col>
            </Form.Row>
          </Form>
        </div>
        <p>When you are logged in as another user, any changes you make will <i>are permanent</i> and are recorded as if that user made them.</p>
        <p>When you are done, select <b>End Login As</b> from the user menu.</p>
        <hr/>
        </div>) : undefined }

      <h3>User Roles</h3>
      <div className="d-flex">
        <p>
          Only Admins and Officers can manage user roles, committee
          members can view current roles.
        </p>
        <Button
          type="button"
          variant="success"
          className={!this.state.canUpdate ? "hidden" : ""}
          disabled={this.state.loading || !this.state.users.length}
          onClick={this.exportUsers}
        >
          Export Users
        </Button>
      </div>

      {this.state.loading ? (
        <Spinner animation="border"/>
      ) : (
        <BootstrapTable
          classes="admin-tbl"
          keyField="id"
          data={this.state.users}
          columns={this.state.fields}
          filter={filterFactory()}
          cellEdit={cellEditFactory({
            mode: "click",
            blurToSave: true,
            afterSaveCell: this.handleChange,
          })}
        />
      )}
    </>);
  };
}

export default ManageUsers;
