import React, {useContext, useEffect, useState} from "react";
import Button from "react-bootstrap/Button";
import { Modal } from "react-bootstrap";
import { TimeCommitmentModal } from '../TimeCommitment';
import ApplicationWindowContext from "../ApplicationContainer/ApplicationWindowContext";
import ApplicationHeader from "../ApplicationHeader.jsx";
import "./Requirements.css";
import RoleCard from "./RoleCard.jsx";
import ManagerApprovals from "./ManagerApprovals.jsx";
import { APPLICANT_TYPE_MENTEE, APPLICANT_TYPE_MENTOR, APPLICANT_TYPE_COMMITTEE, APPLICATION_STATUS_ID_PENDING, APPLICATION_STATUS_ID_SUBMITTED, APPLICATION_STATUS_ID_WITHDRAWN} from '../constants';
import SubmittedApp from "../SubmittedApp/index.jsx";
import app from "../../../App.jsx";
import { IntroVideoCard } from "./IntroVideoCard.jsx";


const Requirements = ({ application, saveApplication, applicantTypeId, setApplicantTypeId, jumpToStep, managerSubmissions, currentUser}) => {
  const [showTimeCommitmentModal, setShowTimeCommitmentModal] = useState(false);
  const [showTimeCommitmentModal_OKButton, setshowTimeCommitmentModal_OKButton] = useState(false);
  const [onCancelTimeComitModalToggle, setOnCancelTimeComitModalToggle] = useState(false);
  const [timeCommitmentApplicantType, setTimeCommitmentApplicantType] = useState(applicantTypeId)
  const [showConfirmWithdraw, setShowConfirmWithdraw] = useState(false);
  const { opening, deadline } = useContext(ApplicationWindowContext);

  const now = new Date()
  const isAfterOpening = opening < now;
  const isBeforeClosing = deadline > now;

  const getIsApplicantType = (type) => {
    if (application?.status_id != APPLICATION_STATUS_ID_WITHDRAWN
      && application?.ApplicantType?.id == type) {
      return true
    }
    return applicantTypeId == type
  }

  const isSubmitted = application?.status_id == APPLICATION_STATUS_ID_SUBMITTED || false;
  const isPending = !isSubmitted && (application?.status_id == APPLICATION_STATUS_ID_PENDING || applicantTypeId != null);
  const isMentor = getIsApplicantType(APPLICANT_TYPE_MENTOR)
  const isMentee = getIsApplicantType(APPLICANT_TYPE_MENTEE)
  const isCommittee = getIsApplicantType(APPLICANT_TYPE_COMMITTEE)
  const okToApply = !isPending && !isSubmitted && isAfterOpening && isBeforeClosing;
  const isApproved = application?.ManagerApproval;

  const startApplication = (type) => {
    setApplicantTypeId(type);
    setShowTimeCommitmentModal(false);
    if (application) {
      // set the applicant type, application status, and active flags when starting a previously withdrawn application
      application.ApplicantType = {
        id: type,
      }
      application.applicant_type_id = type
      application.ApplicationStatus = {
        id: APPLICATION_STATUS_ID_PENDING,
        status: "Pending"
      }
      application.active = true;
      application.status_id = APPLICATION_STATUS_ID_PENDING;
      saveApplication(application).then((res) => {
        jumpToStep(1);
      });
    } else {
      jumpToStep(1);
    }
  };

  const withdrawApplication = () => {
    setApplicantTypeId(null);
    if (!application) {
      setShowConfirmWithdraw(false);
      return;
    }
    application.active = false;
    application.status_id = APPLICATION_STATUS_ID_WITHDRAWN;
    application.ApplicationStatus = {
      id: APPLICATION_STATUS_ID_WITHDRAWN,
      status: "Withdrawn"
    }
    saveApplication(application);
    setShowConfirmWithdraw(false);
  };


  useEffect(() => {
    if (showTimeCommitmentModal_OKButton) {
      setShowTimeCommitmentModal(true);
    }
    if (!showTimeCommitmentModal_OKButton) {
      setShowTimeCommitmentModal(false);
    }
  }, [showTimeCommitmentModal_OKButton, onCancelTimeComitModalToggle]);


  const timeCommitButton = <Button variant="Link" style={{ margin: '-0.75em -1em', position: 'relative', top: '-0.15em', textDecoration: 'underline' }}
                                   onClick={() => {
                                     setshowTimeCommitmentModal_OKButton(true);
                                   }}>Time Commitments</Button>

  const onCancelHandler = () => {
    setshowTimeCommitmentModal_OKButton(false);
    setOnCancelTimeComitModalToggle(x => !x);
  };

  const roleCardProps = [
    {
      title: 'Mentor',
      text: (<>
        <p>Provides guidance and experience with thier Mentee</p>
        <p>Must be at least an Engineer/Architect&nbsp;5 and accept {timeCommitButton}</p>
      </>),
      roleText: 'Mentor',
      isPending, isSubmitted, okToApply,
      isApproved: true,
      approvedText: 'Application Submitted',
      isRole: isMentor,
      onBegin: () => {
        setTimeCommitmentApplicantType(APPLICANT_TYPE_MENTOR);
        setShowTimeCommitmentModal(true);
      },
      onWithdraw: () => setShowConfirmWithdraw(true),
      onContinue: () => jumpToStep(1)
    },
    {
      title: 'Mentee',
      text: (<>
        <p>Brings a project from inception to demonstration</p>
        <p>Must accept {timeCommitButton} and your manager must approve your participation</p>
      </>),
      roleText: 'Mentee',
      isPending, isSubmitted, okToApply, isApproved,
      approvedText: 'Application Submitted',
      isRole: isMentee,
      onBegin: () => {
        setTimeCommitmentApplicantType(APPLICANT_TYPE_MENTEE);
        setShowTimeCommitmentModal(true);
      },
      onWithdraw: () => setShowConfirmWithdraw(true),
      onContinue: () => jumpToStep(1)
    },
    {
      title: 'Committee Member',
      text: (<>
        <p>Support Mentors and Mentees on their CLEAR Journey</p>
      </>),
      roleText: 'Committee',
      isPending, isSubmitted, okToApply,
      isApproved: true,
      approvedText: 'Application Submitted',
      isRole: isCommittee,
      onBegin: () => startApplication(APPLICANT_TYPE_COMMITTEE),
      onWithdraw: () => setShowConfirmWithdraw(true),
      onContinue: () => jumpToStep(1)
    },
  ]
  const filteredRoleCardProps = isSubmitted
    ? roleCardProps.filter(card => card.isRole)
    : roleCardProps;

  return (<>
    <ManagerApprovals managerSubmissions={managerSubmissions} />
    <IntroVideoCard />
    <div className="requirements" style={{ textAlign: 'center' }}>
      <ApplicationHeader
          heading={!isSubmitted ? "Program Eligibility Guidelines" : "Your Application Has Been Submitted!"}
          message={!isSubmitted ? "You must have received an <i>Effective</i> or <i>Outstanding</i> rating on your last performance review and have not been on corrective action for the last twelve months" : ""}
      >
      </ApplicationHeader>
      <h2 style={{ textAlign: 'center', marginTop: '1em' }}></h2>
      <div style={{ display: 'flex', gap: '2rem', flexWrap: 'wrap', justifyContent: 'center' }}>
        {filteredRoleCardProps.map(props => <RoleCard key={props.title} {...props} />)}
      </div>
      <TimeCommitmentModal
        show={showTimeCommitmentModal}
        onCancel={() => {
          onCancelHandler()
        }}
        applicationType={timeCommitmentApplicantType}
        handleSubmit={() => startApplication(timeCommitmentApplicantType)}
        showOkButton={isSubmitted || isPending || showTimeCommitmentModal_OKButton}
      />
    </div>
    <Modal
      show={showConfirmWithdraw}
      onHide={() => setShowConfirmWithdraw(false)}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Are you sure?</Modal.Title>
      </Modal.Header>
      <Modal.Body as="div">
        <p>If you withdraw your application it will not be considered for inclusion in this year's cohort.</p>
        <p>By withdrawing your application you may apply for another role.</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={() => withdrawApplication()}>Yes, withdraw it</Button>
        <Button variant="dark" onClick={() => setShowConfirmWithdraw(false)}>No, keep it</Button>
      </Modal.Footer>
    </Modal>
    {isSubmitted && (
      <div style={{ border: '1px solid #ccc', padding: '1em', marginTop: '3em', borderRadius: '5px' }}>
        <ApplicationHeader
          heading="Your Submission"
          message="Details of your submitted application are shown below. You will be notified of the status of your application after the application deadline."
        />
        <SubmittedApp
          params={{ application_id: application.id }}
          currentUser={currentUser}
          hideHeader
        />
      </div>
    )}
  </>)
}

export default Requirements;

