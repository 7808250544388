import React, { Component } from "react";
import axios from "axios";
import { Button } from "react-bootstrap";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PermissionLevel from "../../data/PermissionLevel";
import Timeline from "./timeline";
import Event from "../Event/index";
import EventForm from "../Event/form";
import Unauthorized from "../Unauthorized";
import PageHeader from "../PageHeader";
import "./Dashboard.css";

export default class Dashboard extends Component {
  state = {
    loading: true,
    creating: false,
    editing: false,
    error: null,
    events: [],
    activeEvent: this.props.params.event_id,
    sidebarOpen: false,
  };

  componentDidMount() {
    this.loadEvents();
  }

  loadEvents() {
    var year = new Date().getFullYear();
    var chapter = this.props.currentUser.chapter_id;

    axios
      .get(`/api/events/${year}/${chapter}`)
      .then((response) => {
        let selEvent = parseInt(this.props.params.event_id) || null;
        if (!selEvent && response.data.length > 0) {
          selEvent = this.getNextEvent(response.data);
        }

        this.setState({
          loading: false,
          events: response.data,
          activeEvent: selEvent,
        });

        this.handleSelect(selEvent);
      })
      .catch((error) => {
        this.setState({
          loading: false,
          error: "Unable to load events!",
        });
      });
  }

  setEvent(eventId) {
    this.setState({
      creating: false,
      editing: false,
      activeEvent: eventId,
    });
  }

  getNextEvent(events) {
    let nextEvent = events[0].id;
    let currDate = new Date();
    currDate.setHours(0, 0, 0, 0);

    events.find((e) => {
      let eventDate = new Date(e.date + " 00:00:00");
      if (eventDate >= currDate) {
        nextEvent = e.id;
        return true;
      }

      return false;
    });

    return nextEvent;
  }

  toggleSidebar() {
    this.setState({
      sidebarOpen: !this.state.sidebarOpen,
    });
  }

  isOfficer() {
    var userRole = this.props.currentUser.Role;
    return userRole.permission_level >= PermissionLevel.Officer;
  }

  handleDeleted(eventId) {
    var events = this.state.events;

    for (let i = 0; i < events.length; i++) {
      if (events[i].id === eventId) {
        events.splice(i, 1);
        break;
      }
    }

    let nextEvent = this.getNextEvent(events);

    this.setState({
      editing: false,
      events: events,
      activeEvent: nextEvent,
    });
  }

  handleCreated(event) {
    var events = this.state.events;
    for (let i = 0; i < events.length; i++) {
      if (events[i].date > event.date) {
        events.splice(i, 0, event);
        break;
      }
    }

    this.setState({
      creating: false,
      events: events,
      activeEvent: event.id,
    });
  }

  handleUpdated(event) {
    var events = this.state.events;
    for (let i = 0; i < events.length; i++) {
      if (events[i].id === event.id) {
        events.splice(i, 1, event);
        break;
      }
    }

    this.setState({
      editing: false,
      events: events,
      activeEvent: event.id,
    });
  }

  renderHeader = () => {
    return (
      <PageHeader title="CLEAR Dashboard">
        {this.isOfficer() ? (
          <Button
            variant="primary"
            onClick={() => {
              this.setState({ creating: true });
            }}
          >
            + New Item
          </Button>
        ) : null}
      </PageHeader>
    );
  };

  render = () => {
    // Only Mentees and up can view this page
    let userLevel = this.props.currentUser.Role.permission_level;
    if (userLevel < PermissionLevel.Mentee) {
      return <Unauthorized />;
    }

    if (
      !this.state.creating &&
      !this.state.loading &&
      this.state.events.length === 0
    ) {
      return (
        <>
          {this.renderHeader()}
          <p className="text-center text-lg">
            <br />
            No events or tasks yet
          </p>
        </>
      );
    }

    return (
      <div>
        {this.renderHeader()}

        <div className="dash">
          <section
            className={
              "dash-timeline " + (this.state.sidebarOpen ? "open" : "")
            }
          >
            <header>
              <button
                onClick={() => this.toggleSidebar()}
                className="btn btn-primary dash-timeline-toggle"
              >
                <FontAwesomeIcon
                  icon={this.state.sidebarOpen ? faChevronLeft : faChevronRight}
                ></FontAwesomeIcon>
              </button>
            </header>

            {!this.state.loading ? (
              <div className="dash-timeline-list">
                <Timeline
                  role={this.props.currentUser.Role}
                  event={this.state.creating || this.state.activeEvent}
                  events={this.state.events}
                  onSelect={(eventId) => this.setEvent(eventId)}
                />
              </div>
            ) : null}
          </section>

          <section className="dash-event">
            {this.state.creating && this.isOfficer() ? (
              <EventForm
                id={null}
                chapter={this.props.currentUser.chapter_id}
                onSaved={(event) => this.handleCreated(event)}
                onCancel={() => this.setState({ creating: false })}
              />
            ) : this.state.editing && this.isOfficer() ? (
              <EventForm
                id={this.state.activeEvent}
                chapter={this.props.currentUser.chapter_id}
                onSaved={(event) => this.handleUpdated(event)}
                onDeleted={(eventId) => this.handleDeleted(eventId)}
                onCancel={() => this.setState({ editing: false })}
              />
            ) : this.state.activeEvent ? (
              <div>
                {this.isOfficer() ? (
                  <>
                    <Button
                      className="edit-btn"
                      variant="primary"
                      onClick={() => this.setState({ editing: true })}
                    >
                      Edit Item
                    </Button>
                    <hr />
                  </>
                ) : null}
                <Event
                  id={this.state.activeEvent}
                  role={this.props.currentUser.Role}
                />
              </div>
            ) : null}
          </section>
        </div>
      </div>
    );
  };
}
