import { ReactNode } from "react";
import Container from "react-bootstrap/Container";
import Spinner from "react-bootstrap/Spinner";
import styled from "styled-components";

const LoadingContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin: 2rem 0;
  min-height: 25vh;
`;
const LoadingSpinner = styled(Spinner)`
  width: 4rem;
  height: 4rem;
`;
const LoadingText = styled.span`
  font-size: 2rem;
`;

type Props = {
  children?: ReactNode
}
const Loader = ({children}:Props) => {
  return (
    <LoadingContainer>
      <LoadingSpinner animation="border" variant="primary" />
      <LoadingText>{children || "Loading..."}</LoadingText>
    </LoadingContainer>
  )
}

export default Loader