import { useEffect, useRef, useState } from 'react';
import { Form, Button, InputGroup, FormControl } from "react-bootstrap";
import { ImageBox } from './FileImage';


const defaultInputTypes = ['jpeg', 'jpg', 'png'];

interface FileInputProps {
    object: any,
    /** This should correspond to a property on `object` that contains a reference to the file */
    fileKey: string,
    /** The text to display on the button */
    entityType: string,
    /** A list of allowed file types */
    allowedTypes: Array<string>,
    /** The shape of the image to use. Can be square, circle, or an empty string */
    imageVariant: string,
    /** Callback used when an invalid file type is selected */
    invalidFileTypeCallback: (invalidFileTypeSelected: boolean) => void
}

export const FileInput = ({ object, fileKey, entityType, invalidFileTypeCallback, imageVariant = 'square', allowedTypes = defaultInputTypes }: FileInputProps) => {
    const [entity, setEntity] = useState<any>(object);
    const [entityFileKey, setEntityFilekey] = useState<string>(fileKey);
    const [fileSelected, setFileSelected] = useState<boolean>(false);
    const [invalidTypeSelected, setInvalidTypeSelected] = useState<boolean>(false);
    const [acceptedInputs] = useState<Array<string>>(allowedTypes);
    const fileDivRef = useRef<HTMLInputElement | null>(null);


    useEffect(() => {

        if (object[fileKey] === undefined) {
            throw new Error(`File key not found in object`);
        }
        let fileSelected = object[fileKey] !== "" && object[fileKey] !== null;

        setFileSelected(fileSelected);
        setEntity(object);
        setEntityFilekey(fileKey);
    }, [object, fileKey, entityType])

    const displayFilePrompt = () => {
        fileDivRef?.current?.click();
    }

    const removeImage = () => {
        if (fileSelected) {
            entity[entityFileKey] = "";
            setEntity(entity);
            setInvalidTypeSelected(false)
            setFileSelected(false);
        }
    }

    const validateMimeType = (mimeType: string): boolean => {
        let isInvalid = false;
        let validTypeFound = false;

        mimeType = mimeType.toLowerCase().trim();

        if (mimeType == "") {
            isInvalid = true;
            return isInvalid;
        }

        for (const acceptedType of allowedTypes) {
            if (mimeType.indexOf(acceptedType) > -1) {
                validTypeFound = true;
            }
        }

        if (!validTypeFound) {
            isInvalid = true;
        }

        return isInvalid;
    }

    const renderInput = () => {
        const fileSelected = entity[fileKey] !== "" && entity[fileKey] !== null;

        if (fileSelected) {
            const prettyImageName = (): string => {
                if (typeof entity[fileKey] === 'string' || entity[fileKey] instanceof String) {
                    const filenameStart = entity[fileKey].lastIndexOf("/") + 1; // TODO: harden this
                    return entity[fileKey].substr(filenameStart);
                } else {
                    return entity[fileKey].name;
                }
            };

            return (
                <InputGroup hasValidation>
                    <InputGroup.Prepend>
                        <Button name="logo" onClick={displayFilePrompt}>Update {entityType}</Button>
                    </InputGroup.Prepend>
                    <Form.Control
                        placeholder={prettyImageName()}
                        onMouseUp={displayFilePrompt}
                        aria-label="Update Logo"
                        style={{ backgroundColor: 'white' }}
                        type="text"
                        readOnly
                        isInvalid={invalidTypeSelected}
                        aria-describedby="logo"

                    />
                    <InputGroup.Append>
                        <Button onClick={() => { removeImage() }} disabled={!fileSelected} className="btn btn-danger" variant="danger">Remove {entityType}</Button>
                    </InputGroup.Append>
                    <Form.Control.Feedback type="invalid">
                        Invalid file format - please select one of the following formats: {acceptedInputs.join(' or ')}.
                    </Form.Control.Feedback>
                </InputGroup>
            );
        }

        return (
            <InputGroup>
                <InputGroup.Prepend>
                    <Button id="project-logo" onClick={displayFilePrompt}>Select {entityType}</Button>
                </InputGroup.Prepend>
                <FormControl
                    onMouseUp={displayFilePrompt}
                    readOnly
                    style={{ backgroundColor: 'white' }}
                    placeholder={`Select a ${acceptedInputs.join(' or ')}. Note: Images will be resized to 200x200px`}
                    aria-label="Logo"
                    aria-describedby="project-logo"
                />
            </InputGroup>
        );
    }

    const toBase64 = async (file: File) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                resolve(reader.result);
            }
            reader.onerror = reject;
        });
    }

    const handleChange = async (e: any) => {
        if (e.target.name == entityFileKey && e.target.files.length > 0) {
            let file = e.target.files[0];
            const { type: mimetype, name } = file;
            const invalidTypeSelected = validateMimeType(mimetype);

            await toBase64(file).then((res) => {
                const b64Data = res;
                entity[entityFileKey] = {
                    name,
                    data: b64Data,
                    mimetype,
                    file
                }
                setFileSelected(true);
                invalidFileTypeCallback(invalidTypeSelected);
                setEntity(entity);
            }).catch((err) => {
                console.log(err);
            });
        }
    }

    const fileInput = renderInput();
    const allowedInputTypes = acceptedInputs.map((t: any) => {
        return `.${t}`;
    }).join();
    const imageURL = entity[entityFileKey] != null ? entity[entityFileKey].file || entity[entityFileKey] : '';
    return (

        <Form.Group>
            <Form.Label className="form-label">{entityType}</Form.Label>
            {entity[entityFileKey] != null && entity[entityFileKey] != '' && !invalidTypeSelected ? (
                <Form.Group>
                    <ImageBox imageURL={imageURL} imageVariant={imageVariant} />
                </Form.Group>
            ) : ''}
            {fileInput}
            <input
                type="file"
                name={fileKey}
                ref={fileDivRef}
                style={{ display: "none" }}
                accept={allowedInputTypes}
                onChange={handleChange}
            />
        </Form.Group>


    )
}