import React, { Component } from "react";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Card from "../Card";
import PageHeader from "../PageHeader";
import { getCommittee } from "../../services/users";
import Permissions from "../../data/PermissionLevel";
import "./CommitteeBios.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";

class CommitteeBios extends Component {
  state = {
    bios: [],
    chapters: [],
    filters: {
      chapter: "Show All",
    },
  };

  componentDidMount = async () => {
    let bios = await getCommittee();
    let chapters = this.loadChapters(bios);
    this.setState({ bios, chapters });
  };

  loadChapters = (bios) => {
    let locations = bios.map((b) => b.location);
    locations = ["Show All", ...new Set(locations)];
    return locations;
  };

  canEdit = (bio) => {
    let permissionLevel = this.props.currentUser.Role.permission_level;
    let isOfficer = permissionLevel >= Permissions.Officer;
    let isUser = bio.id === this.props.currentUser.user_id;
    return isOfficer || isUser;
  };

  setFilter = (type, value) => {
    let filters = this.state.filters;
    filters[type] = filters[type] !== value ? value : "";
    this.setState({ filters });
  };

  renderBios = () => {
    return this.state.bios.map((b, i) => {
      let filters = this.state.filters;
      if (filters.chapter !== "Show All" && filters.chapter !== b.location) {
        return null;
      }

      // add the correct file path to profile photos that are stored locally
      if (b && b.photo != null) {
        if (b.photo.trim().length > 0 && b.photo.indexOf('/') < 0) {
          b.photo = `/images/headshots/${b.photo}`;
        }


        // display default.svg when users are missing a profile photo
        if (b.photo.trim().length == 0) {
          b.photo = undefined;
        }

        // all file paths should be absolute
        if (b.photo !== undefined && !b.photo.startsWith('/')) {
          b.photo = `/${b.photo}`;
        }
      }

      return (
        <li key={i} className="bio">
          <Card
            image_src={(b.photo || "/images/headshots/default.svg")}
            image_alt={"Headshot of " + (b.preferred_name || b.name)}
            title={b.preferred_name || b.name}
            subtitles={[
              b.role,
              // eslint-disable-next-line react/jsx-key
              <a
                className="bio-email"
                target="_blank"
                rel="noopener noreferrer"
                href={"mailto:" + b.email.toLowerCase()}
              >
                {b.email.toLowerCase()}
              </a>,
            ]}
            actions={
              this.canEdit(b)
                ? [
                  // eslint-disable-next-line react/jsx-key
                  <Link
                    to={`/profile/${b.id}/edit`}
                    className="btn btn-light"
                  >
                    <FontAwesomeIcon icon={faPen} />
                    Edit Profile
                  </Link>,
                ]
                : ""
            }
          >
            <div className={"bio-details"}>
              {b.bio
                ? b.bio.split("\n").map((x, i) => <p key={i}>{x}</p>)
                : null}
            </div>
          </Card>
        </li>
      );
    });
  };

  render() {
    return (
      <main>
        <PageHeader title="Committee Members" />

        <div className="committee-filters">
          {this.state.chapters.map((c) => {
            return (
              <Button
                variant={this.state.filters.chapter === c ? "primary" : "link"}
                key={c}
                onClick={() => this.setFilter("chapter", c)}
                onMouseUp={(e) => e.target.blur()}
              >
                {c}
              </Button>
            );
          })}
        </div>
        <ul className="bio-list">{this.renderBios()}</ul>
      </main>
    );
  }
}

export default CommitteeBios;
