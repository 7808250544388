// Refactored From https://github.com/pushtell/react-bootstrap-date-picker/blob/master/src/index.jsx to work with React v16

type Props = {
  displayDate: string | number | Date
  minDate?: string | number | Date
  maxDate?: string | number | Date
  onChange: (d:Date)=>void
  monthLabels: string[]
  previousButtonElement: string | object
  nextButtonElement: string | object
}

const DatePickerHeader = ({displayDate:_dd, minDate, maxDate, onChange, monthLabels, previousButtonElement, nextButtonElement}:Props) => {
  const displayDate = new Date(_dd);
  const displayingMinMonth = () => {
    if (!minDate) return false;
    minDate = new Date(minDate);
    return minDate.getFullYear() == displayDate.getFullYear() && minDate.getMonth() == displayDate.getMonth();
  }

  const displayingMaxMonth = () => {
    if (!maxDate) return false;
    maxDate = new Date(maxDate);
    return maxDate.getFullYear() == displayDate.getFullYear() && maxDate.getMonth() == displayDate.getMonth();
  }

  const handleClickPrevious = () => {
    const newDisplayDate = new Date(displayDate);
    newDisplayDate.setDate(1);
    newDisplayDate.setMonth(newDisplayDate.getMonth() - 1);
    onChange(newDisplayDate);
  }

  const handleClickNext = () => {
    const newDisplayDate = new Date(displayDate);
    newDisplayDate.setDate(1);
    newDisplayDate.setMonth(newDisplayDate.getMonth() + 1);
    onChange(newDisplayDate);
  }

  return (
    <div className="text-center">
      <div className="text-muted pull-left datepicker-previous-wrapper" onClick={handleClickPrevious} style={{cursor: 'pointer'}}>
        {displayingMinMonth() ? null : previousButtonElement}
      </div>
      <span>{monthLabels[displayDate.getMonth()]} {displayDate.getFullYear()}</span>
      <div className="text-muted pull-right datepicker-next-wrapper" onClick={handleClickNext} style={{cursor: 'pointer'}}>
        {displayingMaxMonth() ? null : nextButtonElement}
      </div>
    </div>
  )
}
export default DatePickerHeader