import React, { Component } from "react";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import "./EducationForm.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";

class EducationForm extends Component {
  state = {
    application_id: this.props.application.id,
    description: "",
    attainment_year: "",
    hasChanges: false,
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    await this.props.createEducation(this.state);
    this.setState({ hasChanges: false, description: "", attainment_year: "" });
  };

  handleInputChange = (event) => {
    const { name, value } = event.target;
    this.setState(
      {
        [name]: value,
      },
      () => {
        const hasChanges = this.state.description || this.state.attainment_year;
        this.setState({ hasChanges });
      }
    );
  };

  render() {
    return (
      <div>
        <Form onSubmit={this.handleSubmit}>
          <Form.Row className="align-bottom">
            <Form.Group as={Col}>
              <Form.Label>Institution/Degree Earned</Form.Label>
              <Form.Control
                name="description"
                type="text"
                required
                placeholder="Institution/Degree"
                onChange={this.handleInputChange}
                value={this.state.description}
              />
            </Form.Group>

            <Form.Group as={Col}>
              <Form.Label>Year Attained</Form.Label>
              <Form.Control
                name="attainment_year"
                type="text"
                placeholder="2020"
                required
                onChange={this.handleInputChange}
                value={this.state.attainment_year}
                minLength="4"
                maxLength="4"
                pattern="\d{4}"
                title="Year must be 4 digits"
              />
            </Form.Group>

            <Form.Group as={Col} md="1" sm="12" lg="1">
              <Button
                className="education-btn align-text-bottom"
                variant="primary"
                type="submit"
                block={true}
                disabled={this.state.attainment_year.trim().length === 0 || this.state.description.trim().length === 0}
              >
                Add
              </Button>
            </Form.Group>
          </Form.Row>
        </Form>
      </div>
    );
  }
}

export default EducationForm;
