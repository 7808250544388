import axios from "axios";
import React, { Component } from "react";
import Alert from "react-bootstrap/Alert";
import { Form, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FileInput } from "../Files";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import PermissionLevel from "../../data/PermissionLevel";
import CommitteeRoles from "../../data/CommitteeRoles";
import Unauthorized from "../Unauthorized";
import PageHeader from "../PageHeader";
import Loader from "../Loader";
export class ProfileForm extends Component {
  state = {
    loading: true,
    error: null,
    success: false,
    profile: {},
    invalidImageTypeSelected: false
  };

  componentDidMount() {
    this.loadProfile();
  }

  loadProfile() {
    return axios
      .get(`/api/users/${this.props.params.user_id}`)
      .then((response) => {
        this.setState({
          profile: response.data,
          loading: false,
          error: null,
        });
      })
      .catch((error) => {
        this.setState({
          loading: false,
          error: "Unable to load profile!",
        });
      });
  }

  // Handlers
  handleChange = (e) => {
    let { profile, error } = this.state;

    if (e.target.name != 'photo') {
      profile[e.target.name] = e.target.value;
    }

    // reset the error state when a user updates the form
    if (error === "Invalid fields submitted!") {
      error = null;
    }

    this.setState({ profile, error });
  };

  invalidFileSelected = (invalidFileSelected) => {
    this.setState({ invalidImageTypeSelected: invalidFileSelected });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ success: false, error: null, loading: true });

    // Validate Form
    let form = e.currentTarget;
    let isValid = form.checkValidity() && !this.state.invalidImageTypeSelected;
    if (!isValid) {
      this.setState({ error: "Invalid fields submitted!", loading: false });
      return false;
    }

    // If valid, send to the backend
    axios
      .put(`/api/users/${this.props.params.user_id}`, this.state.profile)
      .then((res) => {
        this.setState({ error: null, success: true, loading: false });
      })
      .catch((res) => {
        this.setState({
          error: "Unexpected error! Saving profile failed.",
          success: false,
          loading: false
        });
      });
  };

  handleCancel(e) {
    if (window.history.length === 1) {
      window.location.href = "/about/committee";
    }

    window.history.back();
  }

  isAllowed() {
    let user = this.props.currentUser;
    let isOfficer = user.Role.permission_level >= PermissionLevel.Officer;
    let isUser = user.user_id === this.state.profile.id;
    return isOfficer || isUser;
  }

  renderRoles(profile) {
    let userPermissions = this.state.profile.permission_level;
    let isCommitteeMember = userPermissions >= PermissionLevel.Committee;
    if (isCommitteeMember) {
      return (
        <Form.Control
          as="select"
          name="role"
          value={profile.role}
          onChange={this.handleChange}
        >
          <option value="">Select One</option>
          {CommitteeRoles.map((r) => {
            return <option key={r}>{r}</option>;
          })}
        </Form.Control>
      );
    }

    return <Form.Control readOnly placeholder={profile.permission_role} />;
  }

  render() {
    if (!this.isAllowed()) {
      return <Unauthorized />;
    }

    if (this.state.loading) {
      return <Loader />;
    }

    let alert = null;
    if (this.state.error) {
      alert = <Alert variant="danger">{this.state.error}</Alert>;
    }

    if (this.state.success) {
      alert = (
        <Alert variant="success">
          Profile Updated!{" "}
          <Alert.Link href="#" onClick={this.handleCancel}>
            Go Back <FontAwesomeIcon icon={faChevronRight} />
          </Alert.Link>
        </Alert>
      );
    }

    let profile = this.state.profile;
    return (
      <main>
        <PageHeader title="Edit Profile" />
        <Form
          key={profile.id}
          id="profile-form"
          onSubmit={this.handleSubmit}
          className="form-container"
        >
          <h2>{profile.preferred_name || profile.name}</h2>
          {alert}
          <Form.Group>
            <Form.Label>Preferred Name</Form.Label>
            <Form.Control
              name="preferred_name"
              value={profile.preferred_name}
              onChange={this.handleChange}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Role</Form.Label>
            {this.renderRoles(profile)}
          </Form.Group>
          <FileInput object={profile} fileKey="photo" entityType="Photo" allowedTypes={['jpeg', 'jpg', 'png']} imageVariant="" invalidFileTypeCallback={this.invalidFileSelected} />
          <Form.Group>
            <Form.Label>Bio</Form.Label>
            <Form.Control
              as="textarea"
              name="bio"
              value={profile.bio}
              onChange={this.handleChange}
              rows={5}
            />
          </Form.Group>
          <Button type="submit" form="profile-form">
            Save Item
          </Button>{" "}
          <Button variant="outline-dark" onClick={this.handleCancel}>
            Cancel
          </Button>
        </Form>
      </main>
    );
  }
}
