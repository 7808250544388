import { ReactNode } from "react";
import styled from "styled-components";

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 3rem;
  color: var(--color-bg);
  background: linear-gradient(
    to right,
    var(--color-primary-dk),
    var(--color-primary) 50%
  );
  z-index: 5;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.25);
`;

const H1 = styled.h1`
  padding-top: 0.15rem;
  font-size: 1.5rem;
  line-height: 3rem;
  text-indent: 1rem;
`;

type Props = {
  title: string
  children?: ReactNode
}

const PageHeader = ({title, children}:Props) => {
  return (
    <Header>
      <H1>{title}</H1>
      {children}
    </Header>
  );
}

export default PageHeader