import React, { useEffect, useMemo, useState } from "react";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import Form from "react-bootstrap/Form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faChevronLeft,
  faExclamationTriangle,
  faWarning,
} from "@fortawesome/free-solid-svg-icons";
import { Popup } from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import ApplicationHeader from "../ApplicationHeader";
import SaveAndStepButton from "../SaveAndStepButton.jsx";
import { Modal } from "react-bootstrap";
import SubmittedApp from "../SubmittedApp/index.jsx";

const ReviewApplicationStep = ({application, jumpToStep, saveApplication, currentUser}) => {
  const thisPageStep = 4;
    const [showPopup, setShowPopup] = useState(false);

  const onContinue = async () => {
      setShowPopup(true);
  }

  const onBack = async () => {
    jumpToStep(thisPageStep - 1);
  }


    const handleFormSubmit = async (event) => {
    if (event) {
      event.preventDefault();
    }
      await saveApplication({...application});
  }

  const handleApplicationSubmit = (event) => {
    event.preventDefault();
    const submitted = {
      ...application,
      submitted_at: new Date(),
      status_id: 2, // submitted status
      ApplicationStatus: {
        id: 2,
        status: "Submitted"
      }
    };
    saveApplication(submitted);
    jumpToStep(0);
  };

  const applicant_type = application.ApplicantType?.applicant_type || "";

  return (
    <div>
      <Popup
        open={showPopup}
        position="right center"
        closeOnDocumentClick
        onClose={() => setShowPopup(false)}
      >
        <div className="popup">
          <h2 className="text-danger">
            <FontAwesomeIcon icon={faExclamationTriangle} /> This is your
            final submission!
          </h2>
          <p>
            You will not be able to edit your application after submission. If
            you have questions or would like someone to review your
            application <b>before</b> submitting, please reach out to the
            CLEAR committee in the{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://app.slack.com/client/T024VU91V/CGKTPBYKG"
            >
              #clear
            </a>{" "}
            slack channel.
          </p>

          <div className="popup-btns">
            <Button
              onClick={handleApplicationSubmit}
              className="text-capitalize"
            >
              Submit {applicant_type} Application
            </Button>
            <Button
              variant="light"
              onClick={()=>setShowPopup(false)}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Popup>

        <ApplicationHeader
          heading="Review Your Submission"
          message="Please review your application. The submission button is located at the bottom of the page."
        />
     <div style={{ border: '1px solid #ccc', padding: '1em', marginTop: '3em', borderRadius: '5px' }}>
        <SubmittedApp
          params={{ application_id: application.id }}
          currentUser={currentUser}
          applicationStatus={application.ApplicationStatus}
          hideHeader
        />
      </div>

      <Form onSubmit={handleFormSubmit}>
        <Form.Group>
          <div className="row nav-btns justify-content-between">
            <SaveAndStepButton
              buttonText="Back"
              icon={faChevronLeft}
              iconPosition="left"
              className="col-2"
              variant="primary"
              onSave={handleFormSubmit}
              onSuccessFunction={onBack}
            />
            <SaveAndStepButton lastStep
              buttonText={`Submit ${applicant_type} Application`}
              icon={faChevronRight}
              iconPosition="right"
              className="text-capitalize"
              variant="primary"
              onSave={handleFormSubmit}
              onSuccessFunction={onContinue}
            />
          </div>
        </Form.Group>
      </Form>
    </div>
  )
}

export default ReviewApplicationStep;
