import React, { Component } from "react";
import axios from "axios";
import "react-stepzilla/src/css/main.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faExclamationTriangle,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Table from "react-bootstrap/Table";
import Alert from "react-bootstrap/Alert";
import PageHeader from "../../PageHeader";
import Card from "../../Card";
import Loader from "../../Loader";
import Unauthorized from "../../Unauthorized";
import "./SubmittedApp.css";
import { COMPETENCIES_MENTEE_INFO, COMPETENCIES_MENTOR_INFO, COMPETENCIES_COMMITTEE_MEMBER_INFO, COMPETENCIES_ADDITIONAL_SKILLS_PROMPT, ESSAY_PROMPT, APPLICATION_STATUS_ID_PENDING} from '../constants'

class SubmittedApp extends Component {
  state = {
    loading: true,
    submission: {},
    fields: {
      EducationEntries: [
        {
          key: "description",
          field: "Institution",
        },
        {
          key: "attainment_year",
          field: "Year",
        },
      ],
      CareerEntries: [
        {
          key: "company",
          field: "Company",
        },
        {
          key: "position",
          field: "Position",
        },
        {
          key: "achievements",
          field: "Achievements",
        },
        {
          key: "start_date",
          field: "Start Date",
        },
        {
          key: "end_date",
          field: "End Date",
        },
      ],
      CompetencyRatings: [
        {
          key: "Competency.name",
          field: "Skill",
        },
        {
          key: "rating",
          field: "Rating",
          formatter: (value) =>
            [
              "Very Weak",
              "Weak",
              "Slightly Weak",
              "Slightly Strong",
              "Strong",
              "Very Strong",
            ][value - 1],
        },
        // {
        //   key: "examples",
        //   field: "Examples",
        // },
      ],
    },
    validPermissionLevel: 80,
    hideHeader: false,
  };

  getApplication = (id) => {
    return axios
      .get("/api/applications/" + id)
      .then((response) => {
        this.setState(
          {
            submission: response.data,
          },
          () => {
            this.setState({ loading: false });
            return Promise.resolve();
          }
        );
      });
  };

  componentDidMount = () => {
    this.getApplication(this.props.params.application_id);
  };

  componentDidUpdate = () => {
    let current_id = this.state.submission.id;
    let new_id = this.props.params.application_id;

    if (new_id != current_id) {
      this.getApplication(this.props.params.application_id);
    }
  };

  /** Application Checks */
isTenuredEnough = () => {
    let hire_date = this.state.submission.hire_date;

    if (hire_date === null) {
        // If hire date is null, use years_with_company
        let tenure = this.state.submission.years_with_company;
        return tenure >= 2.0;
    } else {
        // If hire date is not null, calculate the tenure based on hire_date
        hire_date = new Date(hire_date);
        let current_date = new Date();
        let duration = current_date - hire_date;
        let threshold = 1000 * 60 * 60 * 24 * 365 * 2; // Two Years

        return duration > threshold;
    }
};
  isManagerApproved = () => {
    return !!this.state.submission.ManagerApproval;
  };
  hasMultipleApplications = () => {
    return this.state.submission.Applications?.length > 0;
  };

  getCompInfo = () => {
    let info = "";
    switch (this.state.submission.applicant_type_id) {
      case 1:
        info = "PROMPT:  " + COMPETENCIES_MENTEE_INFO;
        break;
      case 2:
        info = "PROMPT:  " + COMPETENCIES_MENTOR_INFO;
        break;
      case 3:
        info = "PROMPT:  " + COMPETENCIES_COMMITTEE_MEMBER_INFO;
        break;
      default:
        info = `${this.props.applicantTypeId}`;
    }
    return info;
  };

  getCompAdditionalSkillsInfo = () => {
    return "PROMPT:  " + COMPETENCIES_ADDITIONAL_SKILLS_PROMPT;
  };


  getEssayInfo = () => {
    let info = [];

    {
      info.push(
        <div key="essayInfo" className="info">
          <p>
            <i>{"PROMPT:  " + ESSAY_PROMPT}</i>
          </p>
        </div>
      );
    }
    return info;
  };

  buildTable = (dataType) => {
    let fields = this.state.fields[dataType];

    let table = [];
    let header = [];
    let tbody = [];

      if (!this.state.submission || !this.state.submission[dataType] || this.state.submission[dataType].length === 0) {
          tbody.push(
            <tr key={dataType + "-no-data"}>
              <td colSpan={fields.length || 1}>No Entry</td>
            </tr>
          );
        } else {

        if (this.state.submission) {
          let headerTitles = [];
          for (let i = 0; i < fields.length; i++) {
            headerTitles.push(<th key={fields[i].field}>{fields[i].field}</th>);
          }
          header.push(
              <thead key={"header-" + dataType}>
              <tr>{headerTitles}</tr>
              </thead>
          );
          table.push(header);
        }

        for (let i = 0; i < this.state.submission[dataType].length; i++) {
          let row = [];

          for (let j = 0; j < fields.length; j++) {
            let dots = fields[j].key.split(".");
            let obj = this.state.submission[dataType][i];

            for (var dot in dots) {
              // this is so we can dynamically get the nested objects
              // i.e Chapter.location
              obj = obj[dots[dot]];
            }

            let output = obj;
            if (typeof fields[j].formatter === "function") {
              output = fields[j].formatter(obj);
            }

            row.push(
                <td key={this.state.submission.id + "-" + dataType + "-" + j}>
                  {output}
                </td>
            );
          }
          tbody.push(
              <tr key={this.state.submission.id + "-" + dataType + "-row-" + i}>
                {row}
              </tr>
          );
        }
      }

    table.push(<tbody key={dataType + "-body"}>{tbody}</tbody>);
    return table;
  };

  renderWarning = () => {
    if (this.state.submission.submitted_at === null) {
      return (
        <Alert variant="danger">
          <FontAwesomeIcon icon={faExclamationTriangle} />
          This application has not been completed yet!
        </Alert>
      );
    }
    if (this.state.submission.ApplicationStatus.status === "Withdrawn") {
      return (
        <Alert variant="danger">
          <FontAwesomeIcon icon={faExclamationTriangle} />
          This application has been withdrawn!
        </Alert>
      );
    }
    return;
  };

  renderManagerApproval = () => {
    const { ManagerApproval } = this.state.submission;
    const elements = [];
    if (this.state.submission.ApplicantType.applicant_type === "mentee") {
      if (!ManagerApproval) {
        elements.push(<p key="Pending">Pending</p>);
      } else {
        const { approved, notes } = ManagerApproval;
        let approvalText = approved ? "Manager Approval Granted" : "Manager Approval Denied";
        elements.push(<p key="approval-status">{approvalText}</p>);

        if (notes) {
          elements.push(<Row key="notes" className="notes"><p>Notes: {notes}</p></Row>);
        }
      }
    }
    return (
      <Container>
        {elements.map((element) => (
          <Row key={element.key}>{element}</Row>
        ))}
      </Container>
    );
  };


  renderPastApplications = () => {
    if (this.hasMultipleApplications()) {
      let pastApps = this.state.submission.Applications;

      return pastApps.map((a) => {
        let statusIsSubmitted = a.status_id === 2;

        if (a.id !== this.state.submission.id && statusIsSubmitted) {
          return (
            <Alert key={a.id} variant="info">
              <FontAwesomeIcon icon={faInfoCircle} />
              Previous Application Found!{" "}
              <Alert.Link
                href={"/application/" + a.id}
                target="_blank"
                rel="noopener noreferrer"
              >
                View {a.year} Application
              </Alert.Link>
            </Alert>
          );
        }
      });
    }
  };

  render() {
    if (
      this.props.currentUser.Role.permission_level <
        this.state.validPermissionLevel &&
      this.props.currentUser.User.id !== this.state.submission.user_id
    ) {
      return <Unauthorized />;
    }
    const isPending = this.state.submission.status_id === APPLICATION_STATUS_ID_PENDING;
    const isCommitteeLevelOrHigher = this.props.currentUser.Role.permission_level >= this.state.validPermissionLevel
    const showWarnings = !isPending && isCommitteeLevelOrHigher;

    if (this.state.loading) {
      return <Loader />;
    }

    return (
      <>
        {this.props.hideHeader ? null : (
          <PageHeader title="CLEAR Application" />
        )}

        <Container
          className={"application" + (this.props.hideHeader ? " embedded" : "")}
        >
          <Row className="align-items-start">
            <Col md={12} lg={4} className="app-details">
              <Card
                image_src="/images/headshots/default.svg"
                image_variant="small"
                title={this.state.submission.User.name}
                subtitles={[
                  // eslint-disable-next-line react/jsx-key
                  <a href={`mailto:${this.state.submission.User.email}`}>
                    {this.state.submission.User.email}
                  </a>,
                  this.state.submission.title,
                ]}
              >
                <div>
                  <dl>
                    <dt>Applicant Type</dt>
                    <dd>
                      {this.state.submission.ApplicantType.applicant_type}
                    </dd>

                    <dt>Chapter</dt>
                    <dd>{this.state.submission.Chapter.location}</dd>

                    <dt>Department</dt>
                    <dd>{this.state.submission.department}</dd>

                    <dt>Manager</dt>
                    <dd>{this.state.submission.manager}</dd>

                    <dt>
                      {showWarnings &&
                        (this.isTenuredEnough() ? (
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            color="var(--color-success)"
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon={faExclamationTriangle}
                            color="var(--color-danger)"
                            title="Hire date is less than two years ago!"
                          />
                        ))}
                      {isCommitteeLevelOrHigher
                        ? "Tenure"
                        : this.state.submission.hire_date
                        ? "Hire Date"
                        : "Tenure"}
                    </dt>
                    <dd>
                      {isCommitteeLevelOrHigher
                        ? `Years at Comcast: ${this.state.submission.years_with_company}`
                        : this.state.submission.hire_date
                        ? this.state.submission.hire_date
                        : `Years at Comcast: ${this.state.submission.years_with_company}`}
                    </dd>
                    <dt>ERG Details</dt>
                    <dd>{this.state.submission.erg}</dd>

                    <dt>Office Location</dt>
                    <dd>{this.state.submission.location}</dd>

                    <dt>Location Comments</dt>
                    <dd>{this.state.submission.location_comments}</dd>
                  </dl>
                </div>
              </Card>
            </Col>
            <Col md={12} lg={8} className="app-content">
              {showWarnings && (
                <>
                  {this.renderWarning()}
                  {this.renderPastApplications()}
                  {this.state.submission.ApplicantType.applicant_type ===
                    "mentee" && (
                    <>
                      <h2 className="h4">
                        {this.isManagerApproved() ? (
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            color="var(--color-success)"
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon={faExclamationTriangle}
                            color="var(--color-danger)"
                            title="Manager has not yet approved this application!"
                          />
                        )}
                        Manager Approval
                      </h2>
                      {this.renderManagerApproval()} <hr />
                    </>
                  )}
                </>
              )}
              <h2 className="h4">Education</h2>
              <Table key="edu-table">
                {this.buildTable("EducationEntries")}
              </Table>

              <h2 className="h4">Career</h2>
              <Table key="career-table">
                {this.buildTable("CareerEntries")}
              </Table>

              <h2 className="h4">Competencies</h2>
              <b>
                <i>{this.getCompInfo()}</i>
              </b>
              <Table key="comp-table">
                {this.buildTable("CompetencyRatings")}
              </Table>
              <hr />
              <h2 className="h4">Additional Skills</h2>
              <b>
                <i>{this.getCompAdditionalSkillsInfo()}</i>
              </b>
              <p className="additional_skills">
                {this.state.submission.additional_skills?.trim()
                  ? this.state.submission.additional_skills
                  : " - No Entry"}
              </p>
              <h2 className="h4">Essay</h2>
              <b>{this.getEssayInfo()}</b>
              <p className="essay">{this.state.submission.essay}</p>
              <hr />
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default SubmittedApp;
