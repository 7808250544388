import React, { Component } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const Heading = styled.h1`
  font-size: 1.46rem;
  line-height: 1.5rem;
  text-align: center;
  font-weight: bold;
`;

const Message = styled.p`
  font-size: 1.05rem;
  text-align: center;
  margin: 1em auto;
  margin-bottom: 2.5em;
  margin-top: .5em;
  max-width: 36em;
`;


// parse html string and render html tags
function renderHtmlContent(htmlString) {
  const tagRenderers = {
    'b': (content, key) => <b key={key}>{content}</b>, //bold
    'i': (content, key) => <i key={key}>{content}</i>, //italic
    'strong': (content, key) => <strong key={key}>{content}</strong>,
    'u': (content, key) => <u key={key}>{content}</u>, //underline

  };
  const tagRegex = /<(\w+)>(.*?)<\/\1>/g;
  let elements = [];
  let lastIndex = 0;
  htmlString.replace(tagRegex, (match, tagName, content, index) => {
    if (index > lastIndex) {
      elements.push(htmlString.slice(lastIndex, index));
    }
    if (tagRenderers[tagName]) {
      elements.push(tagRenderers[tagName](content, elements.length));
    }
    lastIndex = index + match.length;
  });
  if (lastIndex < htmlString.length) {
    elements.push(htmlString.slice(lastIndex));
  }

  return elements;
}

class ApplicationHeader extends Component {
  static defaultProps = {
    showHorizontalRule: true,
  };

  render() {
  const { heading, message} = this.props;
    return (
      <header>
        {heading && <Heading>{heading}</Heading>}
        {message && <Message>{renderHtmlContent(message)}</Message>}
      </header>
    );
  }
}

// Define prop types
ApplicationHeader.propTypes = {
  heading: PropTypes.string,
  message: PropTypes.string,
};


export default ApplicationHeader;
