import React, { Component } from "react";
import Collapse from "react-bootstrap/Collapse";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";

const CardContainer = styled.div`
  background: #fff;
  box-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.1);
  position: relative;
  border-radius: 0.25em;
  overflow: hidden;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;
const CardHeader = styled.header`
  display: flex;
  align-items: center;
`;
const CardImage = styled.div`
  background: linear-gradient(
    140deg,
    var(--color-primary) 25%,
    var(--color-secondary)
  );
  border-radius: 50%;
  overflow: hidden;
  padding: 2px;
  margin-right: 1rem;
  flex-shrink: 0;

  & img {
    display: block;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background: #fff;
    padding: 2px;
    object-fit: cover;
  }

  &.square {
    border-radius: 0.5rem;
  }
  &.square img {
    border-radius: calc(0.5rem - 2px);
    object-fit: contain;
  }
  &.small img {
    width: 60px;
    height: 60px;
  }
`;
const CardTitle = styled.h1`
  font-size: 1.25rem;
  margin: 0;
`;
const CardContent = styled.div`
  position: relative;
  z-index: 2;
  overflow: auto;
  flex: 0 1 auto;

  & > div {
    padding: 0.5rem 0 0;
  }
`;
const CardActions = styled.span`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  padding-top: 0.5rem;
  width: 100%;

  & > div {
    flex: 1;
  }

  & .btn {
    display: flex;
    gap: 0.5rem;
    width: 100%;
    white-space: nowrap;
    justify-content: center;
  }
`;

export default class Card extends Component {
  state = {
    open: this.props.open || false,
    toggledOpen: this.props.open,
    collapsible: this.props.collapsible || false,
  };

  componentDidUpdate = () => {
    // Allows parent to toggle all, but still allows independant toggling
    if (this.state.toggledOpen !== this.props.open) {
      this.setState({
        toggledOpen: this.props.open,
        open: this.props.open,
      });
    }
  };

  toggle = () => {
    this.setState({ open: !this.state.open });
  };

  collapseButton = () => {
    if (this.props.collapsible) {
      return (
        <a
          href={"#collapse"}
          className="flex-right"
          onClick={() => this.toggle()}
        >
          {this.state.open ? "Hide" : "Show"} Details{" "}
          <FontAwesomeIcon
            icon={this.state.open ? faChevronUp : faChevronDown}
          />
        </a>
      );
    }
  };

  render() {
    return (
      <CardContainer>
        <CardHeader>
          {this.props.image_src ? (
            <CardImage className={this.props.image_variant}>
              <img src={this.props.image_src} alt={this.props.image_alt} />
            </CardImage>
          ) : null}
          <div>
            <CardTitle>{this.props.title}</CardTitle>
            {this.props.subtitles?.length
              ? this.props.subtitles.map((s, i) => <div key={i}>{s}</div>)
              : null}
          </div>
        </CardHeader>

        <CardContent>
          <Collapse in={this.state.open || !this.state.collapsible}>
            <div>{this.props.children}</div>
          </Collapse>
          {this.collapseButton()}
        </CardContent>
        <CardActions>
          {this.props.actions?.length
            ? this.props.actions.map((a, i) =>
                a ? <div key={i}>{a}</div> : null
              )
            : null}
        </CardActions>
      </CardContainer>
    );
  }
}
