import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import Card from "../Card";
import "./Projects.css";
import clearLogoSrc from "../../assets/images/clear-logo.svg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComment, faPen, faVideo } from "@fortawesome/free-solid-svg-icons";

export default class ProjectCard extends Component {
  state = {
    project: this.props.data,
    editable: this.props.editable || false,
    collapsible: this.props.collapsible || false,
    open: this.props.open,
    toggledOpen: this.props.open,
    live: this.props.live,
  };

  componentDidUpdate = () => {
    // Allows parent to toggle all, but still allows independant toggling
    if (this.state.toggledOpen !== this.props.open) {
      this.setState({
        toggledOpen: this.props.open,
        open: this.props.open,
      });
    }
  };

  logoSrc = (p) => {

    if (p.logo && p.logo === 'GENERIC') {
      return clearLogoSrc;
    }

    if (p.logo && p.logo.indexOf('api/files') < 0) {
      const src = `/api/files/images/project-fair/${p.year}/logos/${p.logo}`;
      return src
    }

    // all paths should be absolute 
    if (p.logo && !p.logo.startsWith('/')) {
      p.logo = `/${p.logo}`;
    }

    return p.logo || clearLogoSrc;
  };

  videoBtn = (p) => {
    let btnText = "";
    let url = "";

    if (this.state.live && p.video_url) {
      btnText = "Join Live";
      url = p.video_url;
    } else if (!this.state.live && p.video_stream_url) {
      btnText = "Watch";
      url = p.video_stream_url;
    } else {
      return;
    }

    return (
      <Button variant="light" block target="_blank" rel="noopener noreferrer" href={url}>
        <FontAwesomeIcon icon={faVideo} />
        {btnText}
      </Button>
    );
  };

  sparkBtn = (p) => {
    if (!p.spark_url) return;

    return (
      <Button variant="light" block target="_blank" rel="noopener noreferrer" href={p.spark_url}>
        <FontAwesomeIcon icon={faComment} />
        Comment
      </Button>
    );
  }

  editBtn = (p) => {
    if (!this.state.editable) return;

    return (
      <Link to={`/projects/${p.id}/edit`} className="btn btn-light btn-block">
        <FontAwesomeIcon icon={faPen} />
        Edit
      </Link>
    );
  };

  mentees = (team) => {
    if (!team) return;

    return team
      .filter((t) => t.role.toLowerCase() === "mentee")
      .map((t) => t.name)
      .join(" & ");
  };

  tags = (tags) => {
    if (!tags) return;

    return tags.map((t) => (
      <span key={t.tag.id} className="badge badge-neutral">
        {t.tag.name}
      </span>
    ));
  };

  render() {
    let p = this.state.project;

    return (
      <Card
        image_src={this.logoSrc(p)}
        image_alt={p.title + " Logo"}
        image_variant="square"
        collapsible={this.state.collapsible}
        open={this.state.open}
        title={p.title}
        subtitles={[
          this.mentees(p.team?.team),
          // eslint-disable-next-line react/jsx-key
          <span className="text-muted">
            {p.Chapter.location || ""} {p.year}
          </span>,
        ]}
        actions={[this.videoBtn(p), this.sparkBtn(p), this.editBtn(p)]}
      >
        <div className="project-details">
          <section className={p.tags.length ? "" : "hidden"}>
            <h5>Topics</h5>
            <p className="project-tags">{this.tags(p.tags)}</p>
          </section>

          <section>
            <h5>Description</h5>
            <p>{p.description}</p>
          </section>

          <section>
            <h5>Team Members</h5>
            <ul className="project-member-list">
              {p.team &&
                p.team.team.map((t, i) => (
                  <li key={i}>
                    <b>{t.name}</b> / {t.role}
                    {t.title ? (
                      <div className="text-muted">{t.title}</div>
                    ) : null}
                  </li>
                ))}
              {p.exec_sponsor &&
                p.exec_sponsor.map((e, i) => (
                  <li key={i}>
                    <b>{e.name}</b> / Executive Sponsor
                  </li>
                ))}
            </ul>
          </section>

          {p.thanks && (
            <section className="project-thanks">
              <h5>Special Thanks</h5>
              <p>{p.thanks}</p>
            </section>
          )}
        </div>
      </Card>
    );
  }
}
