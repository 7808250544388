/**
 * Return a className given a list of classNames as strings or objects with flags
 * 
 * @example
 * <button className={classNames('btn', {'btn-disabled':!enabled})}>...</button>
 */
const classNames = (...args:(string|undefined|{[k:string]:number|string|boolean|undefined|null})[]):string => {
  const names = args.reduce((a,x)=>{
    if (!x) {/* skip */}
    else if (typeof(x) == 'string') a.add(x)
    else Object.entries(x).forEach(([k,v])=>{if (v) a.add(k)})
    return a
  }, new Set<string>())
  return [...names].join(' ')
}
export default classNames