import React from 'react';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from "prop-types";

class SaveAndStepButton extends React.Component {


    handleContinue = (event) => {
        event.preventDefault();
        if (this.props.componentsToCheck && this.props.componentsToCheck.length > 0) {
            let isValid = true;
            let invalidComponentForm = null;
            for (let componentName of this.props.componentsToCheck) {
                const component = document.getElementsByName(componentName)[0] || document.getElementById(componentName);
                if (component && !component.checkValidity()) {
                    invalidComponentForm = component.form
                    isValid = false;
                    break;
                }
            }
            if (!isValid && invalidComponentForm) {
                invalidComponentForm.reportValidity();
            } else if (isValid) {
                this.props.onSave().then(this.props.onSuccessFunction);
            }
        }
        else {
                this.props.onSave().then(() => {
                    this.props.onSuccessFunction();
                });
        }
        ;
    }
  render() {
    const isIconFirst = this.props.iconPosition === 'left';
    return (

      <Button
        className={this.props.className}
        disabled={this.props.disabled}
        variant={this.props.variant}
        onClick={this.handleContinue}>
        <div style={{ display: 'inline-flex', alignItems: 'center' }}>
          {isIconFirst && this.props.icon && <FontAwesomeIcon icon={this.props.icon} style={{ float: 'left' }} />}
          {this.props.buttonText}
          {!isIconFirst && !this.props.lastStep && this.props.icon && <FontAwesomeIcon icon={this.props.icon} style={{ float: 'right' }} />}
        </div>
      </Button>

    );
  }
}
SaveAndStepButton.propTypes = {
  fieldsToCheck: PropTypes.array,
  onSave: PropTypes.func.isRequired, //function to perform saving attributes to the database
  onSuccessFunction: PropTypes.func.isRequired, //function to perform after saving attributes to the database
  iconPosition: PropTypes.oneOf(['left', 'right']),
  className: PropTypes.string,
  disabled: PropTypes.bool,
  variant: PropTypes.string,
  icon: PropTypes.object, // FontAwesome icons are objects
  buttonText: PropTypes.string
};
export default SaveAndStepButton;

