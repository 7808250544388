import React, { Component } from "react";
import { Link } from "react-router-dom";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
import PermissionLevel from "../../data/PermissionLevel";
import Menu from "../Menu";
import "./Header.css";
import PropTypes from "prop-types";
import logoSrc from "../../assets/images/clear-logo.svg";
import { Button } from "react-bootstrap";

const collapseWidth = 800;
const siteLinks = [
  {
    name: "About Us",
    path: "/about",
  },
  {
    name: "Projects",
    path: "/projects",
    loginRequired: true,
  },
  {
    name: "Apply",
    path: "/apply",
    loginRequired: true,
  },
  {
    name: "Slack",
    icon: (
      <svg className="icon">
        <use xlinkHref="#icon-slack"></use>
      </svg>
    ),
    path: "https://app.slack.com/client/T024VU91V/CGKTPBYKG",
    external: true,
  },
  {
    name: "Project Fair 2024",
    path: "/project-fair/2024",
    special: true,
    loginRequired: true,
  },
];
const userLinks = [
  {
    name: "Dashboard",
    path: "/dashboard",
    permission_level: PermissionLevel.Mentee,
  },
  {
    name: "Admin Settings",
    path: "/admin-settings",
    permission_level: PermissionLevel.Officer,
  },
  {
    name: "Applications",
    path: "/applications",
    permission_level: PermissionLevel.Committee,
  },
  {
    name: "Application Rater",
    path: "/application-rater",
    permission_level: PermissionLevel.Committee,
  },
  {
    name: "Pledges",
    path: "/pledges",
    permission_level: PermissionLevel.Committee,
  },
  {
    name: "Pledge Submission",
    path: "/pledge",
    permission_level: PermissionLevel.User,
  },
];

class Header extends Component {
  state = {
    collapseNav: window.innerWidth < collapseWidth,
  };

  componentDidMount = () => {
    window.addEventListener("resize", (_event) => {
      if (window.innerWidth < collapseWidth && !this.state.collapseNav) {
        this.setState({ collapseNav: true });
      }
      if (window.innerWidth >= collapseWidth && this.state.collapseNav) {
        this.setState({ collapseNav: false });
      }
    });
  };

  filterLinks = (link) => {
    return (
      (this.props.currentUser?.Role.permission_level || 0) >= link.permission_level
    );
  };

  render = () => {
    const filteredSiteLinks = siteLinks.filter(l=>!l.loginRequired || !!this.props.currentUser)
    const filteredUserLinks = userLinks.filter(this.filterLinks)

    return (
      <header className="site-header">
        <nav className="nav">
          <Link to="/" className="logo">
            <img src={logoSrc} alt="CLEAR" />
          </Link>

          <Menu
            name="Menu"
            links={filteredSiteLinks}
            collapse={this.state.collapseNav}
          />
        </nav>

        {!!this.props.currentUser &&
          <nav className="nav nav-right">
            <Menu
              currentUser={this.props.currentUser}
              endLoginAs={this.props.endLoginAs}
              icon={faUserCircle}
              links={filteredUserLinks}
              collapse={true}
              align="right"
            />
          </nav>
        }
      </header>
    );
  };
}

Header.propTypes = {
  currentUser: PropTypes.shape({
    Role: PropTypes.shape({
      permission_level: PropTypes.number
    })
  })
}

export default Header;
