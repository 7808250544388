import { Button, Form } from 'react-bootstrap'
import useFetch from '../../../../hooks/useFetch'
import DatePicker from '../../../DatePicker'
import axios from 'axios'
import { FormEvent, useEffect, useState } from 'react'
import Loader from '../../../Loader'
import ErrorMessage from '../../../ErrorMessage'
import useSetTimeout from '../../../../hooks/useSetTimeout'

type Data = {
  opening: string // iso-date
  soft_deadline: string // iso-date
  deadline: string // iso-date
}

const ApplicationWindow = () => {
  const { setTimeout } = useSetTimeout()
  const noWindow = {opening: new Date().toISOString(), soft_deadline: new Date().toISOString(), deadline: new Date().toISOString()}
  const {data, loading, error, refresh} = useFetch<Data>('/api/application_window')
  const [window, setWindow] = useState<Data>(data || noWindow)
  const [submitting, setSubmitting] = useState(false)

  useEffect(()=>{
    const w = data || noWindow
    setWindow(w)
  }, [ data ])

  const setOpening = (d:string) => setWindow({...window, opening: d})
  const setSoftDeadline = (d:string) => setWindow({...window, soft_deadline: d})
  const setDeadline = (d:string) => setWindow({...window, deadline: d})

  const submit = (e:FormEvent) => {
    e.preventDefault()
    setSubmitting(true)
    axios.put('/api/application_window', window)
      .catch(e=>{
        // TODO: popup retry message
        console.error(e)
      })
      .finally(()=>{
        setTimeout(()=>setSubmitting(false), 500)
        refresh()
      })
  }

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <ErrorMessage error={error.toString()}/>
  }

  return (
      <Form onSubmit={submit}>
        <Form.Group>
          <Form.Label>Opening</Form.Label>
          <DatePicker utc value={window.opening} onChange={d=>d && setOpening(d)}></DatePicker>
          <Form.Text className="text-muted">
            Applications cannot be started before this date.
          </Form.Text>
        </Form.Group>
        <Form.Group>
          <Form.Label>Soft Deadline</Form.Label>
          <DatePicker utc value={window.soft_deadline} onChange={d=>d && setSoftDeadline(d)} endOfDay></DatePicker>
          <Form.Text className="text-muted">
            This is the <i>displayed</i> deadline. The <i>actual</i> deadline is usually later.
          </Form.Text>
        </Form.Group>
        <Form.Group>
          <Form.Label>Deadline</Form.Label>
          <DatePicker utc value={window.deadline} onChange={d=>d && setDeadline(d)} endOfDay></DatePicker>
          <Form.Text className="text-muted">
            Applications cannot be submitted after this date.
          </Form.Text>
        </Form.Group>
        <Button variant="primary" disabled={submitting} type="submit">Save</Button>
      </Form>
  )
}

export default ApplicationWindow