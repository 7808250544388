import background_texture from "./background_texture.jpg";
import splatter_layer from "./splatter_layer.png";
import splatter_hard_1 from "./splatter_hard_1.png";
import splatter_hard_2 from "./splatter_hard_2.png";
import splatter_hard_3 from "./splatter_hard_3.png";
import splatter_soft_1 from "./splatter_soft_1.png";
import splatter_soft_2 from "./splatter_soft_2.png";
import splatter_soft_3 from "./splatter_soft_3.png";

import project_1 from "./project_1.png";
import project_2 from "./project_2.png";
import project_3 from "./project_3.png";
import project_4 from "./project_4.png";
import project_5 from "./project_5.png";
import project_6 from "./project_6.png";

import leader_1 from "./leader_1.jpg";
import leader_2 from "./leader_2.jpg";
import leader_3 from "./leader_3.jpg";
import leader_4 from "./leader_4.jpg";
import leader_5 from "./leader_5.jpg";
import leader_6 from "./leader_6.jpg";

import mission from "./mission.png";
import sessions from "./legos.png";
import projects from "./projects.png";
import leaders from "./leaders.png";
import apply from "./apply.png";

export var Images = {
  background_texture: background_texture,
  splatter_layer: splatter_layer,
  splatter_hard_1: splatter_hard_1,
  splatter_hard_2: splatter_hard_2,
  splatter_hard_3: splatter_hard_3,
  splatter_soft_1: splatter_soft_1,
  splatter_soft_2: splatter_soft_2,
  splatter_soft_3: splatter_soft_3,
  project_1: project_1,
  project_2: project_2,
  project_3: project_3,
  project_4: project_4,
  project_5: project_5,
  project_6: project_6,
  leader_1: leader_1,
  leader_2: leader_2,
  leader_3: leader_3,
  leader_4: leader_4,
  leader_5: leader_5,
  leader_6: leader_6,
};

export var Overlays = [mission, sessions, projects, leaders, apply];
