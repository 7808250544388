import React, { Component } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronUp,
  faUserCircle,
} from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import { Button } from "react-bootstrap";

const MenuList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  font-size: 1rem;

  &.open {
    background-color: var(--color-bg);
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.25);
    z-index: 2;
  }

  &.text-right li {
    text-align: right;
  }

  &.text-right li.text-center {
    text-align: center;
  }

  &.collapse {
    display: block;
  }

  & button {
    margin: 0 0.5rem;
  }
`;

const CurrentUser = styled.span`
  text-transform: uppercase;
  padding-right: 0.5rem;
  font-size: smaller;
  position: relative;
  top: 0.1em;
`
const MenuItem = styled.li`
  user-select: none;

  & .nav-link,
  .nav-link:link,
  .nav-link:visited {
    padding: 0 1.5rem;
    font-weight: 600;
    line-height: 4rem;
    background: none;
    border: none;
    color: var(--color-text);
    text-decoration: none;
  }

  & .nav-link:hover,
  .nav-link:active,
  .nav-link:focus {
    color: var(--color-primary);
    outline: none;
  }

  &.special .nav-link {
    color: #fff;
    background-color: var(--color-secondary);
    background-image: var(--color-primary-grad);
    transition: background-color 0.25s;

    &:hover,
    &:active {
      background-color: var(--color-primary);
    }
  }
`;

const MenuToggle = styled.button`
  display: inline-block;

  & .icon:last-child {
    margin-left: 0.25rem;
  }
`;

class Menu extends Component {
  state = {
    open: false,
  };

  hideMenu = () => {
    this.setState({ open: false });
  };

  showMenu = () => {
    this.setState({ open: true });
  };

  toggleMenu = () => {
    this.setState({ open: !this.state.open });
  };

  handleBlur = (e) => {
    if (!e.currentTarget.contains(e.relatedTarget)) {
      this.hideMenu();
    }
  };

  classNames = () => {
    let open = this.state.open ? "open" : "";
    let alignment = this.props.align ? "text-" + this.props.align : "";
    let collapse = this.props.collapse ? "collapse" : "";
    return open + " " + alignment + " " + collapse;
  };

  endLoginAs = () => {
    this.props.endLoginAs()
    this.hideMenu()
  }

  render = () => {
    let userName = this.props.currentUser?.User.name
    // trim sky title from name
    userName = userName?.replace(/ \(.* Sky - Guest\)$/,'')
    const isSimulated = this.props.currentUser?.simulated
    return (
      <MenuList className={this.classNames()} onBlur={this.handleBlur}>
        <MenuItem className={!this.props.collapse ? "hidden" : ""}>
          <MenuToggle className="nav-link" onClick={this.toggleMenu}>
            {userName ? <CurrentUser>{userName}</CurrentUser> : undefined}
            {this.props.icon ? (
              <FontAwesomeIcon
                className="icon icon-lg"
                icon={this.props.icon}
              />
            ) : null}

            <FontAwesomeIcon
              className="icon"
              icon={this.state.open ? faChevronUp : faChevronDown}
            />
          </MenuToggle>
        </MenuItem>
        {this.state.open || !this.props.collapse ? (<>
          { isSimulated ? (
            <MenuItem className='text-center' style={{marginBottom: '1em'}}>
              <Button variant="dark" size='sm' onClick={this.endLoginAs}>End Login As</Button>
            </MenuItem>
          ) : undefined }
          <div className='dropdown-divider' style={{margin: '-0.25em 0'}}/>
          {this.props.links.map((link) => (
            <MenuItem
              key={link.name}
              className={link.special ? "special" : ""}
              onClick={this.hideMenu}
            >
              {link.external ? (
                <a
                  className="nav-link"
                  href={link.path}
                  title={link.name}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {link.icon} {link.name}
                </a>
              ) : (
                <Link to={link.path} className="nav-link">
                  {link.icon} {link.name}
                </Link>
              )}
            </MenuItem>
          ))}
        </>)
        : null}
      </MenuList>
    );
  };
}

export default Menu;
