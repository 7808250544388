// FROM https://usehooks-ts.com/react-hook/use-fetch
// changes: added loading and refresh to response
// - loading since data may not be null if refereshing
// - refresh to force another fetch w/o changing the url

/*
Example:

import { useFetch } from './useFetch'
export default function Component() {
  const { data, error, loading, refresh } = useFetch('/api/v1/titles')
  if (error) return <p>There is an error.</p>
  if (loading) return <p>Loading...</p>
  return <p>{data[0].title} <button onClick=refresh>Refresh</button></p>
}
*/

import { useCallback, useEffect, useReducer, useRef, useState } from 'react'

interface State<T> {
  data?: T
  error?: Error
  loading?: boolean
  refresh: ()=>void
}

// discriminated union type
type Action<T> =
  | { type: 'loading' }
  | { type: 'fetched'; payload: T }
  | { type: 'error'; payload: Error }

export default function useFetch<T = unknown>(
  url?: string,
  options?: RequestInit
): State<T> {
  const [refreshFlag, setRefreshFlag] = useState(false)
  const refresh = useCallback(() => {setRefreshFlag(!refreshFlag)}, [refreshFlag])

  // Used to prevent state update if the component is unmounted
  const cancelRequest = useRef<boolean>(false)

  const initialState: State<T> = {
    error: undefined,
    data: undefined,
    loading: undefined,
    refresh
  }

  // Keep state logic separated
  const fetchReducer = (state: State<T>, action: Action<T>): State<T> => {
    switch (action.type) {
      case 'loading':
        return { ...initialState, loading: true }
      case 'fetched':
        return { ...initialState, data: action.payload, loading: false }
      case 'error':
        return { ...initialState, error: action.payload, loading: false }
      default:
        return state
    }
  }

  const [state, dispatch] = useReducer(fetchReducer, initialState)

  useEffect(() => {
    // Do nothing if the url is not given
    if (!url) return

    cancelRequest.current = false

    const fetchData = async () => {
      dispatch({ type: 'loading' })

      try {
        const response = await fetch(url, options)
        if (!response.ok) {
          throw new Error(response.statusText)
        }

        const data = (await response.json()) as T
        if (cancelRequest.current) return

        dispatch({ type: 'fetched', payload: data })
      } catch (error) {
        if (cancelRequest.current) return

        dispatch({ type: 'error', payload: error as Error })
      }
    }

    fetchData()

    // Use the cleanup function for avoiding a possibly...
    // ...state update after the component was unmounted
    return () => {
      cancelRequest.current = true
    }
  }, [url, refreshFlag])

  return state
}

