import { createContext } from "react";
import useFetch from "../../../hooks/useFetch";

const nodate = new Date(0)
const ApplicationWindowContext = createContext({
  opening: nodate,
  softDeadline: nodate,
  deadline: nodate
})
export default ApplicationWindowContext

const ApplicationWindowContextProvider = ({children}) => {
  const { data, error } = useFetch('/api/application_window');
  if (error) {
    console.error(error)
  }
  const nonDate = new Date(0)
  const { opening=nonDate, soft_deadline=nonDate, deadline=nonDate } = data || {}
  const value = {
    opening:new Date(opening),
    softDeadline:new Date(soft_deadline),
    deadline:new Date(deadline),
  }
  return (
    <ApplicationWindowContext.Provider value={value}>{children}</ApplicationWindowContext.Provider>
  )
}

export { ApplicationWindowContextProvider }