import { faBan, faCheckCircle, faCircle, faCircleCheck, faSlash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Card, Button, ListGroup, Badge } from 'react-bootstrap'

const initialCase = (s) => s.charAt(0).toUpperCase() + s.slice(1)
const roleName = (applicantType) => {
  if (applicantType == 'committee') return 'Committee Member'
  return initialCase(applicantType)
}

const ManagerApprovals = ({managerSubmissions=[]}) => {
  if (managerSubmissions.length == 0) return <></>

  return (
    <Card border='primary' className='mb-4'>
      <Card.Header>Manager Approvals</Card.Header>
      <Card.Body as='div'>
        <ListGroup>
          {managerSubmissions.map(sub=>
            <ListGroup.Item key={sub.id} className="d-flex justify-content-between align-items-start">
              <div className="ms-2 me-auto">
                <div><strong>{sub.User.name}</strong></div>
                <div>Role: {roleName(sub.ApplicantType.applicant_type)}</div>
              </div>
              <span className='d-flex align-items-center'>
                {sub.MangerApproval ? <Badge variant='success' className='mr-1'>Approved</Badge> : undefined}
                <Button href={`/application/${sub.id}/manager_approval`}
                  variant={sub.ManagerApproval ? 'light' : 'primary'}>
                  {sub.ManagerApproval ? "Update Approval" : "Submit Approval"}
                </Button>
              </span>
            </ListGroup.Item>
          )}
        </ListGroup>
        <Card.Text className='mt-3'>
          <i><b>Note:</b> Due to space limitations, manager approval does not guarantee
          the applicant will be included in this year's CLEAR program.</i>
        </Card.Text>
      </Card.Body>
    </Card>
  )
}

export default ManagerApprovals