import { Button, Col, Form, Modal, Row } from "react-bootstrap"
import { FormEvent, useEffect, useState } from "react"
import useFetch from "../../../../hooks/useFetch"
import { MessageEditor } from "../../../MessageEditor"
import { EmailReminder } from "../../../../schema"
import axios from "axios"
import Select from 'react-select'
import './react-select.css'
import classNames from "../../../../util/classNames"
import DateTimePicker from "../../../DateTimePicker/DateTimePicker"

const defaultFrom = 'clear@comcast.com'

const isEmptyHtml = (s:string|undefined) => {
  if (!s) return true
  return !![/^<p><br><\/p>$/, /^<p> *<\/p>$/].find(re => re.test(s.trim() as string))
}
const inPast = (s:string|undefined) => {
  if (!s) return false
  const now = new Date()
  return new Date(s) < now
}

interface Option {
  value: string
  label: string
}

interface FormErrors {
  from?: string
  sendOn?: string
  recipients?: string
  chapters?: string
  subject?: string
  htmlBody?: string
}

interface Props {
  show: boolean
  onHide: ()=>void
  editId?: number
  recipientOptions: Option[]
  chapterOptions: Option[]
}

const EmailModal = ({show, onHide, editId, recipientOptions, chapterOptions}:Props) => {
  const tomorrow9AM = () => {
    const tomorrow = new Date()
    tomorrow.setDate(tomorrow.getDate() + 1)
    tomorrow.setHours(9)
    tomorrow.setMinutes(0)
    tomorrow.setSeconds(0)
    tomorrow.setMilliseconds(0)
    return tomorrow.toISOString()
  }

  const [errors, setErrors] = useState<FormErrors>({})
  const {data:editTarget} = useFetch<EmailReminder>(editId == undefined ? '' : `/api/email_reminders/${editId}`)

  const [selectedRecipients, _setSelectedRecipients] = useState<Option[]>([])
  const [recipientCount, setRecipientCount] = useState<number>()
  const setSelectedRecipients = (o:Option[]) => {
    _setSelectedRecipients(o)
    if (o.length > 0) {
      errors.recipients = undefined
      setErrors(errors)
    }
  }

  const [selectedChapters, _setSelectedChapters] = useState<Option[]>([])
  const setSelectedChapters = (o:Option[]) => {
    _setSelectedChapters(o)
    if (o.length > 0) {
      errors.chapters = undefined
      setErrors(errors)
    }
  }

  // get count of recipients
  useEffect(()=>{
    if (selectedChapters.length == 0 || selectedRecipients.length == 0) {
      setRecipientCount(undefined)
      return
    }

    fetch(`/api/email_reminders/recipient_count`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify({
        recipientTypes: selectedRecipients.map(x=>x.value),
        chapterIds: selectedChapters.map(x=>x.value.toString()),
      })
    })
    .then(res=>res.json())
    .then((json)=>{
      console.log('json', json)
      setRecipientCount(json.count)
    })
    .catch((err)=>{
      console.log('err', err)
      setRecipientCount(undefined)
    })
  }, [selectedRecipients, selectedChapters])

  const [from, _setFrom] = useState(defaultFrom)
  const setFrom = (s:string) => {
    _setFrom(s)
    if (s && !!s.trim()) {
      errors.from = undefined
      setErrors(errors)
    }
  }

  const [subject, _setSubject] = useState('')
  const setSubject = (s:string) => {
    _setSubject(s)
    if (s && !!s.trim()) {
      errors.subject = undefined
      setErrors(errors)
    }
  }

  const [sendOn, _setSendOn] = useState(tomorrow9AM())

  const setSendOn = (s:string|undefined) => {
    if (!s) return
    _setSendOn(s)
    if (s && !!s.trim()) {
      errors.sendOn = undefined
      setErrors(errors)
    }
    if (inPast(s)) {
      errors.sendOn = 'Please provide a future time.'
      setErrors(errors)
    }
  }

  const [htmlBody, _setHtmlBody] = useState('')
  const setHtmlBody = (s:string) => {
    console.log('htmlBody', s)
    _setHtmlBody(s)
    if (isEmptyHtml(s)) {
      errors.htmlBody = undefined
      setErrors(errors)
    }
  }
  const [saving, setSaving] = useState(false)

  const clearForm = () => {
    setSelectedRecipients([])
    setSelectedChapters([])
    setFrom(defaultFrom)
    setSubject('')
    setSendOn(tomorrow9AM())
    setHtmlBody('')
  }

  useEffect(()=>{
    if (!editId || !editTarget) {
      // clear form if editTarget is undefined
      clearForm()
      return
    }

    // update form when editTarget is loaded
    let values = editTarget.recipientTypes.split(',').map(x=>x.trim())
    let selected = recipientOptions.filter(x=>values.includes(x.value))
    setSelectedRecipients(selected)

    values = editTarget.chapterIds.split(',').map(x=>x.trim())
    selected = chapterOptions.filter(x=>values.includes(x.value))
    setSelectedChapters(selected)

    setSubject(editTarget.from)
    setSubject(editTarget.subject)
    setSendOn(editTarget.sendOn)
    setHtmlBody(editTarget.htmlBody)
  }, [editId, editTarget])


  const validate = () => {
    const errors = {} as FormErrors
    let isValid = true
    if (!from || !from.trim()) {
      errors.from = 'Please provide a from email.'
      isValid = false
    }
    if (selectedChapters.length == 0) {
      errors.chapters = 'Please select a chapter.'
      isValid = false
    }
    if (selectedRecipients.length == 0) {
      errors.recipients = 'Please select a member group.'
      isValid = false
    }
    if (!subject || !subject.trim()) {
      errors.subject = 'Please provide a subject.'
      isValid = false
    }
    if (isEmptyHtml(htmlBody)) {
      errors.htmlBody = 'Please provide a message.'
      isValid = false
    }
    if (!sendOn || !sendOn.trim() || isNaN(new Date(sendOn).getTime())) {
      errors.sendOn = 'Please provide a time.'
      isValid = false
    } else if (inPast(sendOn)) {
      errors.sendOn = 'Please provide a future time.'
      isValid = false
    }
    setErrors(errors)
    return isValid
  }

  const handleSubmit = (e:FormEvent) => {
    e.preventDefault()
    if (validate()) save()
  }

  const save = () => {
    setSaving(true)

    const recipientTypes = selectedRecipients.map(x=>x.value).join(',')
    const chapterIds = selectedChapters.map(x=>x.value).join(',')
    const emailReminder = {
        from,
        recipientTypes,
        chapterIds,
        subject,
        sendOn,
        sentOn: null,
        sentTo: null,
        htmlBody
    }
    if (editId) {
      // update the message
      axios.put(`/api/email_reminders/${editId}`, emailReminder)
      .then(()=>{
        onHide()
      })
      .catch(err=>{
        // TODO: show retry popup
        console.error(err)
      })
      .finally(()=>{
        setSaving(false)
      })
      return
    }

    axios.post('/api/email_reminders', emailReminder)
    .catch(err=>{
      // TODO: show retry popup
      console.error(err)
    })
    .finally(()=>{
      setSaving(false)
      onHide()
    })
  }

  const onCancel = () => {
    setErrors({})
    onHide()
  }
  const fmtCount = (c:number|undefined) => {
    console.log('fmtCount', c)
    if (c == undefined) return c
    return `(${c.toLocaleString()})`
  }

  const action = editId ? ((editTarget?.sentOn) ? 'Reschedule': 'Update') : 'Schedule'

  return (
    <Modal show={show} onHide={onCancel} size="lg">
      <Modal.Header>
        <h3>{`${action} Email Reminder`}</h3>
      </Modal.Header>
      <Modal.Body>

      <Form onSubmit={handleSubmit}>
        <Row>
          <Col>
            <Form.Group>
              <Form.Label>From</Form.Label>
              <Form.Control type='email'
                className={classNames({"is-invalid":!!errors.from})}
                value={from} onChange={(e)=>setFrom(e.target.value)}
                required placeholder='An email address...'
              />
              <div className="invalid-feedback">{errors.from}</div>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>Send On</Form.Label>
              <DateTimePicker value={sendOn} onChange={setSendOn} invalid={!!errors.sendOn}/>
              <div className="d-block invalid-feedback">{errors.sendOn}</div>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group>
              <Form.Label id='recipients'>Recipients {fmtCount(recipientCount)}</Form.Label>
              <Select isMulti isClearable={false}
                className={classNames('react-select', {"is-invalid":errors.recipients})}
                options={recipientOptions}
                value={selectedRecipients} onChange={x=>setSelectedRecipients(x as Option[])}
                required aria-invalid={!!errors.recipients}
                aria-aria-errormessage={errors.recipients}
              />
              <div className="d-block invalid-feedback">{errors.recipients}</div>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label id='chapters'>Chapters</Form.Label>
              <Select isMulti isClearable={false}
                className={classNames('react-select', {"is-invalid":errors.chapters})}
                options={chapterOptions}
                value={selectedChapters} onChange={x=>setSelectedChapters(x as Option[])}
                required aria-invalid={!!errors.chapters}
                aria-aria-errormessage={errors.chapters}
              />
              <div className="d-block invalid-feedback">{errors.chapters}</div>
            </Form.Group>
          </Col>
        </Row>
        <Form.Group>
          <Form.Label>Subject</Form.Label>
          <Form.Control required value={subject}
            className={classNames({"is-invalid": errors.subject})}
            onChange={(e)=>setSubject(e.target.value)}
            placeholder='Add a subject...'
          />
          <div className="d-block invalid-feedback">{errors.subject}</div>
        </Form.Group>
        <Form.Group>
          <Form.Label>Message</Form.Label>
          <MessageEditor
            className={classNames({'is-invalid':errors.htmlBody})}
            value={htmlBody}
            onChange={setHtmlBody}
          />
          <div className="d-block invalid-feedback">{errors.htmlBody}</div>
        </Form.Group>
      </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button disabled={saving} onClick={handleSubmit}>{`${action } Reminder`}</Button>
        <Button onClick={onCancel} variant="dark">Cancel</Button>
      </Modal.Footer>
    </Modal>
  )
}

export default EmailModal