//App Type Constants
export const APPLICANT_TYPE_MENTEE = 1
export const APPLICANT_TYPE_MENTOR = 2
export const APPLICANT_TYPE_COMMITTEE = 3
export const APP_TYPE_ID_TO_STRING = {
  1: "Mentee",
  2: "Mentor",
  3: "Committee Member",
};
//App Status Constants
export const APPLICATION_STATUS_ID_PENDING = 1
export const APPLICATION_STATUS_ID_SUBMITTED = 2
export const APPLICATION_STATUS_ID_WITHDRAWN = 3
export const APPLICATION_STATUS_ID_ACCEPTED = 4
export const APPLICATION_STATUS_ID_REJECTED = 5
//Essay Constants
export const ESSAY_MAX_WORD_COUNT = 600;
export const local_ESSAY_APPROX_WORD_COUNT = 500; //used locally in CONSTANTS variable ESSAY PROMPT
export const ESSAY_PROMPT = `Describe the reason you would like to join CLEAR program in an essay, that is around ${local_ESSAY_APPROX_WORD_COUNT} words. Please detail what you hope to gain and offer through being selected for the mentoring program. Review your response with your immediate supervisor/manager prior to submission.  This essay is required for consideration.`

//Competencies Constants
export const COMPETENCIES_MENTEE_INFO = "As a mentee, please rate your level of strength in each area of opportunity. " +
  "Rank areas you would like to develop as weaker. This helps us pair you with a mentor more effectively.";

export const COMPETENCIES_MENTOR_INFO = "As a mentor, please rate your level of strength in each area of " +
  "opportunity. Rank areas you can help a mentee develop as stronger. " +
  "This helps us pair mentors and mentees together more effectively.";

export const COMPETENCIES_COMMITTEE_MEMBER_INFO = "As a committee member, please rate your level of strength in each area of " +
  "opportunity. This helps us pair triad committee members with mentors and mentees effectively.";

export const COMPETENCIES_ADDITIONAL_SKILLS_PROMPT = "Feel free to share more about your skills and aspirations " +
  "in the space below. You can elaborate with examples of the skills listed above, or any additional skills or talents you possess.";


