import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTh,
} from "@fortawesome/free-solid-svg-icons";
import { getPastProjectFairs } from "../../services/projectFairDetails.js";
import PermissionLevel from "../../data/PermissionLevel";
import PageHeader from "../PageHeader";
import MultiSelect from "../MultiSelect";
import ProjectCard from "./ProjectCard";
import { Button } from "react-bootstrap";
import "./Projects.css";

class ProjectFair extends Component {
  constructor(props) {
    super(props);
    this.multiSelectProjTagRef = React.createRef();
    this.multiSelectProjLocaRef = React.createRef();
  }
  
  clearAllMultiSelections = () => {
    this.multiSelectProjTagRef.current.clearDataList();
    this.multiSelectProjLocaRef.current.clearDataList();
    this.multiSelectProjTagRef.current.handleChange([]);
    this.multiSelectProjLocaRef.current.handleChange([]);
  }
  state = {
    projects: [],
    tags: [],
    locations:[],
    tagFilters: [],
    locationFilters: [],
    possibleLocations: [],
    possibleTags: [],
    filteredProjects: [],
  };

  componentDidMount = async () => {
    let details = await getPastProjectFairs();
    let tags = [
      ...new Set(details.map((p) => p.tags.map((t) => t.tag.name)).flat()),
    ];
    let locations = [...new Set(
      details.map((d) => d.Chapter.location)
    )];
    this.setState({
      projects: details,
      possibleTags: tags,
      possibleLocations: locations,
    });
  };

  isOfficer() {
    var userRole = this.props.currentUser.Role;
    return userRole.permission_level >= PermissionLevel.Officer;
  }

  filterProjectByTag = async () => {
    await this.setState({
      filteredProjects: this.state.projects.filter((p) =>
        p.tags.some((t) => this.state.tagFilters.includes(t.tag.name))
      ),
    });
  };

  addTagFilter = async () => {
    var tag = document.getElementById("potential-tag-input").value;
    if (this.state.possibleTags.includes(tag)) {
      await this.setState({
        tagFilters: [...this.state.tagFilters, tag],
        possibleTags: this.state.possibleTags.filter((t) => t != tag),
      });
      await this.filterProjectByTag();
      // clear input field
      document.getElementById("potential-tag-input").value = null;
    }
  };

  removeTag = async (tag) => {
    let tags = this.state.tagFilters.filter((t) => t != tag);
    await this.setState({
      tagFilters: tags,
      possibleTags: [...this.state.possibleTags, tag],
    });
    await this.filterProjectByTag();
  };

  filteredProjects = () => {
    let projectFilteredList = [];
    let projects = this.state.projects;
  
    if (this.state.tagFilters?.length === 0 && this.state.locationFilters?.length === 0) {
      return this.state.projects;
    }

    if (this.state.tagFilters.length > 0 || this.state.locationFilters.length > 0) {
      
      projectFilteredList = projects.filter((p) => {
        
        if(this.state.tagFilters.length > 0 && this.state.locationFilters.length > 0){
          return p.tags?.some((t) =>  (this.state.tagFilters.length>0 && this.state.tagFilters.includes(t.tag.name)) && (this.state.locationFilters.length > 0 && this.state.locationFilters.includes(p.Chapter.location)));
        }
        if(this.state.tagFilters.length > 0){
          return p.tags?.some((t) =>  (this.state.tagFilters.length>0 && this.state.tagFilters.includes(t.tag.name)));  
        }

        if(this.state.locationFilters.length > 0){
          return p.tags?.some((t) =>  (this.state.locationFilters.length > 0 && this.state.locationFilters.includes(p.Chapter.location)));        
        }
     });
      
    }
    if(projectFilteredList.length > 0){
      return projectFilteredList;
    }
  };

  render() {
    let projectList = this.filteredProjects();

    return (
      <>
        <PageHeader title="Projects" />

        <main className="projects">
          <div className="project-fair-controls">
          <div className="project-fair-filters">
            <div className="project-tag-input">
              <MultiSelect
                id="tag-filter"
                label="Filter By Topic"
                datalist={this.state.possibleTags}
                ref={this.multiSelectProjTagRef}
                onChange={(s) =>
                  this.setState({ tagFilters: s }, this.updateCols)
                }
              />
            </div>
            <div className="project-location-input">
                  <MultiSelect
                    id="location-filter"
                    type="select"
                    label="Filter By Location"
                    isShowClearAll={false}
                    datalist={this.state.possibleLocations}
                    ref={this.multiSelectProjLocaRef}
                    onChange={(s) => this.setState({ locationFilters: s }, this.updateCols)}
                  />
            </div>
            <div className="project-clear-filter">
                   <Button onClick={this.clearAllMultiSelections}>
                   Clear Filter
                   </Button>
            </div>
            </div>   
          </div>

          <ul className="project-list">
            {projectList && projectList.length > 0 ? (
              projectList.map((p, i) => (
                <li key={p.id} className="project">
                  <ProjectCard
                    data={p}
                    editable={this.isOfficer()}
                    collapsible={false}
                    open={false}
                  />
                </li>
              ))
            ) : (
              <div className="project-fair-text text-center">
                <p>
                  <FontAwesomeIcon
                    icon={faTh}
                    size="6x"
                    className="text-muted text-faded-25"
                  />
                </p>
                <p className="text-muted">No Results Found!</p>
              </div>
            )}
          </ul>
        </main>
      </>
    );
  }
}

export default ProjectFair;
