import { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import { Typeahead } from 'react-bootstrap-typeahead'
import 'react-bootstrap-typeahead/css/Typeahead.css'

// Note: might be nice to move this list to a database to make it easy to update
const options = [
  { label: 'Asian Pacific Americans' },
  { label: 'Black Employee Network' },
  { label: 'Indigenous' },
  { label: 'MyAbilities' },
  { label: 'OUT' },
  { label: 'Unidos' },
  { label: 'Veterans Network' },
  { label: 'Womens Network' },
  { label: 'Young Professionals Network' },
]
const csvToOptions = (s) => (!s) ? [] : s.split(',').map(x => ({ label: x.trim() }))
const optionsToCsv = (o) => o.map(x => x.label).join(',')

const ERGFormGroup = (props) => {
  const { id, name, value, onChange } = props
  const addlProps = { ...props, id: undefined, value: undefined, onChange: undefined }
  const [selection, setSelection] = useState(csvToOptions(value))
  useEffect(() => setSelection(csvToOptions(value)), [value])

  const handleChange = (options) => {
    setSelection(options)
    if (onChange) {
      let optsToCsv = optionsToCsv(options)
      let event = {
        target: {
          ...props,
          value: optsToCsv
        }
      }
      onChange(event);
    }
  }

  return (
    <Form.Group {...addlProps}>
      <Form.Label>If any, which Comcast Employee Resource Groups (ERGs) have you been involved with?</Form.Label>
      <Typeahead
        className='erg-input'
        id={id||'erg-form-control'}
        name={name}
        multiple
        allowNew
        newSelectionPrefix='New ERG: '
        onChange={handleChange}
        options={options}
        placeholder='I have been involved with...'
        selected={selection}
      />
    </Form.Group>
  )
}
export default ERGFormGroup