import { Button, Modal } from "react-bootstrap"

interface Props {
    /**
     * Show the modal
     */
    show: boolean
    /**
     * Applicant type - "1" or "2" (mentee or mentor)
     */
    applicationType: string
    /**
     * Callback function to close the modal
     * @returns void
     */
    onCancel: () => void
    /**
     * Callback function to submit the modal/proceed to the next step of the application process
     * @returns void
     */
    handleSubmit: () => void

    showOkButton: boolean; // New prop to determine the current application status
}

export const TimeCommitmentModal = ({ show, onCancel, applicationType, handleSubmit, showOkButton }: Props) => {
    return (
        <Modal show={show} onHide={onCancel} size="lg">
            <Modal.Header>
                <h3>{`Time Commitments`}</h3>
            </Modal.Header>
            <Modal.Body>

                <p>
                    Before applying, understand that this program is going to put pressure
                    on your professional and personal life. We ask potential Mentees to be
                    conscious of any major life events that may be on the horizon for the
                    year. Not everything in life is planned and the program is designed
                    with that flexibility in mind. However, if you know you will be
                    overloaded or taking extended periods of time off, the additional
                    commitments CLEAR requires may not be a good opportunity to seek out
                    this year.
                </p>
                <p>
                    Also be aware that only a finite amount of seats are available each
                    year. If you commit to the program and later decide the commitment is
                    too large, your seat will go unused. There is no practical method to
                    backfill seats after the program has started. Please be honest, aware,
                    and sincere about your commitment of your best intentions to the
                    program. CLEAR is a team of people who are giving their time and
                    network to you over the course of the year and we’d like to make sure
                    that Mentees and Mentors are serious about their intentions.
                </p>
                <p>Your expected time commitments include the following:</p>
                <ul>
                    <li>
                        The program runs for ~9 months, beginning in February and ending in
                        October
                    </li>
                    <li>
                        Classroom sessions require ~10 hours per month throughout the
                        program and participants are allowed a single unexcused absence
                    </li>
                    <li>
                        Projects require roughly 1-8 hours per week throughout the program
                    </li>
                </ul>
            </Modal.Body>
            <Modal.Footer>
                {
                    showOkButton ? (
                        <Button onClick={onCancel}>{`OK`}</Button>
                    ) : (
                        <>
                            <Button onClick={handleSubmit} value={applicationType}>{`Accept and Proceed`}</Button>
                            <Button onClick={onCancel} variant="dark">Cancel</Button>
                        </>
                    )
                }
            </Modal.Footer>
        </Modal>
    )
}
