import { SVG } from "@svgdotjs/svg.js";
import background_texture from "../../assets/images/background_texture.jpg";

export function Splatter(id, options) {
  this.id = id;

  /**
   * These options define the contstraints for the generated image. Any
   * of these can be overridden by passing in an options object when you
   * instantiate the Splatter object.
   */
  this.options = {
    width: 1920,
    height: 1080,

    // Images
    texture: {
      url: background_texture,
      rotate: 0,
    },
    overlay: false,
    maskSprites: [],
  };

  /* Override defaults with given options */
  Object.assign(this.options, options);

  /**
   * Scale and rotate can be an Array or a Number. Arrays contain a
   * range value then a translate value that dictate the thresholds
   * of the random offest for that image. A single number will not
   * generate a random value and use the given value instead.
   */
  if (options.maskSprites) {
    this.options.maskSprites.push(...options.maskSprites);
  }

  /**
   * This is the high level process that generates the layers of the SVG.
   */
  this.generate = function () {
    // Generate a new SVG object
    this.svg = SVG()
      .addTo(this.id)
      .size(this.options.width, this.options.height);

    // Generates the primary background texture for the image
    this.createTexture();

    // Generates the masking used for the image
    this.createMasking();

    this.createOverlay();
  };

  /**
   * This generates the background texture layer using the image defined in
   * options.texture.
   */
  this.createTexture = function () {
    this.bg = this.svg.image(this.options.texture.url, (e) => {
      var w = this.options.width;
      var h = this.options.height;
      var cX = this.options.width / 2;
      var cY = this.options.height / 2;
      var locX = -(w / 2 - cX);
      var locY = -(h / 2 - cY);

      this.bg
        .size(w, h)
        .attr("preserveAspectRatio", "none")
        .opacity(0)
        .rotate(this.options.texture.rotate, cX, cY)
        .move(locX, locY);
    });
  };

  /**
   * This generates the masking layer using the settings defined in
   * options.maskSprites.
   */
  this.createMasking = function () {
    // Loop through all of the sprite layers
    this.maskGroup = this.svg.group();
    this.bg.maskWith(this.maskGroup);

    var sprites = this.options.maskSprites;
    sprites.forEach((s, idx) => {
      // Determine the computed rotation and scale values
      let rotate = s.rotate;
      if (Array.isArray(s.rotate)) {
        rotate = this.randomValue(...s.rotate);
      }

      let scale = s.scale;
      if (Array.isArray(s.scale)) {
        scale = this.randomValue(...s.scale);
      }

      // Generate as many layers as defined in the options
      var layers = [];
      for (let i = 1; i <= s.count; i++) {
        let url = typeof s.url === "string" ? s.url : s.url[i % s.url.length];

        layers[i] = this.maskGroup.image(url, (e) => {
          // Get the pseudorandom offsets
          let natW = e.target.naturalWidth;
          let natH = e.target.naturalHeight;
          let offset = this.getOffset(natW, natH, ...s.offset);
          let cX = offset[0] + natW / 2;
          let cY = offset[1] + natH / 2;

          // Apply transformations to the layer
          layers[i]
            .move(...offset)
            .rotate(rotate, cX, cY)
            .scale(scale, cX, cY)
            .opacity(s.opacity);

          // Don't show bg texture until at least one mask image is loaded
          // otherwise, you get an ugly flash of the full texture for a
          // split second while the mask is loading.
          this.bg.animate(250, 50).opacity(1);
        });
      }
    });
  };

  /* Adds an image overlay, if given */
  this.createOverlay = function () {
    if (this.options.overlay) {
      var overlay = this.svg.image(this.options.overlay, (e) => {
        var w = Math.max(this.options.width * 0.65, 360);
        var h = Math.max(this.options.height * 0.65, 360);
        var x = (this.options.width - w) / 2;
        var y = (this.options.height - h) / 2;

        overlay.size(w, h).move(x, y);
        overlay.maskWith(this.svg.use(this.maskGroup));
      });
    }
  };

  /**
   * This is a utility function that generates a pseudorandom value within
   * the given range, then sets the minimum value of that range to the
   * given translation value.
   */
  this.randomValue = function (range, translation) {
    return Math.floor((Math.random() * range + translation) * 100) / 100;
  };

  /**
   * This generates a [x,y] point with a pseudorandom offset for an image
   * sprite with the given width and height.
   */
  this.getOffset = function (w, h, sX, sY) {
    // Calculates the centerpoint of the image
    var cX = this.options.width / 2 - w / 2;
    var cY = this.options.height / 2 - h / 2;

    // Generates the random offset using defined class options
    var oX = (Math.random() - 0.5) * (this.options.width * sX);
    var oY = (Math.random() - 0.5) * (this.options.height * sY);

    // Applies the offset to the centerpoints
    var x = cX + oX;
    var y = cY + oY;

    return [x, y];
  };
}

export default Splatter;
