import React from "react";
import Table from "react-bootstrap/Table";
import "./EducationTable.css";
import Button from "react-bootstrap/Button";
import ConfirmDeleteButton from "../../../ConfirmDeleteButton";
import { Alert } from "react-bootstrap";

const EducationTable = (props) => {
  if (props.educationEntries.length === 0) return (
    <Alert variant="secondary">You have not entered your education history</Alert>
  );
  return (
    <Table className="education-table" responsive striped bordered>
      <thead>
        <tr>
          <th>Institution/Degree Earned</th>
          <th>Year Attained</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {props.educationEntries.map((education, index) => {
          return (
            <tr key={education.description + education.attainment_year}>
              <td>{education.description}</td>
              <td>{education.attainment_year}</td>
              <td width='1em'>
                <ConfirmDeleteButton onDelete={() => props.handleDeleteEducation(index)}/>
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

export default EducationTable;
