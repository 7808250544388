import axios from "axios";
import Moment from "../Moment";
import React, { Component } from "react";
import Alert from "react-bootstrap/Alert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import PermissionLevel from "../../data/PermissionLevel";
import "./Event.css";

export default class Dashboard extends Component {
  state = {
    loading: true,
    error: null,
    id: null,
    event: {},
  };

  componentDidMount() {
    this.loadEvent(this.props.id);
  }

  componentDidUpdate() {
    if (this.props.id && this.props.id !== this.state.id) {
      this.setState({
        id: this.props.id,
        loading: true,
        error: null,
      });

      this.loadEvent(this.props.id);
    }
  }

  loadEvent(id) {
    if (!id) {
      return;
    }

    axios
      .get(`/api/events/${id}`)
      .then((response) => {
        this.setState({
          loading: false,
          event: response.data,
        });
      })
      .catch((error) => {
        let message = error.message;
        if (error.response.status === 404) {
          message = "Item not found!";
        }

        this.setState({
          loading: false,
          error: message,
        });
      });
  }

  isOfficer() {
    var userRole = this.props.role;
    return userRole.permission_level >= PermissionLevel.Officer;
  }

  render = () => {
    let event = this.state.event;

    if (this.state.loading) {
      return <p>Loading data...</p>;
    } else if (this.state.error) {
      return (
        <Alert variant="danger">
          <FontAwesomeIcon icon={faExclamationTriangle} /> {this.state.error}
        </Alert>
      );
    } else if (this.state.deleted) {
      return (
        <Alert variant="success">
          <FontAwesomeIcon icon={faCheckCircle} /> Item deleted!
        </Alert>
      );
    } else if (event) {
      return (
        <article>
          <h1>{event.name}</h1>
          <p>
            <span className="badge badge-primary">
              <Moment format="dddd, MMMM Do">{event.date}</Moment>
            </span>{" "}
            <span className="badge badge-secondary">{event.type}</span>
          </p>
          <p>{event.description}</p>

          <hr />

          <h2>Links</h2>
          {this.renderEventLinks(event.EventLinks)}

          <hr />
        </article>
      );
    }
  };

  renderEventLinks = (links) => {
    if (!links || links.length === 0) {
      return <p className="text-muted">No links for this item!</p>;
    }

    return (
      <ul>
        {links.map((link) => {
          return (
            <li key={link.id}>
              <a href={link.url}>{link.name}</a>
            </li>
          );
        })}
      </ul>
    );
  };
}
