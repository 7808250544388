import React, { Component } from "react";
import axios from "axios";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import { Modal, Form } from "react-bootstrap";
import BootstrapTable from "../../react-bootstrap-table/packages/react-bootstrap-table2"
import filterFactory, { selectFilter } from "../../react-bootstrap-table/packages/react-bootstrap-table2-filter";
import cellEditFactory, { Type } from "../../react-bootstrap-table/packages/react-bootstrap-table2-editor";
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSort,
  faSortUp,
  faSortDown,
} from "@fortawesome/free-solid-svg-icons";
import Moment from "../Moment";
import { CSVLink } from "react-csv";
import Unauthorized from "../Unauthorized";
import PageHeader from "../PageHeader";
import Loader from "../Loader";
import PermissionLevel from "../../data/PermissionLevel";
import "./Applications.css";
import PropTypes from "prop-types";

function urlFormatter(cell, row) {
  let url = "/application/" + row.id;
  return (
    <a href={url} target="_blank" rel="noopener noreferrer">
      View
    </a>
  );
}

function dateFormatter(cell, _row) {
  if (cell) {
    return <Moment format="MM/DD/YYYY hh:mm A">{cell}</Moment>;
  }

  return cell;
}

function scoreFormatter(cell, _row) {
  if (cell) {
    let scoreText = scoreValue[Math.round(cell)];
    return `${cell} (${scoreText})`;
  }

  return "-";
}

const csvHeaders = [
  {
    label: "Applicant Type",
    key: "ApplicantType.applicant_type",
  },
  {
    label: "Chapter",
    key: "Chapter.location",
  },
  {
    label: "Applicant",
    key: "User.name",
  },
  {
    label: "Email",
    key: "User.email",
  },
  {
    label: "Location",
    key: "location",
  },
  {
    label: "Location Comments",
    key: "location_comments",
  },
  {
    label: "Department",
    key: "department",
  },
  {
    label: "Title",
    key: "title",
  },
  {
    label: "Manager",
    key: "manager",
  },
  {
    label: "Hire Date",
    key: "hire_date",
  },
  {
    label: "Submitted At",
    key: "submitted_at",
  },
  {
    label: "Essay",
    key: "essay",
  },
];

const scoreValue = [
  "Strong No",
  "No",
  "Weak No",
  "Weak Yes",
  "Yes",
  "Strong Yes",
];

function sortCaret(order, _column) {
  if (order === undefined) {
    return <FontAwesomeIcon icon={faSort} />;
  } else if (order === "asc") {
    return <FontAwesomeIcon icon={faSortUp} />;
  } else if (order === "desc") {
    return <FontAwesomeIcon icon={faSortDown} />;
  }
}

class Submissions extends Component {
  state = {
    year: new Date().getFullYear(),
    loading: true,
    saving: false,
    chapters: [],
    applications: [],
    applicationTypes: [],
    applicationStatuses: [],
    selectedRows: {},
    fields: [],
    showManagerApprovalModal: false,
    activeApplication: {}
  };

  componentDidMount = async () => {
    const chapters = await axios.get('/api/chapters').then(r => r.data);
    const applicationTypes = await axios.get('/api/application_types').then(r => r.data);
    const applicationStatuses = await axios.get("/api/applications/statuses").then(r => r.data);
    const applications = await axios.get("/api/applications/submissions")
      .then(async (resp) => {
        let apps = resp.data;

        apps.forEach((obj) => {
          // ensure that each application has a blank manager approval record when one doesn't exist
          if (obj && obj.ManagerApproval === null)
            obj.ManagerApproval = {
              approved: ''
            };
        });

        // Get the average score for each application
        await axios.get("/api/application_scores").then((resp) => {
          let scores = resp.data;
          for (let i in scores) {
            for (let j in apps) {
              if (apps[j].id === scores[i].application_id) {
                let score = scores[i].avg;
                apps[j].score = score;
                apps[j].num_ratings = scores[i].rankings_count;
              }
            }
          }
        }
        );

        return apps;
      });

    this.setState({
      chapters,
      applications,
      applicationTypes,
      applicationStatuses,
      loading: false,
      fields: [
        {
          dataField: "Chapter.location",
          text: "Chapter",
          sort: true,
          sortCaret: sortCaret,
          filter: selectFilter({
            options: chapters.reduce((opts, c) => {
              return Object.assign(opts, { [c.location]: c.location });
            }, {}),
          }),
          classes: "table-cell-editable",
          editCellClasses: "table-cell-editing",
          editable: this.canEdit,
          editor: {
            type: Type.SELECT,
            options: chapters.map((s) => ({
              value: s.location,
              label: s.location,
            })),
          },
        },
        {
          dataField: "ApplicantType.applicant_type",
          text: "Applicant Type",
          sort: true,
          sortCaret: sortCaret,
          filter: selectFilter({
            options: applicationTypes.reduce((opts, t) => {
              return Object.assign(opts, { [t.applicant_type]: t.applicant_type });
            }, {})
          }),
          classes: "table-cell-editable",
          editCellClasses: "table-cell-editing",
          editable: this.canEdit,
          editor: {
            type: Type.SELECT,
            options: applicationTypes.map((s) => ({
              value: s.applicant_type,
              label: s.applicant_type,
            })),
          },
        },
        {
          dataField: "User.name",
          text: "Applicant",
          sort: true,
          sortCaret: sortCaret,
          editable: false,
        },
        {
          dataField: "User.email",
          text: "Email",
          sort: true,
          sortCaret: sortCaret,
          editable: false,
        },
        {
          dataField: "title",
          text: "Title",
          sortCaret: sortCaret,
          sort: true,
          editable: false,
        },
        {
          dataField: "submitted_at",
          text: "Submitted At",
          sortCaret: sortCaret,
          sort: true,
          formatter: dateFormatter,
          editable: false,
        },
        {
          dataField: "manager_approval_status_id",
          text: "Manager Approved",
          sortCaret: sortCaret,
          sort: true,
          formatter: ((cell, row) => {
            // use the incoming cell to retrieve the string value of the manager approval status
            let matchingStatus = applicationStatuses.find((obj) => {
              return obj.id == cell;
            });

            if (matchingStatus)
              cell = matchingStatus.status;

            return cell;
          }),
          classes: "table-cell-editable",
          editCellClasses: "table-cell-editing",
          editable: this.canEdit,
          editor: {
            type: Type.SELECT,
            options: applicationStatuses.filter((s) => {
              let isSubmitted = s.status.toLowerCase() === "submitted";
              return !isSubmitted;
            }).map((s) => ({
              value: s.id,
              label: s.status,
            })),
          },
        },
        {
          dataField: "score",
          text: "Average Score",
          sortCaret: sortCaret,
          sort: true,
          formatter: scoreFormatter,
          editable: false,
        },
        {
          dataField: "num_ratings",
          text: "# Ratings",
          sortCaret: sortCaret,
          sort: true,
          editable: false,
        },
        {
          dataField: "ApplicationStatus.status",
          text: "App Status",
          sortCaret: sortCaret,
          sort: true,
          filter: selectFilter({
            options: applicationStatuses.reduce((opts, s) => {
              return Object.assign(opts, { [s.status]: s.status });
            }, {}),
          }),
          classes: "table-cell-editable",
          editCellClasses: "table-cell-editing",
          editable: this.canEdit,
          editor: {
            type: Type.SELECT,
            options: applicationStatuses.filter((s) => {
              let isPending = s.status.toLowerCase() === "pending";
              let isWithdrawn = s.status.toLowerCase() === "withdrawn";
              return !isPending && !isWithdrawn;
            }).map((s) => ({
              value: s.status,
              label: s.status,
            })),
          },
        },
        {
          dataField: "id",
          text: "",
          formatter: urlFormatter,
          editable: false,
        },
      ]
    });
  };

  updateApplication = (application) => {
    this.setState({ saving: application.id, loading: true });

    axios
      .put(`/api/applications/${application.id}`, application)
      .then((_res) => {
        let savedAppId = this.state.saving;
        let apps = this.state.applications;

        // update the application with the latest changes from server 
        for (const [index, app] of apps.entries()) {
          if (app.id === savedAppId) {
            apps[index] = _res.data;

            // set the the applicant type back to the misspelled version of committee until the issue is resolved in the backend
            if (apps[index].hasOwnProperty('applicant_type') && apps[index]['applicant_type'] === 'committee') {
              apps[index]['applicant_type'] = 'commitee';
            }
          }
        }

        this.setState({ saving: false, loading: false, applications: apps }, () => {
          toast.success("Changes Saved!");
        });
      }, (err) => {
        this.setState({ saving: false, loading: false }, () => {
          toast.error(err.message);
        });
      });
  };

  selectRow = {
    mode: "checkbox",
    onSelect: (row, isSelect) => {
      let selectedRows = { ...this.state.selectedRows };
      if (isSelect) {
        selectedRows[row.id] = row;
        selectedRows[row.id].essay = selectedRows[row.id].essay
          ? selectedRows[row.id].essay
            .replace(/(\r\n|\n|\r)/gm, "")
            .replace('"', '"')
          : "";
      } else {
        delete selectedRows[row.id];
      }
      this.setState({ selectedRows: selectedRows });
    },
    onSelectAll: (isSelect, rows) => {
      let selectedRows = { ...this.state.selectedRows };
      for (let idx in rows) {
        if (isSelect) {
          selectedRows[rows[idx].id] = rows[idx];
          selectedRows[rows[idx].id].essay = selectedRows[rows[idx].id].essay
            ? selectedRows[rows[idx].id].essay
              .replace(/(\r\n|\n|\r)/gm, "")
              .replace('"', '"')
            : "";
        } else {
          delete selectedRows[rows[idx].id];
        }
      }
      this.setState({ selectedRows: selectedRows });
    },
  };

  canEdit = () => {
    let currentUser = this.props.currentUser;
    let userPermissionLevel = currentUser.Role.permission_level;
    return userPermissionLevel >= PermissionLevel.Officer;
  };

  handleChange = (oldValue, newValue, row, column) => {
    if (!this.canEdit()) {
      return;
    }

    // Update the application in the database
    let application = row;


    switch (column.dataField) {
      case "ApplicantType.applicant_type": {
        let applicantType = this.state.applicationTypes.find((s) => s.applicant_type === newValue);
        application.applicant_type_id = applicantType.id;
        this.updateApplication(application);
        break;
      }
      case "ApplicationStatus.status": {
        let status = this.state.applicationStatuses.find((s) => s.status === newValue);
        application.status_id = status.id;
        this.updateApplication(application);
        break;
      }

      case "Chapter.location": {
        let location = this.state.chapters.find((l) => l.location == newValue);
        application.chapter_id = location.id;
        application.location = location.location;
        this.updateApplication(application);
        break;
      }
      case "manager_approval_status_id": {
        let statusIdInt = parseInt(newValue, 10);
        let managerApprovalStatus = this.state.applicationStatuses.find((s) => s.id == statusIdInt);

        // newValue is an empty string when the user doesn't select an option
        if (newValue === "") {
          application.manager_approval_status_id = null;
        }

        if (managerApprovalStatus === undefined) // the user didn't select an option so we shouldn't save anything
          return;

        application.ManagerApproval.application_id = application.id;
        application.manager_approval_status_id = statusIdInt;


        if (managerApprovalStatus.status === 'Accepted') {
          application.ManagerApproval.approved = true;
          this.setState({ showManagerApprovalModal: true, activeApplication: application });
          return;
        }

        if (managerApprovalStatus.status === 'Rejected') {
          application.ManagerApproval.approved = false;
          this.updateApplication(application);
        } else {
          application.ManagerApproval.notes = '';
          this.updateApplication(application);
        }

        break;
      }
    }

    // Update the local state with new value
    let applications = [...this.state.applications];
    for (let i in applications) {
      if (column.dataField === "ApplicationStatus.status") {
        applications[i].status = newValue;
      }
    }

    this.setState(applications);
  };

  render() {
    if (
      this.props.currentUser.Role.permission_level < PermissionLevel.Committee
    ) {
      return <Unauthorized />;
    }

    if (this.state.loading) {
      return (
        <div>
          <Loader />
        </div>);
    }

    let hasManagerApprovalNotes = Object.keys(this.state.activeApplication).length > 0 && this.state.activeApplication.ManagerApproval?.notes !== null && this.state.activeApplication.ManagerApproval?.notes !== undefined;
    return (
      <>
        <PageHeader title="Applications" />
        <Container className="apps-container">
          <Modal show={this.state.showManagerApprovalModal} centered>
            <Modal.Header>
              Save Manager Approval
            </Modal.Header>
            <Modal.Body>
              <Form.Group>
                <Form.Label>Manager Approval Notes:</Form.Label>
                <Form.Control type="text" value={hasManagerApprovalNotes ? this.state.activeApplication.ManagerApproval.notes : ''} placeholder="Notes" onChange={((e) => {
                  let { value } = e.target;
                  let application = this.state.activeApplication;
                  application.ManagerApproval.notes = value;


                  this.setState({ activeApplication: application });
                })} />
              </Form.Group>
            </Modal.Body>
            <Modal.Footer style={{ justifyContent: 'space-between' }}>
              <Button variant="secondary" onClick={() => {
                let showManagerApprovalModal = this.state.showManagerApprovalModal;
                this.setState({ showManagerApprovalModal: !showManagerApprovalModal });
              }}>Cancel</Button>
              <Button onClick={(() => {
                this.updateApplication(this.state.activeApplication);
                this.setState({ showManagerApprovalModal: !this.state.showManagerApprovalModal })
              }
              )} variant="primary" disabled={hasManagerApprovalNotes && this.state.activeApplication.ManagerApproval.notes.trim().length === 0}>Save</Button>
            </Modal.Footer>
          </Modal>
          <Row>
            <BootstrapTable
              filterPosition="top"
              keyField="id"
              classes="submission-tbl"
              data={this.state.applications}
              columns={this.state.fields}
              filter={filterFactory()}
              selectRow={this.selectRow}
              loading={this.state.saving}
              cellEdit={cellEditFactory({
                mode: "click",
                blurToSave: true,
                afterSaveCell: this.handleChange,
              })}
            />
          </Row>
          <Row>
            <CSVLink
              data={Object.values(this.state.selectedRows)}
              headers={csvHeaders}
              filename={"clear-applicants-" + this.state.year + ".csv"}
            >
              Export Selection to CSV
            </CSVLink>
          </Row>
        </Container>
      </>
    );
  }
}

Submissions.propTypes = {
  currentUser: PropTypes.shape({
    Role: PropTypes.shape({
      permission_level: PropTypes.number,
    })
  })
}
export default Submissions;
