import { useCallback, useEffect, useRef, useState } from "react"
import { faXmark } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button } from "react-bootstrap"

/**
 * js version of hooks/useClickOutside (can't import *.ts into *.jsx)
 */
const useClickOutside = (cb) => {
  const ref = useRef(null)

  const handleClickOutside = useCallback((event) => {
    if (ref.current && !ref.current.contains(event.target)) cb()
  }, [cb])

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true)
    return () => document.removeEventListener('click', handleClickOutside, true)
  }, [handleClickOutside])

  return ref
}

const ConfirmDeleteButton = ({onDelete, disabled, confirmLabel="Delete", className}) => {
  const [confirm, setConfirm] = useState(false)
  const variant = confirm ? 'danger' : 'outline-secondary'
  const deleteElem = confirm ? <>{confirmLabel}</> : <FontAwesomeIcon icon={faXmark}/>

  const ref = useClickOutside(()=>{
    setConfirm(false)
  })

  const handleDelete = (e) => {
    e.preventDefault()
    e.stopPropagation()
    if (!confirm) {
      setConfirm(true)
      return
    }
    if (onDelete) onDelete()
  }

  const handleEsc = (e) => {
    if (e.key == "Escape") setConfirm(false)
  }

  return (
    <Button className={`btn-table-cell ${className}`}
      ref={ref}
      variant={variant}
      disabled={disabled}
      onClick={handleDelete}
      onKeyUp={handleEsc}
      onBlur={()=>setConfirm(false)}
    >
      {deleteElem}
    </Button>
  )
}

export default ConfirmDeleteButton