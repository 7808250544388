import { Button, Container } from "react-bootstrap";
import { Link } from "react-router-dom";

const NotFound = () => (
  <Container style={{paddingTop: "2em"}}>
    <h2>404 This is an unknown page.</h2>
    <Link to="/">
      <Button>Return Home</Button>
    </Link>
  </Container>
)

export default NotFound
