import { ReactNode, useState } from "react"
import { Button } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faXmark } from "@fortawesome/free-solid-svg-icons"
import useClickOutside from "../../hooks/useClickOutside"
import './DeleteableItem.css'

interface Props {
  confirmLabel?: string
  onDelete: ()=>void
  disabled: boolean
  children: ReactNode
}

const DeleteableItem = ({confirmLabel='Remove Item', onDelete, disabled=false, children}:Props) => {
  const [confirm, setConfirm] = useState(false)
  const variant = confirm ? 'danger' : 'outline-secondary'
  const deleteElem = confirm ? <>{confirmLabel}</> : <FontAwesomeIcon icon={faXmark}/>

  const ref = useClickOutside<HTMLButtonElement>(()=>{
    setConfirm(false)
  })

  const handleDelete = (e:React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault()
    e.stopPropagation()
    if (!confirm) {
      setConfirm(true)
      return
    }
    if (onDelete) onDelete()
  }

  const handleEsc = (e:React.KeyboardEvent) => {
    if (e.key == "Escape") setConfirm(false)
  }

  return (
    <div className='deleteable-item'>
      {children}
      <Button
        ref={ref}
        variant={variant}
        disabled={disabled}
        onClick={handleDelete}
        onKeyUp={handleEsc}
        onBlur={()=>setConfirm(false)}
      >
        {deleteElem}
      </Button>
    </div>
  )
}
export default DeleteableItem