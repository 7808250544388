import { Col, ListGroupItem, Row } from "react-bootstrap"
import { EmailReminder, Option } from "./types"
import './EmailReminderItem.css'
import DeleteableItem from "../../../DeleteableItem"
import { useState } from "react"
import { DateTime } from "luxon"
import RelativeTime from "../../../RelativeTime"

export const timeAgo = (dateTime: DateTime) => {
  const units:Intl.RelativeTimeFormatUnit[] = ['year', 'month', 'week', 'day', 'hour', 'minute', 'second']
  const diff = dateTime.diffNow().shiftTo(...units)
  const unit = units.find((unit) => diff.get(unit) !== 0) || 'second'
  const relativeFormatter = new Intl.RelativeTimeFormat('en', { numeric: 'auto', })
  return relativeFormatter.format(Math.trunc(diff.as(unit)), unit)
}

interface Props {
  emailReminder: EmailReminder
  onClick: (id:number, er:EmailReminder)=>void
  onDelete: (id:number, er:EmailReminder)=>void
  recipientOptions: Option[]
  chapterOptions: Option[]
}
const EmailReminderItem = ({emailReminder:er, onClick, onDelete, recipientOptions, chapterOptions}:Props) => {
  const findLabel = (s:string, o:Option[]) => {
    return (o.find(x=>x.value == s) || {label:''}).label
  }
  const fmtList = (s:string, o:Option[]) => {
    return s.split(',')
    .map(x=>x.trim())
    .map(x=>findLabel(x, o))
    .filter(x=>!!x)
    .join(', ')
  }
  const fmtBodyText = (html:string) => {
    const el = document.createElement('div')
    el.innerHTML = html
    return Array.from(el.children).map(x=>(x as HTMLElement).innerText).join(' ')
  }

  const [deleting, setDeleting] = useState(false)
  const handleDelete = async () => {
    if (!onDelete) return
    try {
      setDeleting(true)
      await onDelete(er.id, er)
    } finally {
      setDeleting(false)
    }
  }

  return <DeleteableItem onDelete={handleDelete} disabled={deleting}>
  <ListGroupItem className='email-reminder-item' action onClick={()=>onClick(er.id, er)}>
    <Row>
      <Col>
        To: <span className='to'>{fmtList(er.recipientTypes, recipientOptions)}</span>
      </Col>
      <Col>
        Chapter{er.chapterIds.length != 1 ? 's':''}: <span className='chapters'>{fmtList(er.chapterIds, chapterOptions)}</span>
      </Col>
      <Col style={{textAlign:'right'}}>
        <RelativeTime value={er.sentOn || er.sendOn}/>
      </Col>
    </Row>
    <Row>
      <Col>
        Subject: <span className='subject'>{er.subject}</span>
      </Col>
    </Row>
    <div>
      <span className='body'>{fmtBodyText(er.htmlBody)}</span>
    </div>
    {!er.sentTo ? undefined : <>
      <hr/>
      <div>
        <span className='recipients'>{er.sentTo}</span>
      </div>
    </>}

  </ListGroupItem>
  </DeleteableItem>
}
export default EmailReminderItem