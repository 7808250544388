import React, { Component } from "react";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import SaveAndStepButton from '../SaveAndStepButton.jsx';
import "./ApplicantForm.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import EducationStep from "../EducationStep";
import CareerStep from "../CareerStep";
import ERGFormGroup from "./ERGFormGroup"
import {
  faChevronRight,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";
import ApplicationHeader from "../ApplicationHeader";

const locations = [
  'East - Philadelphia (EST)',
  'West - Denver (MST)',
  'CIEC - Chennai (IST)',
  'SkyUK - London (GMT)'
]

class ApplicantProfileForm extends Component {

  thisPageStep = 1;
  onContinue = () => {
    this.props.jumpToStep(this.thisPageStep + 1);
  };
  onBack = () => {
    this.props.jumpToStep(0);
  };

  yearsSince = (hire_date) => {
    let currentDate = new Date();
    let startDate = hire_date ? new Date(hire_date) : new Date();
    let yearsMs = Math.abs(currentDate - startDate);
    const msPerYear = 31556952000;
    return (yearsMs / msPerYear).toFixed(2);
  };


  state = {
    application: {
      id: null,
      user_id: this.props.currentUser.User.id,
      department: this.props.currentUser.User.department,
      manager: this.props.currentUser.User.manager_email,
      hire_date: this.props.currentUser.User.hire_date,
      title: this.props.currentUser.User.job_title,
      pern: "",
      erg: "",
      years_with_company: this.props.application?.years_with_company !== undefined
        ? this.props.application.years_with_company
        : (this.props.currentUser.User.hire_date ? this.yearsSince(this.props.currentUser.User.hire_date) : 0),
      location: this.props.application?.location || "-1",
      location_comments: "",
      chapter_id: this.props.application?.chapterId || 1,
      applicant_type_id: this.props.applicantTypeId,
      essay: "",
      year: new Date().getFullYear(),
      active: true,
      status_id: 1, // pending
      educationEntries: this.props.educationEntries,
      careerEntries: this.props.careerEntries,
      ApplicantType: {
        id: this.props.applicantTypeId
      },
      // Overrides defaults when editing an application
      ...this.props.application,
    },

    hasChanges: false,
  };

  componentDidMount() {
    this.handleSubmit().then(() => {
      const yearsWithCompany = this.props.application?.years_with_company !== undefined
        ? this.props.application.years_with_company
        : (this.props.currentUser.User.hire_date ? this.yearsSince(this.props.currentUser.User.hire_date) : 0);
      this.setState({
        application: {
          id: this.props.application.id,
          ...this.props.application,
          years_with_company: yearsWithCompany,
        },
        chapters: this.getChapters(),
        hasChanges: false
      });
    });
  }
  handleSubmit = async (event) => {
    if (event) {
      event.preventDefault();
    }
    await this.props.saveApplication(this.state.application);
    this.setState({ hasChanges: false });
  };

  handleLocationChange = (event) => {
    const { name, value } = event.target;
    let chapter_id = '';

    // set the chapter when the user selects location
    if (name === 'location') {
      let chapters = this.state.chapters;
      // find the chapter with a corresponding location
      let matchingChapter = chapters.find((obj) => {
        const selectedValue = value.toLowerCase();
        const { location: chapterName } = obj;
        return selectedValue.includes(chapterName.toLowerCase());
      });

      // set the chapter if we find a matching chapter
      if (matchingChapter !== undefined) {
        chapter_id = matchingChapter.id;
        this.setState({
          application: {
            ...this.state.application,
            chapter_id: chapter_id,
            [name]: value
          }
        })
      } else {
        console.error(`No matching chapter found for location ${value}`);
      }
    }
  }
  handleInputChange = (event) => {
    const { name, value } = event.target;

    this.setState({
      hasChanges: true,
      application: {
        ...this.state.application,
        [name]: value,
      },
    });

  };

  //TODO: remove this and inject the chapters as a propery from ApplicationContainer. Chapters should be supplied from an API request
  getChapters = () => {
    let chapters = [
      { id: 1, name: "Denver", location: 'West - Denver (MST)' },
      { id: 2, name: "Philadelphia", location: 'East - Philadelphia (EST)' },
      { id: 3, name: "India", location: 'CIEC - Chennai (IST)' },
      { id: 4, name: 'Virtual', location: 'None' },
      { id: 5, name: 'Sky-UK', location: 'SkyUK - London (GMT)' }
    ];

    return chapters
  };

  shouldSave = () => {
    return this.state.hasChanges || !this.props.application;
  };

  render() {
    const locationOptions = locations.map((location, index) => {
      return <option value={location} key={location + index + location}>{location}</option>
    });

    const maxYearsAtComcast = (new Date().getFullYear() - 1963);
    return (

      <div>
        <Form>
          <Form.Row>
            <Form.Group
              className="text-left"
              as={Col}
              controlId="formGridTitle"
            >
              <Form.Label>Title</Form.Label>
              <Form.Control
                value={this.state.application.title}
                name="title"
                required
                type="text"
                placeholder="Current Title"
                onChange={this.handleInputChange}
              />
            </Form.Group>
            <Form.Group
              className="text-left"
              controlId="formGridDepartment"
              as={Col}
            >
              <Form.Label>Department</Form.Label>
              <Form.Control
                value={this.state.application.department}
                name="department"
                required
                type="text"
                placeholder="Current Department"
                onChange={this.handleInputChange}
              />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group
              className="text-left"
              as={Col}
              controlId="formGridManager"
            >
              <Form.Label>Manager Email</Form.Label>
              <Form.Control
                value={this.state.application.manager}
                name="manager"
                required
                type="text"
                placeholder="Current Manager Email"
                onChange={this.handleInputChange}
              />
            </Form.Group>
            <Form.Group
              className="text-left"
              as={Col}
              controlId="formGridLocation"
            >
              <Form.Label>Chapter Location</Form.Label>
              <Form.Control
                value={this.state.application.location}
                name="location"
                required
                as="select"
                onChange={this.handleLocationChange}
                custom
              >
                <option value="" hidden>Select an Option</option>
                {locationOptions}
              </Form.Control>
            </Form.Group>
            <Form.Group
              className="text-left"
              as={Col}
              controlId="formGridYearsWithCompany"
            >
              <Form.Label>Tenure</Form.Label>
              <Form.Control
                value={this.state.application.years_with_company}
                name="years_with_company"
                required
                placeholder="Years at Comcast"
                type="number"
                alt="Years at Comcast"
                min={0.01}
                step="0.01"
                max={maxYearsAtComcast}
                onChange={this.handleInputChange}
              />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <ERGFormGroup as={Col} id="formGridERG" value={this.state.application.erg} name='erg' onChange={this.handleInputChange} />
          </Form.Row>
        </Form>

        <EducationStep
          createEducation={this.props.createEducation}
          application={this.state.application}
          educationEntries={this.props.educationEntries}
          handleDeleteEducation={this.props.handleDeleteEducation}
        />

        <CareerStep
          createCareer={this.props.createCareer}
          application={this.state.application}
          careerEntries={this.props.careerEntries}
          handleDeleteCareer={this.props.handleDeleteCareer}
        />
        <Form>
          <div className="justify-content-between">
            <SaveAndStepButton
              buttonText="Back"
              icon={faChevronLeft}
              iconPosition="left"
              className="col-sm-1 col-md-3 col-lg-2"
              variant="primary"
              onSave={this.handleSubmit}
              onSuccessFunction={this.onBack}
            />
            <SaveAndStepButton
              buttonText="Continue"
              icon={faChevronRight}
              iconPosition="right"
              className="col-sm-1 col-md-3 col-lg-2"
              variant="primary"
              componentsToCheck={[
                'title', 'department', 'manager', 'location', 'years_with_company'
              ]}
              onSave={this.handleSubmit}
              onSuccessFunction={this.onContinue}
            />
          </div>
        </Form>
      </div>
    );
  }
}

export default ApplicantProfileForm;
