import React, { Component } from "react";
import axios from "axios";
import Form from "react-bootstrap/Form";
import SubmittedApp from "../SubmittedApp";
import Unauthorized from "../../Unauthorized";
import "react-stepzilla/src/css/main.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faStar,
  faCheck,
  faCheckCircle,
  faTimesCircle,
  faMinus,
  faCircleNotch,
} from "@fortawesome/free-solid-svg-icons";
import PageHeader from "../../PageHeader";
import "./Rater.css";

toast.configure();

const ranks = ["Strong No", "No", "Weak No", "Weak Yes", "Yes", "Strong Yes"];
const distLimit = 25;

class Rater extends Component {
  state = {
    loading: true,
    validPermissionLevel: 80,

    /* Dropdown Vars */
    chapters: [],
    chapter_id: "1",
    applicationTypes: [],
    applicant_type_id: "1",
    year: new Date().getFullYear(),

    /* Submission List */
    submissions: [],
    rankedSubmissions: [],
    activeSubmission: null,
    nextSubmission: null,
    loadingNext: false,
    breakdown: null,
  };

  componentDidMount = async () => {
    const applicationTypes = await axios.get('/api/application_types').then(r => r.data);
    const chapters = await axios.get('/api/chapters').then(r => r.data);
    this.setState({ applicationTypes, chapters });
    this.loadData();
  };

  loadData = () => {
    this.getRankings().then(() => {
      this.getSubmissions().then(() => {
        let submissions = this.state.submissions.filter(
          (s) => s.status_id != 3
        ); // filter out withdrawn
        let ranked = this.state.rankedSubmissions;

        if (submissions.length > 0 && ranked.length > 0) {
          for (var i in submissions) {
            for (var j in ranked) {
              if (submissions[i].id === ranked[j].application_id) {
                submissions[i].ranking = ranked[j].rating;
                submissions[i].date_ranked = ranked[j].updated_at;
              }
            }
          }

          this.setState({
            submissions: submissions,
          });
        }

        this.getNext();
      });
    });
  };

  getRankings = () => {
    let path = "/api/applicant_rankings";
    let params = [
      `user_id=${this.props.currentUser.User.id}`,
      `chapter_id=${this.state.chapter_id}`,
      `applicant_type_id=${this.state.applicant_type_id}`,
      `year=${this.state.year}`,
    ].join("&");

    return axios.get(`${path}?${params}`).then((response) => {
      let rankings = response.data.ratings;
      let breakdown = response.data.breakdown;

      this.setState(
        {
          rankedSubmissions: rankings,
          breakdown: breakdown,
        },
        () => {
          return Promise.resolve();
        }
      );
    });
  };

  getSubmissions = () => {
    let path = "/api/applications/submissions";
    let params = [
      `submitted=true`,
      `chapter_id=${this.state.chapter_id}`,
      `applicant_type_id=${this.state.applicant_type_id}`,
      `year=${this.state.year}`,
    ].join("&");

    return axios.get(`${path}?${params}`).then((response) => {
      this.setState(
        {
          submissions: response.data,
        },
        () => {
          this.setState({ loading: false });
          return Promise.resolve();
        }
      );
    });
  };

  getNext = () => {
    this.setState({
      activeSubmission: null,
      nextSubmission: null,
      loadingNext: true,
    });

    return axios
      .post("/api/applicant_rankings/get-random", {
        rated_by: this.props.currentUser.User.id,
        chapter_id: this.state.chapter_id,
        applicant_type_id: this.state.applicant_type_id,
        year: this.state.year,
      })
      .then((response) => {
        this.setState({
          nextSubmission: response.data.id,
          activeSubmission: response.data.id,
          loadingNext: false,
        });
      });
  };

  handleInputChange = (event) => {
    const { name, value } = event.target;

    this.setState(
      {
        activeSubmission: null,
        [name]: value,
      },
      () => {
        this.loadData();
      }
    );
  };

  setRank = (event, appId) => {
    let rank = event.target.value;
    this.state.submissions.forEach((s) => {
      if (s.id === appId) {
        /* Use rate limiting for click-happy individuals */
        clearTimeout(s.saving);
        s.saving = setTimeout(() => {
          s.ranking = rank;

          /* So subs that are being updated don't get re-sorted */
          if (!s.date_ranked) {
            s.date_ranked = new Date().toISOString();
          }

          this.saveRank(s);
        }, 500);
      }
    });

    this.setState({
      submissions: this.state.submissions,
    });
  };

  saveRank = (submission) => {
    axios
      .post(`/api/applicant_rankings/${submission.id}`, {
        applicant_type_id: this.state.applicant_type_id,
        chapter_id: this.state.chapter_id,
        rated_by: this.props.currentUser.User.id,
        ranking: submission.ranking,
        year: this.state.year,
      })
      .then(() => {
        submission.saving = null;

        this.setState({
          submissions: this.state.submissions,
        });

        this.updateBreakdown();
        this.getNext();
      })
      .catch((error) => {
        toast.error(`Error: ${error.response.data}`, { autoClose: 10000 });
      });
  };

  updateBreakdown = () => {
    let dist = {};
    let subs = this.state.submissions;
    let ranked = subs.filter((s) => s.ranking !== undefined);
    let total = ranked.length;

    ranked.forEach((s) => {
      if (s.ranking !== undefined) {
        if (!dist[s.ranking]) {
          dist[s.ranking] = {
            count: 0,
            percentage: 0,
          };
        }

        dist[s.ranking].count += 1;
        dist[s.ranking].percentage = dist[s.ranking].count / total;
      }
    });

    this.setState({
      breakdown: dist,
    });
  };

  setActiveApplication = (applicationId) => {
    this.setState({
      activeSubmission: applicationId,
    });
  };

  filterSubmissions = (s) => {
    let isRanked = s.ranking !== undefined;
    let isNext = s.id === this.state.nextSubmission;
    return isRanked || isNext;
  };

  sortSubmissions = (a, b) => {
    // If one is undefined
    if (a.date_ranked && !b.date_ranked) {
      return -1;
    } else if (b.date_ranked && !a.date_ranked) {
      return 1;

      // Both defined
    } else if (a.date_ranked && b.date_ranked) {
      if (a.date_ranked < b.date_ranked) {
        return -1;
      } else if (a.date_ranked > b.date_ranked) {
        return 1;
      }
    }

    return 0;
  };

  getCompleted = () => {
    return this.state.submissions.filter((s) => s.ranking !== undefined);
  };

  isFinished = () => {
    let total = this.state.submissions.length;
    let completed = this.getCompleted().length;

    return total > 0 && completed === total;
  };

  /* Rendering Methods */
  renderProgress = () => {
    if (this.state.submissions.length === 0) {
      return;
    }

    return (
      <div className="rank-progress">
        <label>
          Completed: {this.getCompleted().length} of{" "}
          {this.state.submissions.length}
        </label>
        <div className="rank-progress-bar">
          <progress
            max={this.state.submissions.length}
            value={this.getCompleted().length}
          ></progress>

          <div className="rank-progress-goal" style={{ left: "100%" }}>
            {this.isFinished() ? <FontAwesomeIcon icon={faStar} /> : null}
          </div>
        </div>
      </div>
    );
  };

  renderBreakdown = () => {
    if (this.state.submissions.length === 0) {
      return;
    }

    if (this.state.breakdown) {
      return (
        <div className="rank-dist">
          <span>Distribution (Threshold = 25%)</span>
          <ul>
            {[0, 1, 2, 3, 4, 5].map((i) => {
              let name = ranks[i];
              let pct = 0;
              let cnt = 0;

              if (this.state.breakdown[i]) {
                pct = (this.state.breakdown[i].percentage * 100).toFixed(2);
                cnt = this.state.breakdown[i].count;
              }

              let col = pct > distLimit ? "text-danger" : "";
              let cls = "rank-dist-value " + col;
              return (
                <li key={i}>
                  {name} <br />
                  <span className={cls}>
                    {pct}% ({cnt})
                  </span>
                </li>
              );
            })}
          </ul>
        </div>
      );
    }
  };

  renderRankIcon = (submission) => {
    let icon = null;
    let spin = false;
    let col = "text-muted";

    if (submission.saving) {
      icon = faCircleNotch;
      spin = true;
    } else if (submission.ranking === undefined) {
      icon = faMinus;
    } else if (submission.ranking >= 3) {
      icon = faCheckCircle;
      col = "text-success";
    } else if (submission.ranking < 3) {
      icon = faTimesCircle;
      col = "text-danger";
    }

    return (
      <span className={col}>
        {submission.saving ? "Saving..." : ranks[submission.ranking]}
        <FontAwesomeIcon icon={icon} spin={spin} />
      </span>
    );
  };

  render() {
    if (
      this.props.currentUser.Role.permission_level <
      this.state.validPermissionLevel
    ) {
      return <Unauthorized />;
    }

    let submissionList = null;
    if (this.state.submissions.length > 0) {
      submissionList = (
        <ul className="rank-list">
          {this.state.submissions
            .filter(this.filterSubmissions)
            .sort(this.sortSubmissions)
            .map((s) => (<>
              <li
                key={s.id}
                className={`rank-item ${
                  s.id === this.state.activeSubmission ? "selected" : ""
                }`}
                onClick={() => this.setActiveApplication(s.id)}
              >
                <div className="rank-item-label">
                  <span>{s.User.name}</span>
                  {this.renderRankIcon(s)}
                </div>

                <div className="rank-item-action">
                  {ranks.map((r, i) => (
                    <label key={i}>
                      <input
                        type="radio"
                        name={"rank-value-" + s.id}
                        value={i}
                        checked={s.ranking == i}
                        onChange={(event) => this.setRank(event, s.id)}
                      />
                      <span>{r}</span>
                    </label>
                  ))}
                </div>
              </li>
            </>))}
        </ul>
      );
    }

    return (
      <div className="rank-layout">
        <PageHeader title="Application Rater" />

        <div className="rank">
          <div className="rank-sidebar">
            <p>
              Choose the chapter and the applicant type you would like to start
              rating and we will give you the next person that needs a rating.
            </p>
            <Form className="ranking-select" onSubmit={this.handleSubmit}>
              <Form.Label>Chapter</Form.Label>
              <Form.Control
                name="chapter_id"
                required
                as="select"
                placeholder="Chapter"
                defaultValue={this.state.chapter_id}
                onChange={this.handleInputChange}
              >
                {this.state.chapters.map((c) => <option key={c.id} value={c.id}>{c.location}</option>)}
              </Form.Control>

              <Form.Label>Applicant Type</Form.Label>
              <Form.Control
                name="applicant_type_id"
                required
                as="select"
                placeholder="Applicant Type"
                defaultValue={this.state.applicant_type_id}
                onChange={this.handleInputChange}
              >
                {this.state.applicationTypes.map((t) => <option key={t.id} value={t.id}>{t.applicant_type}</option>)}
              </Form.Control>
            </Form>

            <hr />

            {this.renderProgress()}
            {this.renderBreakdown()}
            {submissionList || (
              <p className="text-center">No applications found!</p>
            )}

            {this.state.loadingNext ? (
              <p className="text-center">Loading next applicant...</p>
            ) : null}

            {this.isFinished() ? (
              <p className="text-center">
                Nice! You've completed all applications!
              </p>
            ) : null}
          </div>

          <div className="rank-content">
            {this.state.activeSubmission ? (
              <SubmittedApp
                params={{ application_id: this.state.activeSubmission }}
                currentUser={this.props.currentUser}
                hideHeader
              />
            ) : (
              <p className="rank-content-empty">
                Choose an applicant on the left to view their application!
              </p>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default Rater;
