import React, { Component } from "react";
import Moment from "../Moment";
import { Link } from "react-router-dom";
import {
  faCircle,
  faCalendar,
  faTasks,
  faCalendarCheck,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Dashboard.css";

export default class Dashboard extends Component {
  state = {
    events: this.props.events,
    activeEvent: this.props.event,
  };

  componentDidMount() {
    this.handleSelect = this.handleSelect.bind(this);
  }

  componentDidUpdate() {
    if (this.state.activeEvent != this.props.event) {
      this.setState({
        activeEvent: this.props.event,
      });
    }
  }

  isAllowed(eventRole) {
    var userRole = this.props.role;
    if (eventRole.permission_level <= userRole.permission_level) {
      return true;
    }

    return false;
  }

  handleSelect(eventId) {
    this.setState({
      activeEvent: eventId,
    });

    this.props.onSelect(eventId);
  }

  render = () => {
    let links = [];
    let currMonth = null;

    this.state.events.forEach((event) => {
      let isAllowed = this.isAllowed(event.Role);
      if (!isAllowed) {
        return;
      }

      // Add month divider when appropriate
      let month = event.date.split("-")[1];
      if (currMonth !== month) {
        currMonth = month;

        links.push(
          <div key={"div_" + currMonth} className="dash-timeline-divider">
            <Moment format="MMMM">{event.date}</Moment>
          </div>
        );
      }

      // Add event to the timeline list
      links.push(
        <Link
          key={event.id}
          to={"/dashboard/" + event.id}
          className={
            "dash-timeline-event " +
            (this.state.activeEvent === event.id ? "active" : "")
          }
          onClick={() => this.handleSelect(event.id)}
        >
          {this.renderIcon(event)}
          <div>
            <h1>{event.name}</h1>
            <time>
              <Moment format="dddd, MMMM Do">{event.date}</Moment>
            </time>
          </div>
        </Link>
      );
    });

    if (!links.length) {
      links = <p>No events found!</p>;
    }

    return links;
  };

  renderIcon(event) {
    var icon = faCircle;
    var color = "text-muted";

    if (event.type === "event") {
      let eventDate = new Date(event.date + " 00:00:00");
      let currDate = new Date();
      currDate.setHours(0, 0, 0, 0);

      if (eventDate < currDate) {
        icon = faCalendarCheck;
        color = "text-success";
      } else {
        icon = faCalendar;
      }
    } else if (event.type === "task") {
      icon = faTasks;
    }

    return (
      <div className={"dash-timeline-icon " + color}>
        <FontAwesomeIcon icon={icon} />
      </div>
    );
  }
}
