import React, { useEffect, useMemo, useState } from "react";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import Form from "react-bootstrap/Form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faChevronLeft,
  faExclamationTriangle,
  faWarning,
} from "@fortawesome/free-solid-svg-icons";
import { Popup } from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import "./Essay.css";
import ApplicationHeader from "../ApplicationHeader";
import SaveAndStepButton from "../SaveAndStepButton.jsx";
import { useDebounce } from "../../../hooks/useDebounce";
import { Modal } from "react-bootstrap";
import { ESSAY_PROMPT, ESSAY_MAX_WORD_COUNT } from '../constants'

const EssayStep = ({application, jumpToStep, saveApplication}) => {
  const thisPageStep = 3;
  const [essay, setEssay] = useState(application.essay);
  const [showPopup, setShowPopup] = useState(false);
  const debouncedEssay = useDebounce(essay, 500);
  const [saving, setSaving] = useState(false);
  const [showTooWordy, setShowTooWordy] = useState(false);

  const wordCount = useMemo(()=>{
    if (debouncedEssay) {
      let words = debouncedEssay.split(/\s+/);
      return words.filter((w) => w !== "").length;
    }
    return 0
  }, [debouncedEssay])

  useEffect(()=>{
    setSaving(true)
    saveApplication({...application, essay})
    .finally(()=>setTimeout(()=>setSaving(false), 500))
  }, [debouncedEssay, wordCount])

  const onContinue = async () => {
      console.debug('onContinue')
    if (wordCount > ESSAY_MAX_WORD_COUNT) {
      setShowTooWordy(true);
    }
    if (wordCount !== 0 && wordCount <= (ESSAY_MAX_WORD_COUNT)) {
      jumpToStep(thisPageStep + 1);
    }

  }

  const onBack = async () => {
    jumpToStep(thisPageStep - 1);
  }

  const handleEssayChange = (event) => {
    setEssay(event.target.value)
    // 
  }

  const handleFormSubmit = async (event) => {
    if (event) {
      event.preventDefault();
    }
      await saveApplication({...application, essay});
  }

  return (
    <div>
      <ApplicationHeader
        heading="Personal Statement"
        message={ESSAY_PROMPT}
      >
      </ApplicationHeader>

      <Form onSubmit={handleFormSubmit}>
        <Form.Group>
          <Form.Control
            required
            name="essay"
            value={essay}
            as="textarea"
            rows="15"
            style={{minHeight:'9.25rem'}}
            onChange={handleEssayChange}
          />
          <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', marginTop:'0.35em'}}>
            <div className="wordCount">
              Word Count: {wordCount} {(wordCount > ESSAY_MAX_WORD_COUNT) ? <FontAwesomeIcon color='#ecb100' icon={faWarning}/> : undefined}
            </div>
            {saving ? <Spinner animation="border" size="sm"/> : undefined}
          </div>
          <Form.Group className="d-flex flex-column align-items-center mt-3">
            <Form.Check type="checkbox" id="ai-check">
              <Form.Check.Input type="checkbox" required isValid />
              <Form.Check.Label style={{ color: 'lightcoral' }}>I did not use Generative AI to create this essay</Form.Check.Label>
          </Form.Check>
          </Form.Group>
          <div className="row nav-btns justify-content-between">
            <SaveAndStepButton
              buttonText="Back"
              icon={faChevronLeft}
              iconPosition="left"
              className="col-2"
              variant="primary"
              onSave={handleFormSubmit}
              onSuccessFunction={onBack}
            />
            <SaveAndStepButton
              buttonText="Continue"
              icon={faChevronRight}
              iconPosition="right"
              className="col-2"
              variant="primary"
              componentsToCheck={[
                'essay',
                'ai-check'
              ]}
              onSave={handleFormSubmit}
              onSuccessFunction={onContinue}
              />
          </div>
        </Form.Group>

      </Form>
    <Modal show={showTooWordy} onHide={()=>setShowTooWordy(false)} centered>
      <Modal.Body>
        At {wordCount} words, your essay is too long. Please edit it
        so that is not longer than {ESSAY_MAX_WORD_COUNT} words.
      </Modal.Body>
       <Modal.Body>
        <Button onClick={()=>setShowTooWordy(false)}>Ok, I understand</Button>
       </Modal.Body>
    </Modal>
    </div>
  )
}

export default EssayStep;
