import { useState, useEffect } from 'react';
import styled from "styled-components";

const CardImage = styled.div`
  background: linear-gradient(
    140deg,
    var(--color-primary) 25%,
    var(--color-secondary)
  );
  border-radius: 50%;
  overflow: hidden;
  padding: 2px;
  margin-right: 1rem;
  flex-shrink: 0;
  display: inline-flex;

  & img {
    display: block;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background: #fff;
    padding: 2px;
    object-fit: cover;
  }
  &.square {
    border-radius: 0.5rem;
  }
  &.square img {
    border-radius: calc(0.5rem - 2px);
    object-fit: contain;
  }
  &.small img {
    width: 60px;
    height: 60px;
  }
`;

interface ImageDisplayProps {
  imageURL: (string | File),
  /** The shape of the image to use. Can be square, circle, or an empty string */
  imageVariant?: string
}

export const ImageBox = ({ imageURL, imageVariant }: ImageDisplayProps) => {
  const [imageURI, setImageURI] = useState<string | File>(imageURL);


  useEffect(() => {
    if (imageURL instanceof File) {
      setImageURI(URL.createObjectURL(imageURL));
      return
    }

    if (imageURL.length > 0 && imageURL[0] != '/')
      setImageURI('/' + imageURL || "/holder.js/171x180");
    else
      setImageURI(imageURL);

  }, [imageURL]);


  return (
    <CardImage className={imageVariant || ""}>
      <img src={imageURI as string} />
    </CardImage>
  )
}