import React, { Component } from "react";
import axios from "axios";
import BootstrapTable from "../../react-bootstrap-table/packages/react-bootstrap-table2"
import filterFactory, { selectFilter } from "../../react-bootstrap-table/packages/react-bootstrap-table2-filter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container, Row } from "react-bootstrap";
import {
  faSort,
  faSortUp,
  faSortDown,
} from "@fortawesome/free-solid-svg-icons";
import PermissionLevel from "../../data/PermissionLevel";
import PageHeader from "../PageHeader";
import "./Pledges.css";

const sortCaret = (order, column) => {
  if (!order)
    return (
      <span>
        <FontAwesomeIcon icon={faSort} />
      </span>
    );
  else if (order === "asc")
    return (
      <span>
        <FontAwesomeIcon icon={faSortUp} />
      </span>
    );
  else if (order === "desc")
    return (
      <span>
        <FontAwesomeIcon icon={faSortDown} />
      </span>
    );
  return null;
};

class Pledges extends Component {
  state = {
    loading: true,
    applications: [],
    chapters: [],
  };
  componentDidMount = async () => {
    if (
      this.props.currentUser.Role.permission_level >= PermissionLevel.Committee
    ) {
      const chapters = await axios.get('/api/chapters').then(r => r.data);
      axios.get("/api/applications/submissions?status_id=4&applicant_type_id=1")
        .then((response) => {
          const applications = response.data.filter(
            (app) => app.ApplicantType.applicant_type === "mentee"
          );

          applications.forEach((a) => {
            if (a.GradPledges) {
              let signedBy = {
                mentee: [],
                mentor: [],
                committee: [],
                manager: [],
              };
              a.GradPledges.forEach((g) => {
                let signed = `${g.User.name} signed ${new Date(
                  g.created_at
                ).toLocaleDateString()}`;
                if (g.signer_role === "mentee") {
                  signedBy.mentee.push(signed);
                } else if (g.signer_role === "mentor") {
                  signedBy.mentor.push(signed);
                } else if (g.signer_role === "manager") {
                  signedBy.manager.push(signed);
                } else {
                  signedBy.committee.push(signed);
                }
              });
              a.grad_pledge_signed = signedBy;
            }
          });

          this.setState({
            applications,
            chapters,
            loading: false,
            fields: [
              {
                dataField: "User.name",
                text: "Applicant",
                sort: true,
                sortCaret,
              },
              {
                dataField: "Chapter.location",
                text: "Chapter",
                sort: true,
                sortCaret,
                formatter: (cell, row) => {
                  return chapters.find(c => Number(c.id) === Number(cell))?.location || '';
                },
                filter: selectFilter({
                  options: chapters.reduce((opts, c) => {
                    return Object.assign(opts, {[c.location]: c.location});
                  }, {}),
                }),
              },
              {
                dataField: "grad_pledge_signed.mentee",
                text: "Mentee",
                sort: false,
              },
              {
                dataField: "grad_pledge_signed.mentor",
                text: "Mentor",
                sort: false,
              },
              {
                dataField: "grad_pledge_signed.committee",
                text: "Committee",
                sort: false,
              },
              {
                dataField: "grad_pledge_signed.manager",
                text: "Manager",
                sort: false,
              },
            ]
          });
        });
    } else {
      this.setState({ loading: false });
    }
  }

  render() {
    const defaultSorted = [
      {
        dataField: "grad_pledge_id", // if dataField is not match to any column you defined, it will be ignored.
        order: "asc", // desc or asc
      },
    ];

    if (this.state.loading) {
      return <div>Loading...</div>;
    }

    const isCommittee = this.props.currentUser.Role.permission_level < PermissionLevel.Committee
    if (isCommittee) {
      return <div>You do not have permission to view this page.</div>;
    }

    return (
      <>
        <PageHeader title="Graduation Pledges" />
        <Container>
          <Row>
            <p className="page-descr">
              Displaying {this.state.applications.length} Graduation Pledges
            </p>
          </Row>
          <Row>
            <BootstrapTable
              keyField="id"
              data={this.state.applications}
              columns={this.state.fields}
              defaultSorted={defaultSorted}
              filter={filterFactory()}
            />
          </Row>
        </Container>
      </>
    );
  }
}

export default Pledges;
