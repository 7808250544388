import React, { Component } from "react";
import axios from "axios";
import StepZilla from "react-stepzilla";
import "react-stepzilla/src/css/main.css";
import {Col, Container, Row, Spinner} from "react-bootstrap";
import Requirements from "../Requirements";
import ApplicantProfileForm from "../ApplicantProfileForm";
import CompetenciesStep from "../CompetenciesStep";
import EssayStep from "../EssayStep";
import "./ApplicationContainer.css";
import { ApplicationWindowContextProvider } from "./ApplicationWindowContext";
import DeadlineAlert from "./DeadlineAlert.jsx";
import { APPLICATION_STATUS_ID_SUBMITTED, APPLICATION_STATUS_ID_WITHDRAWN, APP_TYPE_ID_TO_STRING } from '../constants'
import ReviewApplicationStep from "../ReviewApplicationStep/index.jsx";

class ApplicationContainer extends Component {
  state = {
    loading: true,
    application: null,
    educationEntries: [],
    careerEntries: [],
    competencies: [],
    competenciesRatings: [],
    applicantTypeId: null,
    managerSubmissions: [],
    step: 0,
  };

  setApplicantType = (id) => {
    this.setState({ applicantTypeId: id });
  };

  updateApplication = (application) => {
    return axios
      .put(`/api/applications/${application.id}`, application)
      .then((response) => {
        // reset the application status and applicant type after an application has been withdrawn
        if (application.status_id === APPLICATION_STATUS_ID_WITHDRAWN) {
          application.status_id = null;
          application.ApplicantType = null;
          application.ApplicationStatus = null;
        }
        this.setState(
          {
            application: application
          },
          () => {
            return Promise.resolve();
          }
        );
      });
  };

  saveApplication = (application) => {
    if (application.id) {
      return this.updateApplication(application);
    }

    return axios.post("/api/applications", application).then((response) => {

      this.setState(
        { application: { ...application, ...response.data } },
        () => {
          return Promise.resolve();
        }
      );
    });
  };

  createEducation = (education) => {
    if (education?.application_id === null) {
      education.application_id = this.state.application.id;
    }

    axios.post("/api/education_entries", education).then((response) => {
      this.setState({
        educationEntries: [...this.state.educationEntries, response.data],
      }, () => {
        return Promise.resolve();
      });
    });
  };

  handleDeleteEducation = (id) => {
    const existingEducation = this.state.educationEntries;
    const recordToBeDeleted = existingEducation[id];
    existingEducation.splice(id, 1);

    // delete the row from the db if the row already exists, otherwise just remove it from the client
    if (recordToBeDeleted && Object.hasOwn(recordToBeDeleted, 'id')) {
      axios.delete(`/api/education_entries/${recordToBeDeleted.id}`).then((result) => {
        this.setState({ educationEntries: existingEducation });
      });
    } else {
      this.setState({ educationEntries: existingEducation });
    }
  };

  createCareer = (career) => {
    if (career?.end_date?.trim().length == 0) {
      career.end_date = null;
    }

    if (career?.application_id === null) {
      career.application_id = this.state.application.id;
    }

    axios.post("/api/career_entries", career).then((response) => {
      this.setState({
        careerEntries: [...this.state.careerEntries, response.data],
      }, () => {
        return Promise.resolve();
      });
    });
  };

  handleDeleteCareer = (id) => {
    const existingJobs = this.state.careerEntries;
    const recordToBeDeleted = this.state.careerEntries[id]
    existingJobs.splice(id, 1);
    if (recordToBeDeleted && Object.hasOwn(recordToBeDeleted, 'id')) {

      axios.delete(`/api/career_entries/${recordToBeDeleted.id}`).then((result) => {
        this.setState({ careerEntries: existingJobs });
      });

    } else {
      existingJobs.splice(id, 1);
      this.setState({ careerEntries: existingJobs });
    }
  };

  getCompetencies = (applicant_type) => {
    return axios
      .get("/api/competencies?applicant_type_id=" + applicant_type)
      .then((response) => {
        this.setState({ competencies: response.data });
        return Promise.resolve();
      });
  };

  createCompetenciesRatings = (ratings) => {
    return axios
      .post(
        "/api/competencies_ratings?application_id=" + this.state.application.id,
        ratings
      )
      .then((response) => {
        this.setState(
          {
            competenciesRatings: response.data,
          },
          () => {
            return Promise.resolve();
          }
        );
      });
  };

  loadEduction = () => {
    axios
      .get(`/api/education_entries?application_id=${this.state.application.id}`)
      .then((response) => {
        this.setState({ educationEntries: response.data });
      });
  };

  loadCareerEntries = () => {
    axios
      .get(`/api/career_entries?application_id=${this.state.application.id}`)
      .then((response) => {
        this.setState({ careerEntries: response.data });
      });
  };

  componentDidMount() {
    axios
      .get("/api/applications/my_application")
      .then((response) => {
        const submissions = response.data.filter(s => s.year === new Date().getFullYear());

        // set the application to the one that is active
        // isUser prevents managers from accidentally editing on of their reports' application
        console.debug('submissions', submissions)
        const application = submissions.find((x) => {
          const isActive = x.active === true;
          const isSubmitted = x.status_id === APPLICATION_STATUS_ID_SUBMITTED;
          const currentYear = x.year === new Date().getFullYear();
          const isUser = x.user_id === this.props.currentUser.user_id;
          return (isActive || isSubmitted) && currentYear && isUser;
        });
        console.debug('application', application)

        if (application) {
          this.setState({ application }, () => {
            this.loadEduction();
            this.loadCareerEntries();
          });

          axios
            .get(`/api/competencies_ratings?application_id=${application.id}`)
            .then((response) => {
              this.setState({ competenciesRatings: response.data });
            });
        }
        const managerSubmissions = submissions.filter(x => {
          const isSubmitted = x.status_id === APPLICATION_STATUS_ID_SUBMITTED;
          const currentYear = x.year === new Date().getFullYear();
          const isUser = x.user_id === this.props.currentUser.user_id;
          return (currentYear && isSubmitted && !isUser)
        })
        this.setState({ managerSubmissions })
      })
      .finally(() => this.setState({ loading: false }));
  }

  render() {
    if (this.state.loading) {
      return (
        <div className='text-center mt-3'>
          <Spinner variant="primary" animation="border" role="status" />
        </div>
      )
    }

    const isApplicationSubmitted = this.state.application && this.state.application.status_id === APPLICATION_STATUS_ID_SUBMITTED;
    const showStepsCondition = !this.state.application || !isApplicationSubmitted;
    const applicantTypeString = APP_TYPE_ID_TO_STRING[this.state.applicantTypeId] || "";

    const steps = [
      {
        name: "Role",
        component: (
          <Requirements
            currentUser={this.props.currentUser}
            application={this.state.application}
            saveApplication={this.saveApplication}
            applicantTypeId={this.state.applicantTypeId}
            setApplicantTypeId={this.setApplicantType}
            managerSubmissions={this.state.managerSubmissions}
          />
        ),
      },
      {
        name: "Profile",
        component: (
          <ApplicantProfileForm
            currentUser={this.props.currentUser}
            saveApplication={this.saveApplication}
            application={this.state.application}
            applicantTypeId={this.state.applicantTypeId}
            educationEntries={this.state.educationEntries}
            careerEntries={this.state.careerEntries}
            handleDeleteCareer={this.handleDeleteCareer}
            handleDeleteEducation={this.handleDeleteEducation}
            createCareer={this.createCareer}
            createEducation={this.createEducation}
          />
        ),
      },
      {
        name: "Skills",
        component: (
          <CompetenciesStep
            application={this.state.application}
            applicantTypeId={this.state.applicantTypeId}
            competencies={this.state.competencies}
            ratings={this.state.competenciesRatings}
            getCompetencies={this.getCompetencies}
            createCompetenciesRatings={this.createCompetenciesRatings}
            saveApplication={this.saveApplication}
          />
        ),
      },
      {
        name: "Essay",
        component: (
          <EssayStep
            application={this.state.application}
            saveApplication={this.saveApplication}
          />
        ),
      },
      {
        name: "Review",
        component: (
          <ReviewApplicationStep
            currentUser={this.props.currentUser}
            application={this.state.application}
            saveApplication={this.saveApplication}
          />
        ),
      }
    ];

    return (
      <ApplicationWindowContextProvider>
        <Container className='mb-5'>
          {this.state.step === 0 && <DeadlineAlert />}
          <Row>
            <Col>
              <h2 style={{ textAlign: 'center', marginTop: '2em' }}>
                CLEAR Program {applicantTypeString} Application
              </h2>
              {isApplicationSubmitted ? (
                <>
                  {/* Only add line breaks if the application is submitted */}
                  <br /><br /><br />
                </>
              ) : null}
              <div className="step-progress" >
                <StepZilla
                  showSteps={showStepsCondition}
                  onStepChange={(step) => this.setState({ step })}
                  steps={steps}
                  showNavigation={false}
                  stepsNavigation={false}
                  preventEnterSubmission={true}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </ApplicationWindowContextProvider>
    );
  }
}

export default ApplicationContainer;
