import { useState } from 'react'
import { Button, ListGroup, Nav } from 'react-bootstrap'
import useFetch from '../../../../hooks/useFetch'
import EmailModal from './EmailModal'
import { Option, EmailReminder } from './types'
import EmailReminderItem from './EmailReminderItem'
import './EmailReminders.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRefresh } from '@fortawesome/free-solid-svg-icons'
import { DateTime } from 'luxon'

const EmailReminders = () => {
  const {data:recipientOptions=[]} = useFetch<Option[]>('/api/email_reminders/recipient_options')
  const {data:chapterOptions=[]} = useFetch<Option[]>('/api/email_reminders/chapter_options')

  const [showModal, setShowModal] = useState(false)
  const [editId, setEditId] = useState<number>()
  const [showSent, setShowSent] = useState(false)

  const {data:all=[], refresh} = useFetch<EmailReminder[]>('/api/email_reminders')
  const shown = all.filter(x=> showSent ? !!x.sentOn : !x.sentOn)
  const anyUnsentToday = undefined != all.find(x=>{
    if (x.sentOn != undefined) return false
    const t = DateTime.fromISO(x.sendOn)
    const s = DateTime.now().startOf('day')
    const e = DateTime.now().endOf('day')
    return t >= s && t <= e
  })

  const editEmail = (id?:number)=>{
    setEditId(id)
    setShowModal(true)
  }

  const onHide = () => {
    setShowModal(false)
    setEditId(undefined)
    refresh()
  }

  const deleteEmail = (id?:number) => {
    fetch(`/api/email_reminders/${id}`, {method: 'DELETE'})
    .then(refresh)
  }

  const sendUnset = () => {
    fetch(`/api/email_reminders/send_todays`, {method: 'POST'})
    .then (()=>setTimeout(refresh, 1000))
  }
  
  return (
    <div className='email-reminders'>
      <EmailModal show={showModal} onHide={onHide} editId={editId}
        recipientOptions={recipientOptions}
        chapterOptions={chapterOptions}
      />
      <p>Schedule email messages for CLEAR member groups (for example: Officers, Mentees or Mentors) and chapters. Messages will be sent at 9:00 am Eastern of the scheduled date.</p>

      <div className='mb-2'>
      <Button onClick={()=>editEmail()}>Schedule an Email Reminder</Button>
      <hr/>
      </div>

      {all.length == 0 ? <h5 className='mt-4'>No email reminders have been scheduled.</h5> : <>
      <div className='m-0 d-flex justify-content-between'>
        <Nav className='border-bottom-0' variant='tabs' activeKey={showSent ? 'sent' : 'pending'}>
          <Nav.Item>
            <Nav.Link eventKey='pending' onClick={()=>setShowSent(false)}>Pending</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey='sent' onClick={()=>setShowSent(true)}>Sent</Nav.Link>
          </Nav.Item>
        </Nav>
        <Button
        variant='outline-secondary' onClick={refresh}><FontAwesomeIcon icon={faRefresh}/></Button>
      </div>
        {shown.length > 0 ? undefined : <h5 className='mt-4'>
          {showSent ? `No emails have been sent.` : `There are no pending emails.`}
        </h5>}
        <ListGroup>
          {shown.map(x=>{
            return <EmailReminderItem key={x.id}
              emailReminder={x}
              onClick={()=>editEmail(x.id)}
              onDelete={()=>deleteEmail(x.id)}
              recipientOptions={recipientOptions}
              chapterOptions={chapterOptions}
            />
          })}
        </ListGroup>

        {(anyUnsentToday && !showSent) ? <>
          <hr/>
          Send any of today's unsent email reminders immediately<br/>
          <Button className='mt-2' onClick={sendUnset}>Send Today's Reminders</Button>
        </> : undefined}
      </>}
    </div>
  )
}

export default EmailReminders