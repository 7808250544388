import React, { Component } from "react";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import "./CareerForm.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";

class CareerForm extends Component {
  state = {
    application_id: this.props.application.id,
    company: "",
    position: "",
    start_date: "",
    end_date: "",
    achievements: "",
    hasChanges: false,
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    await this.props.createCareer(this.state);
    this.setState({
      hasChanges: false,
      company: "",
      position: "",
      start_date: "",
      end_date: "",
      achievements: "",
    });
  };

  handleInputChange = (event) => {
    const { name, value } = event.target;
    this.setState(
      {
        [name]: value,
      },
      () => {
        const hasChanges =
          this.state.company ||
          this.state.position ||
          this.state.start_date ||
          this.state.end_date ||
          this.state.achievements;
        this.setState({ hasChanges });
      }
    );
  };
  shouldSave = () => {
    return this.state.hasChanges || !this.props.careerEntries.length;
  };

  render() {
    return (
      <div>
        <Form onSubmit={this.handleSubmit}>
          <Form.Row className="align-bottom">
            <Form.Group as={Col}>
              <Form.Label>Company</Form.Label>
              <Form.Control
                name="company"
                required
                type="text"
                placeholder="Company"
                onChange={this.handleInputChange}
                value={this.state.company}
              />
            </Form.Group>

            <Form.Group as={Col} >
              <Form.Label>Position</Form.Label>
              <Form.Control
                name="position"
                required
                type="text"
                placeholder="Position"
                onChange={this.handleInputChange}
                value={this.state.position}
              />
            </Form.Group>

            <Form.Group as={Col} >
              <Form.Label>Start Date</Form.Label>
              <Form.Control
                name="start_date"
                required
                type="date"
                onChange={this.handleInputChange}
                value={this.state.start_date}
              />
            </Form.Group>

            <Form.Group as={Col} >
              <Form.Label>End Date</Form.Label>
              <Form.Control
                name="end_date"
                type="date"
                onChange={this.handleInputChange}
                value={this.state.end_date}
              />
            </Form.Group>

            <Form.Group as={Col} md="1" lg="1" sm="12">
              <Button
                className="career-btn align-text-bottom"
                variant="primary"
                type="submit"
                block={true}
                disabled={this.state.company.trim().length === 0 || this.state.start_date.trim().length === 0 || this.state.position.trim().length === 0}
              >
                Add
              </Button>
            </Form.Group>
          </Form.Row>
        </Form>
      </div>
    );
  }
}

export default CareerForm;
