import { DateTime } from "luxon"
import useSetTimeout from "../hooks/useSetTimeout"
import timeAgo from "../util/timeAgo"
import { useState } from "react"

const relTime = (dt:DateTime, now?:DateTime) => {
  // TODO handle other languages?
  now = now || DateTime.now()
  const today = now.startOf('day')
  const yesterday = today.minus({day:1})
  if (dt >= yesterday && dt < today) {
    return 'Yesterday at ' + dt.toFormat('t')
  }

  const tomorrow = today.plus({day:1})
  if (dt >= today && dt < tomorrow) {
    if (dt < now || dt > now.plus({hours:2})) {
      return 'Today at ' + dt.toFormat('t')
    }
    return timeAgo(dt)
  }
  if (dt >= tomorrow && dt < tomorrow.plus({day:1})) {
    return 'Tomorrow at ' + dt.toFormat('t')
  }

  const weekAgo = now.minus({day:5})
  const weekAway = now.plus({day:5})
  if (dt >= weekAgo && dt < weekAway) {
    return dt.toFormat('cccc') + ' at ' + dt.toFormat('t')
  }
  return dt.toISODate() + ' at ' + dt.toFormat('t')
}

const toDateTime = (value:string|Date|DateTime) => {
  if (typeof(value) == 'string') return DateTime.fromISO(value)
  if (value instanceof Date) return DateTime.fromJSDate(value)
  if (value instanceof DateTime) return value
  return DateTime.fromISO('')
}

interface Props {
  value: string|Date|DateTime
}
const RelativeTime = ({value}:Props) => {
  const [flag, setFlag] = useState(false)
  const refresh = () => setFlag(!flag)
  const {setTimeout} = useSetTimeout()
  setTimeout(()=>refresh(), 1000)

  const dt = toDateTime(value)
  const className = dt.isValid ? 'time-ago' : 'time-ago is-invalid'
  return <span className={className}>{relTime(dt)}</span>
}
export default RelativeTime