import React, { Component } from "react";
import styled from "styled-components";

const Label = styled.label`
  display: block;
  font-weight: bold;
  margin-bottom: 0;
`;

const Output = styled.div`
  background: white;
  border: solid 1px var(--color-text);
  border-radius: 3px;
  padding: 0.125rem;
  position: relative;
  cursor: text;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

const Item = styled.button`
  border: 0;
  padding: 0.75em 1em;
  margin: 0.125em;
  display: inline-block;
  font-size: 0.9rem;
  text-align: left;

  &:after {
    content: "\\00d7";
    font-weight: bold;
    margin-left: 1em;
  }
`;

const Input = styled.input`
  border: 0;
  outline: none;
  display: inline-block;
  padding: 0 0.5em;
  flex: 1 1 auto;
  height: 2.7em;
  max-width: 250px;
`;
export default class MultiSelect extends Component {
  state = {
    id: this.props.id,
    label: this.props.label || "",
    datalist: [],
    selected: this.props.selected || [],
    input: "",
    isShowClearAll: this.props.isShowClearAll || false,
  };

  componentDidMount = () => {
    this.updateDatalist();
  };
  componentDidUpdate = () => {
    this.updateDatalist();
  };

  updateDatalist = () => {
    if (this.props.datalist.length !== this.state.datalist.length) {
      this.setState({
        datalist: this.props.datalist || [],
      });
    }
  };

  isSelected = (v) => this.state.selected.includes(v);
  isOption = (v) => this.state.datalist.includes(v);
  clearDataList = () => {
    this.setState({ selected: [], input: "" });
  };
  toggleOption = (value) => {
    let selected = this.state.selected;
    let isOption = this.isOption(value);

    if (isOption) {
      if (!this.isSelected(value) && isOption) {
        selected = [...selected, value];
      } else {
        selected = selected.filter((s) => s !== value);
      }

      this.setState({ selected, input: "" });
      this.handleChange(selected);
    } else {
      this.setState({ input: value });
    }
  };

  handleChange = (selected) => {
    if (this.props.onChange) {
      this.props.onChange(selected);
    }
  };

  handleFocus = () => {
    if (this.state.datalist.length > this.state.selected.length) {
      document.getElementById(this.props.id).focus();
    }
  };

  render() {
    return (
      <>
        <Label htmlFor={this.props.id}>{this.props.label}</Label>
        <Output onClick={this.handleFocus}>
          {this.state.selected.map((s) => (
            <Item
              className="badge badge-primary"
              key={s}
              onClick={() => this.toggleOption(s)}
            >
              {s}
            </Item>
          ))}

          <Input
            id={this.props.id}
            list={this.props.id + "-select-list"}
            onChange={(e) => this.toggleOption(e.target.value)}
            value={this.state.input}
            placeholder={this.state.datalist.length === 0 || ( this.state.selected.length === this.state.datalist.length)  ? "No Available Options" : " Select an option..."}
            disabled={this.state.datalist.length === this.state.selected.length}
          />
        </Output>

        <datalist id={this.props.id + "-select-list"}>
          {this.state.datalist
            .filter((d) => !this.isSelected(d))
            .map((d) => (
              <option value={d} key={d}>
                {d}
              </option>
            ))}
        </datalist>
      </>
    );
  }
}
