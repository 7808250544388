// Refactored From https://github.com/pushtell/react-bootstrap-date-picker/blob/master/src/index.jsx to work with React v16

export type DateValues = {
  value?: string
  displayDate: Date
  selectedDate?: Date
  inputValue?: string
}

export const makeInputValueString = (date:Date, dateFormat:string, separator:string) => {
  const month = date.getMonth() + 1;
  const day = date.getDate();

  if (dateFormat.match(/MM.DD.YYYY/)) {
    return (month > 9 ? month : `0${month}`) + separator + (day > 9 ? day : `0${day}`) + separator + date.getFullYear();
  }
  else if (dateFormat.match(/DD.MM.YYYY/)) {
    return (day > 9 ? day : `0${day}`) + separator + (month > 9 ? month : `0${month}`) + separator + date.getFullYear();
  }
  return date.getFullYear() + separator + (month > 9 ? month : `0${month}`) + separator + (day > 9 ? day : `0${day}`);
}

export const makeDateValues = (isoString:string|undefined, dateFormat:string, minDate:Date|undefined, maxDate:Date|undefined, separator:string, utc:boolean) => {
  let selectedDate, inputValue, displayDate;
  if (isoString) {
    const d = (utc ? new Date(isoString).toISOString() : toLocalISOString(isoString)).slice(0,10)
    selectedDate = new Date(d + 'T12:00:00.000Z')
    inputValue = makeInputValueString(selectedDate as Date, dateFormat, separator)
  }
  if (selectedDate) {
    displayDate = new Date(selectedDate);
  } else {
    const today = new Date(`${(new Date().toISOString().slice(0,10))}T12:00:00.000Z`);
    if (minDate && minDate.getTime() >= today.getTime()){
      displayDate = minDate;
    } else if (maxDate && maxDate.getTime() <= today.getTime()){
      displayDate = maxDate;
    } else {
      displayDate = today;
    }
  }

  return {
    value: selectedDate ? selectedDate.toISOString() : undefined,
    displayDate,
    selectedDate,
    inputValue
  } as DateValues;
}

export const toLocalISOString = (date:Date|string) => {
  if (typeof date === 'string') date = new Date(date)
  const tzo = -date.getTimezoneOffset()
  const dif = tzo >= 0 ? '+' : '-'
  const pad = function(num:number, digits=2) {
    const n = Math.pow(10,digits)
    if (num >= n) return num.toString()
    return (num+n).toString().slice(1)
  }

  return date.getFullYear() +
    '-' + pad(date.getMonth() + 1) +
    '-' + pad(date.getDate()) +
    'T' + pad(date.getHours()) +
    ':' + pad(date.getMinutes()) +
    ':' + pad(date.getSeconds()) +
    dif + pad(Math.floor(Math.abs(tzo) / 60)) +
    ':' + pad(Math.abs(tzo) % 60)
}

export const toStartOfDay = (d:Date|string, utc=false) => {
  if (utc) return toLocalISOString(d).slice(0,10) + 'T00:00:00.000Z'
  d = new Date(d)
  d.setHours(0)
  d.setMinutes(0)
  d.setSeconds(0)
  d.setMilliseconds(0)
  return toLocalISOString(d)
}
export const toEndOfDay = (d:Date|string, utc=false) => {
  if (utc) return toLocalISOString(d).slice(0,10) + 'T23:59:59.999Z'
  d = new Date(d)
  d.setHours(23)
  d.setMinutes(59)
  d.setSeconds(59)
  d.setMilliseconds(999)
  return toLocalISOString(d)
}