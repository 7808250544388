import { Button, Container } from "react-bootstrap";
import { Link } from "react-router-dom";

const Unauthorized = () => (
  <Container style={{paddingTop: "2em"}}>
    <h2>You do not have permission to view this page.</h2>
    <Link to="/">
      <Button>Return Home</Button>
    </Link>
  </Container>
)

export default Unauthorized
