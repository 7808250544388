import React, { useEffect, useState } from "react";
import axios from "axios";
import { BrowserRouter as Router, Route, Switch, useParams } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import "./assets/fonts/fonts.css";
import "./App.css";

import Loader from "./components/Loader";
import Header from "./components/Header";
import ApplicationContainer from "./components/Application/ApplicationContainer";
import Applications from "./components/Applications";
import SubmittedApp from "./components/Application/SubmittedApp";
import Rater from "./components/Application/Rater";
import Home from "./components/Home";
import ManagerApproval from "./components/Application/ManagerApproval";
import Pledge from "./components/Pledge";
import Pledges from "./components/Pledges";
import { ProjectFair, Projects } from "./components/Projects";
import CommitteeBios from "./components/Committee";
import Dashboard from "./components/Dashboard";
import Project from "./components/Project";
import AdminSettings from "./components/AdminSettings";
import { ProfileForm } from "./components/Profile";
import NotFound from "./components/NotFound";
import LoginFailure from "./components/LoginFailure";

const routes = {
  "/about": CommitteeBios,
  "/projects": Projects,
  "/projects/:project_id/edit": Project,
  "/project-fair/:year": ProjectFair,
  "/apply": ApplicationContainer,
  "/admin-settings/*": AdminSettings,
  "/application/:application_id": SubmittedApp,
  "/application/:application_id/manager_approval": ManagerApproval,
  "/applications": Applications,
  "/application-rater": Rater,
  "/pledges": Pledges,
  "/pledge": Pledge,
  "/dashboard/:event_id?": Dashboard,
  "/profile/:user_id/edit": ProfileForm,
  "/": Home,
};

const App = () => {
  const [loading, setLoading] = useState(true)
  const [currentUser, setCurrentUser] = useState(undefined)

  const loginAsUser = (id) => {
    setLoading(true)
    axios.post(`/api/users/simulate/${id || 'none'}`)
    .then(()=>{
      return axios.get("/auth/current_user")
      .then((result) => setCurrentUser(result.data))
      .catch((error) => {})
    })
    .finally(()=>setLoading(false))
  }

  const endLoginAs = () => loginAsUser('none')

  useEffect(()=>{
    axios.get("/auth/current_user")
    .then((result) => setCurrentUser(result.data))
    .catch((error) => {})
    .finally(()=>setLoading(false))
  },[])

  if (loading) return <Loader/>
  if (window.location.pathname == '/login-failure') return <LoginFailure/>
  if (!currentUser) window.location = "/auth/login"

  return (
    <div className="App">
      <Router>
        <Header currentUser={currentUser} loginAsUser={loginAsUser} endLoginAs={endLoginAs}/>
        <ToastContainer autoClose={8000} />
        <div className="page-content">
          <Switch>
            {Object.entries(routes).map(([route, Page]) => {
              var exact = true
              if (route.endsWith('/*')) {
                exact = false
                route = route.substring(0,route.length-2)
              }
              return (
                <Route
                  key={route}
                  exact={exact}
                  path={route}
                  component={() => (
                    <Page
                      params={useParams()}
                      currentUser={currentUser}
                      loginAsUser={loginAsUser}
                      endLoginAs={endLoginAs}
                    />
                  )}
                />
              );
            })}
            <Route path="*"><NotFound/></Route>
          </Switch>
        </div>
      </Router>
    </div>
  )
}

export default App;
